export enum ResponseType {
    TextResponse,
    NumberResponse,
    DateResponse,
    AnswerOptionResponse,
    ArrayAnswerOptionResponse,
    ArrayBooleanResponse,
    ArrayNumberResponse,
    ConnectedQuestionResponse
}

export type SingleResponseType =
    | ResponseType.TextResponse
    | ResponseType.NumberResponse
    | ResponseType.DateResponse
    | ResponseType.AnswerOptionResponse;

export type ArrayResponseType =
    | ResponseType.ArrayAnswerOptionResponse
    | ResponseType.ArrayBooleanResponse
    | ResponseType.ArrayNumberResponse;
