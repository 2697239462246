import { IBaseResponse } from './abstract-responses/base-response';
import { ResponseType } from './response-type';
import { INumberResponse } from './single-responses/number-response';
import { IArrayNumberResponse } from './array-responses/array-number-response';
import {
    IExpression,
    evaluateExpression,
} from '../questions/expression/expression';
import {
    setResponse as masterSetResponse,
    Response,
    findResponseWithQuestionId,
    hasResponse as hasMasterResponse,
} from './response';

export interface IConnectedQuestionResponse
    extends Required<
        IBaseResponse<
            ResponseType.ConnectedQuestionResponse,
            Array<INumberResponse | IArrayNumberResponse>
        >
    > {
    responseTransforms: {
        [responseId: string]: IExpression;
    };
}

export function hasResponse(
    response: IConnectedQuestionResponse,
    responseVal: IConnectedQuestionResponse['response'][0],
): boolean {
    try {
        const foundResponse = findResponseWithQuestionId(
            responseVal.questionId,
            response.response,
        ) as IConnectedQuestionResponse['response'][0];

        return hasMasterResponse(
            foundResponse as any,
            responseVal.response as any,
        );
    } catch (err) {
        return false;
    }
}

export function setResponse(
    response: IConnectedQuestionResponse,
    responseVal: IConnectedQuestionResponse['response'][0],
): void {
    if (response.response === undefined) {
        response.response = [responseVal];
    } else {
        response.response = (response.response as any[]).filter(
            (
                currentResponse: NonNullable<
                    IConnectedQuestionResponse['response']
                >[0],
            ) => {
                return currentResponse.questionId !== responseVal.questionId;
            },
        );
        response.response.push(responseVal);
    }

    response.response.forEach((currentResponse) => {
        if (currentResponse.questionId !== responseVal.questionId) {
            Object.keys(response.responseTransforms).forEach((responseId) => {
                const questionId = responseId.split('_')[0];

                if (questionId === currentResponse.questionId) {
                    const subQuestionId = responseId.split('_')[1];
                    const evaluatedResponse = evaluateExpression(
                        response.responseTransforms[responseId],
                        response.response,
                    );

                    if (subQuestionId) {
                        masterSetResponse(
                            currentResponse as IArrayNumberResponse,
                            {
                                subQuestionId,
                                response: evaluatedResponse,
                            },
                        );
                    } else {
                        masterSetResponse(
                            currentResponse as INumberResponse,
                            evaluatedResponse,
                        );
                    }
                }
            });
        }
    });
}

export function isConnectedQuestionResponse(
    response: Response,
): response is IConnectedQuestionResponse {
    return response.responseType === ResponseType.ConnectedQuestionResponse;
}
