import {
    IAnswerOption,
    ISubQuestion,
    Question,
    IRadioQuestion,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    Response,
    IAnswerOptionResponse,
    IArrayBooleanResponse,
    findResponseWithQuestionId,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';

export function getKeyMapForChoices(
    choices: (IAnswerOption | ISubQuestion)[],
): {
    answerOptions: string[];
} {
    const keys = choices.map((_o, i) => {
        /* Convert from the index of the current choice to it's corresponding lower
        case alphabet. For example 0 is converted to 'a', 1 to 'b' etc */
        return String.fromCharCode(97 + i);
    });

    return {
        answerOptions: keys,
    };
}

export function getKeyHandlersForChoiceQuestion<
    T extends Question,
    U extends Response = T extends IRadioQuestion
        ? IAnswerOptionResponse
        : IArrayBooleanResponse
>(
    props: {
        question: T;
        responses: Response[];
        scrollToNextQuestion: () => void;
    },
    updateResponse: (question: T, response: U, e: KeyboardEvent) => void,
    scrollToNextQuestion: boolean,
) {
    return {
        answerOptions: (e: KeyboardEvent) => {
            const { question } = props;

            const responseForCurrentQuestion = findResponseWithQuestionId(
                question.questionId,
                props.responses,
            );

            updateResponse(question, responseForCurrentQuestion as U, e);

            if (scrollToNextQuestion) props.scrollToNextQuestion();
        },
    };
}
