import * as React from 'react';

import { observer, inject } from 'mobx-react';
import { WillYouLiveToSeeIt as DumbWillYouLiveToSeeIt } from '../dumb/WillYouLiveToSeeIt/WillYouLiveToSeeIt';
import { MPoRT } from '../../store/ui/mport';

export interface IWillYouSeeItProps {
    mport?: MPoRT;
    nextQuestion: Function;
    focus: boolean;
}

@inject('mport')
@observer
export class WillYouLiveToSeeIt extends React.Component<IWillYouSeeItProps> {
    formatValues(valueToFormat: number): number {
        return Math.round(valueToFormat * 100);
    }
    render() {
        const {
            setSliderValue,
            sliderValue,
            bigLifeText,
            bigLifeYear,
        } = this.props.mport!.willYouLiveToSeeIt;
        const {
            currentAge,
            survivalToBigLife,
            willYouLiveToSeeItSliderAgeSurvivalPercent,
            currentLifeExpectancy,
            riskToTime,
            meanHealthAge,
            healthyHealthAge,
            willYouLiveToSeeItAge,
            lifeYearsLost,
        } = this.props.mport!;

        console.log(`
            LE: ${currentLifeExpectancy}
            Risk 5 Year: ${riskToTime}
            Risk 1 Year: ${riskToTime / 5}
            Mean Health Age: ${meanHealthAge}
            Healthy Health Age: ${healthyHealthAge}
            Smoking Life Years Lost: ${lifeYearsLost.smoking}
            Alcohol Life Years Lost: ${lifeYearsLost.alcohol}
            Diet Life Years Lost: ${lifeYearsLost.diet}
            PA Life Years Lost: ${lifeYearsLost.physicalActivity}
        `);

        return (
            <DumbWillYouLiveToSeeIt
                lowerAge={currentAge}
                higherAge={110}
                willYouLiveToSeeItAge={willYouLiveToSeeItAge}
                willYouLiveToSeeItSurvival={this.formatValues(
                    survivalToBigLife,
                )}
                bigLifeText={bigLifeText}
                bigLifeYear={bigLifeYear}
                sliderAge={sliderValue}
                sliderSurvivalPercent={this.formatValues(
                    willYouLiveToSeeItSliderAgeSurvivalPercent,
                )}
                updateSliderValue={newSliderAge => {
                    return setSliderValue(Math.round(newSliderAge));
                }}
                nextQuestion={this.props.nextQuestion}
                focus={this.props.focus}
            />
        );
    }
}
