import { Covariate } from '../covariate';
import { autobind } from 'core-decorators';
import { DerivedField } from '../../derived-field/derived-field';
import { Data } from '../../../data/data';
import { IDatum } from '../../../data';
import { NonInteractionCovariate } from '../non-interaction-covariats/non-interaction-covariate';
import { IUserFunctions } from '../../../algorithm/user-functions/user-functions';
import { ITables } from '../../../algorithm/tables/tables';
import { IValidationError } from '../../../../validation/validate/validation-error';

@autobind
export class InteractionCovariate extends Covariate {
    // Initialized in the covariate constructor
    derivedField!: DerivedField;

    calculateDataToCalculateCoefficent(
        data: Data,
        userDefinedFunctions: IUserFunctions,
        tables: ITables,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              data: Data;
          } {
        const coefficentDataCalculation = super.calculateDataToCalculateCoefficent(
            data,
            userDefinedFunctions,
            tables,
        );
        if (coefficentDataCalculation instanceof Array) {
            return coefficentDataCalculation;
        }

        // Check whether the coefficent data has the interaction covariate in it and if it does return it
        if (
            coefficentDataCalculation.data.length === 1 &&
            coefficentDataCalculation.data[0].name === this.name
        ) {
            return coefficentDataCalculation;
        }

        if (
            this.isCoefficentDatumSetToReferencePoint(
                coefficentDataCalculation.data[0],
            ) ||
            this.isCoefficentDatumSetToReferencePoint(
                coefficentDataCalculation.data[1],
            )
        ) {
            return {
                warnings: coefficentDataCalculation.warnings,
                data: [],
            };
        } else {
            return coefficentDataCalculation;
        }
    }

    private isCoefficentDatumSetToReferencePoint(datum: IDatum): boolean {
        const derivedFieldForDatum = this.derivedField.derivedFrom.find(
            derivedFromItem => derivedFromItem.name === datum.name,
        );

        if (!derivedFieldForDatum) {
            throw new Error(
                `No datum found for derived field ${this.derivedField.name}`,
            );
        }

        return (
            (derivedFieldForDatum as NonInteractionCovariate).referencePoint ===
            datum.coefficent
        );
    }
}
