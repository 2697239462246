import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { CVDPoRT } from '../../store/ui/cvdport';
import { CVDPoRTRiskResult as DumbCVDPoRTRiskResult } from '../dumb/CVDPoRTRiskResult/CVDPoRTRiskResult';

export interface ICVDPoRTRiskResultProps {
    cvdport?: CVDPoRT;
    nextQuestion: Function;
    focus: boolean;
}

@inject('cvdport')
@observer
export class CVDPoRTRiskResult extends React.Component<
    ICVDPoRTRiskResultProps
> {
    render() {
        let risk = Math.round(this.props.cvdport!.riskToTime * 100 * 10) / 10;
        let { nextQuestion, focus } = this.props;

        return (
            // TODO: Give it a life expectancy prop
            <DumbCVDPoRTRiskResult
                nextQuestion={nextQuestion}
                focus={focus}
                risk={risk}
            />
        );
    }
}
