import { observable, computed } from 'mobx';
import { Data } from '@big-life-lab/pbl-calculator-engine/src/engine/data';
import { flatten } from 'lodash';

import { ISurvey } from '@big-life-lab/pbl-limesurvey-engine/lib/survey';
import { ISurveyConfig } from '@big-life-lab/pbl-survey-components/src/models/survey-config';
import {
    Response,
    responseFactoryFromSurvey,
    ResponseType,
    resetNotRelevantResponses,
    findResponseWithQuestionId,
    hasUserGivenResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';

export class SurveyStore {
    survey: ISurvey;
    surveyConfig?: ISurveyConfig;
    @observable
    responses: Response[];

    constructor(surveyJson: ISurvey, surveyConfig?: ISurveyConfig) {
        this.survey = surveyJson;
        this.surveyConfig = surveyConfig;

        this.responses = responseFactoryFromSurvey(this.survey);
    }

    @computed
    get dataFromResponses(): Data {
        return flatten(
            resetNotRelevantResponses(this.responses, this.survey).map(
                this.mapResponseToData,
            ),
        );
    }

    get hasAnsweredAgeAndSexQuestions(): boolean {
        const sex = findResponseWithQuestionId('sex', this.responses);
        const age = findResponseWithQuestionId('age', this.responses);

        if (hasUserGivenResponse(sex) && hasUserGivenResponse(age)) {
            return true;
        } else {
            return false;
        }
    }

    private formatSexResponse(response?: string | null): string {
        return response ? (response === 'fem' ? 'female' : 'male') : 'male';
    }

    private formatAgeResponse(response?: number | null): number {
        return response ? response : 21;
    }

    private mapResponseToData = (response: Response): Data => {
        const NA = undefined;

        if (response.responseType === ResponseType.ConnectedQuestionResponse) {
            return flatten(
                response.response.map(connectedQuestionResponse => {
                    return this.mapResponseToData(connectedQuestionResponse);
                }),
            );
        }
        if (
            response.responseType === ResponseType.TextResponse ||
            response.responseType === ResponseType.AnswerOptionResponse
        ) {
            if (response.questionId === 'sex') {
                return [
                    {
                        name: response.questionId,
                        coefficent: this.formatSexResponse(response.response),
                    },
                ];
            } else {
                return [
                    {
                        name: response.questionId,
                        coefficent:
                            response.response === null ? NA : response.response,
                    },
                ];
            }
        } else if (response.responseType === ResponseType.NumberResponse) {
            if (response.questionId === 'age') {
                return [
                    {
                        name: response.questionId,
                        coefficent: this.formatAgeResponse(response.response),
                    },
                ];
            } else {
                return [
                    {
                        name: response.questionId,
                        coefficent:
                            response.response === null ? NA : response.response,
                    },
                ];
            }
        } else if (
            response.responseType === ResponseType.ArrayBooleanResponse
        ) {
            return response.response.map(subQuestionResponse => {
                return {
                    name: `${response.questionId}_${subQuestionResponse.subQuestionId}`,
                    coefficent:
                        subQuestionResponse.response === undefined
                            ? null
                            : subQuestionResponse.response === true
                            ? 'Yes'
                            : 'No',
                };
            });
        } else if (
            response.responseType === ResponseType.ArrayAnswerOptionResponse
        ) {
            return response.response.map(subQuestionResponse => {
                return {
                    name: `${response.questionId}_${subQuestionResponse.subQuestionId}`,
                    coefficent:
                        subQuestionResponse.response === undefined
                            ? NA
                            : subQuestionResponse.response,
                };
            });
        } else if (response.responseType === ResponseType.ArrayNumberResponse) {
            return response.response.map(subQuestionResponse => {
                return {
                    name: `${response.questionId}_${subQuestionResponse.subQuestionId}`,
                    coefficent:
                        subQuestionResponse.response === undefined
                            ? NA
                            : subQuestionResponse.response,
                };
            });
        } else {
            throw new Error(`Unhandled response type`);
        }
    };
}
