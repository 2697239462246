import '../../../styles/icon-graph.scss';

import * as React from 'react';
import * as d3 from 'd3';

export interface IIconGraphProps {
    columns: number;
    rows: number;
    icon: string;
    iconWidth: number;
    iconHeight: number;
    xGap?: number;
    yGap?: number;
    percentHighlighted: number;
    xMargin?: number;
    yMargin?: number;
}

function renderIconGraph(rootNode: Element, config: IIconGraphProps) {
    let {
        columns,
        rows,
        icon,
        iconWidth,
        iconHeight,
        xGap,
        yGap,
        xMargin,
        yMargin,
        percentHighlighted,
    } = config;

    // Set default values for params
    xGap = xGap || 20;
    yGap = yGap || 10;
    xMargin = xMargin || 10;
    yMargin = yMargin || 10;

    var iconArray = d3.range(columns * rows);

    // Total number of icons
    const numberOfIcons = columns * rows;

    const numberHighlighted = Math.floor(
        percentHighlighted * numberOfIcons * 0.01,
    );

    // Calculate width and height based on icon sizes and row/column counts
    // This is used to set the viewbox dimensions
    // This has nothing to do with the size of the div the svg will be
    // rendered into
    var width = iconWidth * columns + (columns - 1) * xGap + 2 * xMargin;
    var height = iconHeight * rows + (rows - 1) * yGap + 2 * yMargin;

    // Create svg element
    var svg = d3
        .select(rootNode)
        .append('svg')
        .attr('class', 'icon-graph-svg')
        .attr('width', rootNode.clientWidth)
        .attr('height', rootNode.clientHeight);

    // Create group element and create an svg <use> element for each icon
    const iconGraph = svg.append('g');

    // Add the icons to the icon graph
    iconGraph
        .selectAll('path')
        .data(iconArray)
        .enter()
        .append('path')
        .attr('d', icon)
        .attr('transform', function(iconIndex) {
            const rowIndex = Math.floor(iconIndex / numberOfIcons * rows);
            const columnIndex = iconIndex % numberOfIcons - columns * rowIndex;

            const translateX =
                (xMargin as number) +
                columnIndex * iconWidth +
                (xGap as number) * columnIndex;

            const translateY =
                (yMargin as number) +
                rowIndex * iconHeight +
                (yGap as number) * rowIndex;

            return `translate(${translateX}, ${translateY})`;
        })
        .attr('class', function(iconIndex) {
            return iconIndex < numberHighlighted
                ? 'icon-graph__icon icon-graph__icon--selected'
                : 'icon-graph__icon';
        });

    svg
        .attr('viewBox', `0 0 ${width} ${height}`)
        .attr('preserveAspectRatio', 'xMidYMid meet');

    window.addEventListener('resize', () => {
        svg
            .attr('width', rootNode.clientWidth)
            .attr('height', rootNode.clientHeight);
    });

    // Returns function for updating the number of highlighted icons
    return function(newPercentHighlighted: number) {
        iconGraph
            .selectAll('path.icon-graph__icon')
            .data(iconArray)
            .attr('class', function(iconIndex) {
                return iconIndex < newPercentHighlighted
                    ? 'icon-graph__icon icon-graph__icon--selected'
                    : 'icon-graph__icon';
            });
    };
}

export class IconGraph extends React.Component<IIconGraphProps, {}> {
    // prettier-ignore
    containerRef!: Element;
    // prettier-ignore
    updateIconGraph!: (newPercentHighlighted: number) => void;

    componentDidMount() {
        this.updateIconGraph = renderIconGraph(this.containerRef, this.props);
    }
    componentDidUpdate() {
        this.updateIconGraph(this.props.percentHighlighted);
    }
    render() {
        return (
            <div
                className="icon-graph"
                ref={containerRef => {
                    this.containerRef = containerRef as Element;
                }}
            />
        );
    }
}
