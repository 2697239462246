import { SurveyStore } from '../../../../models/survey-store';
import { findDatumWithName } from '@big-life-lab/pbl-calculator-engine/src/engine/data';
import { clamp } from 'lodash';
import { ISurveyConfig } from '@big-life-lab/pbl-survey-components/src/models/survey-config';
import { ISurvey } from '@big-life-lab/pbl-limesurvey-engine/lib/survey';

export class MPoRTSurveyStore extends SurveyStore {
    constructor(
        mportSurveyJson: ISurvey,
        mportSurveyAdditionalConfig: ISurveyConfig,
    ) {
        super(mportSurveyJson, mportSurveyAdditionalConfig);
    }

    get dataFromResponses() {
        const surveyData = super.dataFromResponses;

        const ageDatum = findDatumWithName('age', surveyData);
        /* Clamp the age coefficient to values 0 and 110 since the life table
         we use only goes from ages 0 to 110 */
        ageDatum.coefficent = clamp(ageDatum.coefficent as number, 0, 110);

        return surveyData;
    }
}
