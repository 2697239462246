import { Response, findResponseWithQuestionId } from '../../responses/response';
import * as functions from './functions';
import {
    hasAnsweredSubQuestionWithId,
    findSubQuestionResponse,
} from '../../responses/array-responses/array-response';
import { IEquation } from '../equation/equation';
import { ResponseType } from '../../responses/response-type';

export interface IExpression {
    equation: string;
    equationVars: Array<string | IEquation>;
}

export function evaluateExpression(
    expression: IExpression,
    responses: Response[],
): any {
    let func: any = functions['default'];
    func;

    var obj: {
        [index: string]: any;
    } = {};

    expression.equationVars.forEach((exprVar) => {
        if (typeof exprVar === 'string') {
            const foundResponse = findResponseWithQuestionId(
                getQuestionNameFromEquationVar(exprVar),
                responses,
            );

            obj[exprVar] = getExprVarValueForResponse(foundResponse, exprVar);
        } else {
            obj[exprVar.equationId] = evaluateExpression(
                exprVar.expression,
                responses,
            );
        }
    });

    return eval(expression.equation);
}

function getQuestionNameFromEquationVar(equationVar: string): string {
    if (equationVar.indexOf('_') > -1) {
        return equationVar.split('_')[0];
    } else {
        return equationVar;
    }
}

function getSubQuestionNameFromEquationVar(equationVar: string): string {
    return equationVar.split('_')[1];
}

function getExprVarValueForResponse(
    response: Response,
    exprVar: string,
): string | number | undefined {
    const questionId = getQuestionNameFromEquationVar(exprVar);
    const subQuestionId = getSubQuestionNameFromEquationVar(exprVar);

    switch (response.responseType) {
        case ResponseType.AnswerOptionResponse:
        case ResponseType.NumberResponse:
        case ResponseType.TextResponse: {
            return response.response;
        }
        case ResponseType.DateResponse: {
            return response.response ? response.response.format() : '';
        }
        case ResponseType.ArrayBooleanResponse: {
            return hasAnsweredSubQuestionWithId(response, subQuestionId)
                ? 'Y'
                : '';
        }
        case ResponseType.ArrayNumberResponse:
        case ResponseType.ArrayAnswerOptionResponse: {
            const foundSubQuestionResponse = findSubQuestionResponse(
                response,
                subQuestionId,
            );

            return foundSubQuestionResponse
                ? foundSubQuestionResponse.response
                : '';
        }
        case ResponseType.ConnectedQuestionResponse: {
            if (response.response === undefined) {
                return '';
            }

            for (let i = 0; i < response.response.length; i++) {
                if (response.response[i].questionId === questionId) {
                    return getExprVarValueForResponse(
                        response.response[i],
                        exprVar,
                    );
                }
            }

            throw new Error(
                `No response found for question ${questionId} in connected question ${response.questionId}`,
            );
        }
    }
}
