/**
 * Whether the browser window is currently being embedded or not
 *
 * @export
 * @returns {boolean}
 */
export function isEmbedded(): boolean {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}
