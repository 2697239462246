import '../../../styles/flexible_row_array_question.scss';

import * as React from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { RadioAnswerOption } from '../RadioAnswerOption/RadioAnswerOption';
import {
    IAnswerOption,
    IFlexibleRowArrayQuestion,
    ISubQuestion,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    IArrayAnswerOptionResponse,
    findSubQuestionResponse,
    findResponseWithQuestionId,
    setResponse,
    ArrayResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import {
    getFilteredSubQuestions,
    getText,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import { Question, QuestionProps } from '../Question/Question';
import {
    getKeyHandlersForChoiceQuestion,
    getKeyMapForChoices,
} from '../../utils/choice-question-util';
import { flatten } from 'lodash';
import { computed } from 'mobx';
import { autobind } from 'core-decorators';

export interface FlexibleRowArrayQuestionProps extends QuestionProps<true> {
    question: IFlexibleRowArrayQuestion;
}

@observer
@autobind
export class FlexibleRowArrayQuestion extends React.Component<
    FlexibleRowArrayQuestionProps
> {
    render() {
        return (
            <Question
                {...this.props}
                rootClassName="flexible-row-array-question"
                showContinueButton
                additionalKeyMaps={getKeyMapForChoices(
                    /* Create a new entry for each combination of subquestions and answer options
                    to allow giving each subquestion answer option a unique character */
                    flatten(
                        this.filteredSubQuestions.map(
                            () => this.props.question.answerOptions,
                        ),
                    ),
                )}
                additionalKeyHandlers={getKeyHandlersForChoiceQuestion(
                    this.props,
                    this.updateResponseOnKeyPress,
                    false,
                )}
            >
                {this.renderSubQuestions()}
            </Question>
        );
    }

    private renderSubQuestions() {
        const { question, responses } = this.props;

        const currentResponse = findResponseWithQuestionId(
            question.questionId,
            this.props.responses,
        ) as IArrayAnswerOptionResponse;
        let keypressIndex = 0;

        return this.filteredSubQuestions.map(
            (subQuestion, subQuestionIndex) => {
                const responseForCurrentSubQuestion = findSubQuestionResponse(
                    currentResponse,
                    subQuestion.subQuestionId,
                );
                const key = `${question.questionId}_${subQuestion.subQuestionId}`;

                return (
                    <div
                        key={key}
                        className={classNames(
                            key,
                            'flexible-row-array-question__sub-questions-container',
                            {
                                'flexible-row-array-question__sub-questions-container--last-sub-question':
                                    question.subQuestions.length - 1 ===
                                    subQuestionIndex,
                            },
                        )}
                    >
                        <label>{getText(subQuestion, responses)}</label>
                        <div className="flexible-row-array-question__answer-options-container">
                            {question.answerOptions.map(
                                (answerOption, answerOptionIndex) => (
                                    <RadioAnswerOption
                                        key={answerOptionIndex}
                                        question={question}
                                        isChecked={
                                            responseForCurrentSubQuestion.response ===
                                            answerOption.answerOptionId
                                        }
                                        onChange={() =>
                                            this.setResponse(
                                                subQuestion.subQuestionId,
                                                answerOption.answerOptionId,
                                            )
                                        }
                                        answerOptionText={getText(
                                            answerOption,
                                            responses,
                                        )}
                                        keypressValue={String.fromCharCode(
                                            65 + keypressIndex++,
                                        )}
                                    />
                                ),
                            )}
                        </div>
                    </div>
                );
            },
        );
    }

    private updateResponseOnKeyPress(
        question: IFlexibleRowArrayQuestion,
        _response: IArrayAnswerOptionResponse,
        e: KeyboardEvent,
    ) {
        const keyCode = e.key.charCodeAt(0) - 97;
        let currentKeyCode = 0;

        let subQuestion!: ISubQuestion, answerOption: IAnswerOption | undefined;

        /* Iterate through each combination of subquestion and answer option to find the matching
        index of the associated combination */
        for (const currentSubQuestion of this.filteredSubQuestions) {
            subQuestion = currentSubQuestion;

            answerOption = question.answerOptions.find(() => {
                if (currentKeyCode === keyCode) return true;
                currentKeyCode += 1;
                return false;
            });

            if (answerOption) break;
        }

        this.setResponse(
            subQuestion.subQuestionId,
            answerOption!.answerOptionId,
        );
    }

    private setResponse(subQuestionId: string, answerOptionId: string) {
        const response = findResponseWithQuestionId(
            this.props.question.questionId,
            this.props.responses,
        ) as IArrayAnswerOptionResponse;

        setResponse(response, {
            subQuestionId,
            response: answerOptionId,
        });

        this.props.onChange();
    }

    @computed
    private get filteredSubQuestions() {
        return getFilteredSubQuestions(
            this.props.question,
            this.props.responses as ArrayResponse[],
        );
    }
}
