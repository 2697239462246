import { ArrayResponse, hasAnsweredSubQuestionWithId } from '../../responses/array-responses/array-response';
import { ISubQuestion } from '../sub-question/sub-question';

export interface ISubQuestionMixin {
    subQuestions: ISubQuestion[];
    filter?: string;
}

export function getFilteredSubQuestions(
    subQuestionMixin: ISubQuestionMixin,
    responses: ArrayResponse[],
): Array<ISubQuestion> {
    if (!subQuestionMixin.filter) {
        return subQuestionMixin.subQuestions;
    }

    //Find the response with the same questionId as the arrayFilter
    try {
        var responseFilter = responses.find(response => {
            return response.questionId === subQuestionMixin.filter;
        });


        if (responseFilter === undefined) {
            return [];
        }
        //If there was a response return an array containing only those sub questions 
        // whose subQuestionId is part of the found response
        else {
            return subQuestionMixin.subQuestions.filter(subQuestion => {
                if (responseFilter) {
                    return hasAnsweredSubQuestionWithId(responseFilter, subQuestion.subQuestionId);
                } else {
                    return true;
                }
            });
        }
    }
    catch (error) {
        return subQuestionMixin.subQuestions;
    }

}