import { observable } from 'mobx';
import { autobind } from 'core-decorators';

export class HeartAge {
    @observable referenceSelected: ReferenceSelected;

    constructor() {
        this.referenceSelected = ReferenceSelected.Mean;
    }

    @autobind
    toggleReference() {
        if (this.referenceSelected === ReferenceSelected.Mean) {
            this.referenceSelected = ReferenceSelected.Healthy;
        } else {
            this.referenceSelected = ReferenceSelected.Mean;
        }
    }
}

export enum ReferenceSelected {
    Mean,
    Healthy,
}
