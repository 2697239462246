import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { CVDPoRT } from '../../store/ui/cvdport';
import { CVDPoRTIconGraph as DumbCVDPoRTIconGraph } from '../dumb/CVDPoRTIconGraph/CVDPoRTIconGraph';

export interface CVDPoRTIconGraphProps {
    cvdport?: CVDPoRT;
    nextQuestion: Function;
    focus: boolean;
}

@inject('cvdport')
@observer
export class CVDPoRTIconGraph extends React.Component<CVDPoRTIconGraphProps> {
    get formattedRisk() {
        return Math.round(this.props.cvdport!.riskToTime * 100 * 10) / 10;
    }
    get percentHighlighted() {
        return Math.round(this.props.cvdport!.riskToTime * 100);
    }
    render() {
        return (
            <DumbCVDPoRTIconGraph
                risk={this.formattedRisk}
                percentHighlighted={this.percentHighlighted}
                nextQuestion={this.props.nextQuestion}
                focus={this.props.focus}
            />
        );
    }
}
