import './short-report.scss';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { observer } from 'mobx-react';

export interface IShortReportProps {
    value: number;
    showShortReport: boolean;
    shortReportValueDivId: string;
    shortReportContainerId: string;
}

@observer
export class ShortReport extends React.Component<IShortReportProps> {
    hideOrShowShortReport() {
        if (this.props.showShortReport) {
            document
                .getElementById(this.props.shortReportContainerId)!
                .setAttribute('style', `visibility: visible;`);
        } else {
            document
                .getElementById(this.props.shortReportContainerId)!
                .setAttribute('style', `visibility: hidden;`);
        }
    }
    componentDidMount() {
        this.hideOrShowShortReport();
    }
    componentDidUpdate() {
        this.hideOrShowShortReport();
    }
    render() {
        return ReactDOM.createPortal(
            <div>{this.props.value}</div>,
            document.getElementById(
                this.props.shortReportValueDivId,
            ) as HTMLElement,
        );
    }
}
