import ScrollPercentage from 'react-scroll-percentage';

//react
import * as React from 'react';
import classnames from 'classnames';
import { isEmbedded } from '../../utils/browser';

export interface QuestionWrapperProps {
    questionId: string;
    updateQuestionVisibility: Function;
    targetQuestion: boolean;
    current: boolean;
    children(percentage: number, inView: boolean): any;
}

export interface ScrollPercentageProps {
    percentage: number;
    inView: boolean;
}

export default class QuestionWrapper extends React.Component<
    QuestionWrapperProps,
    {}
> {
    constructor(props: QuestionWrapperProps) {
        super(props);
        this.node = React.createRef();
    }

    // TODO: I tried to use PureComponent instead of this but it's not working
    shouldComponentUpdate(nextProps: QuestionWrapperProps) {
        return (
            this.props.current !== nextProps.current ||
            this.props.targetQuestion !== nextProps.targetQuestion
        );
    }

    node: React.RefObject<typeof ScrollPercentage>;

    componentDidUpdate(prevProps: QuestionWrapperProps) {
        if (
            this.props.targetQuestion &&
            prevProps.targetQuestion !== this.props.targetQuestion
        ) {
            const element = this.node.current.node;
            const elementRect = element.getBoundingClientRect();
            const absoluteElementTop = elementRect.top + window.pageYOffset;

            // If the element is taller than the screen, scroll to the top of the element
            // else scroll it into the middle of the view
            // 66 and 63 refer to the heights of the navbar and footer
            const scrollTo =
                elementRect.height > window.innerHeight - 66 - 63
                    ? absoluteElementTop
                    : absoluteElementTop -
                      window.innerHeight / 2 +
                      elementRect.height / 2;

            window.scroll({
                left: 0,
                top: scrollTo,
                behavior: 'smooth',
            });
        }
    }

    render() {
        return isEmbedded() ? (
            this.renderQuestionWrapperChild(0, false)
        ) : (
            <ScrollPercentage
                ref={this.node}
                tag="div"
                onChange={(percentage: number) =>
                    this.props.updateQuestionVisibility(
                        this.props.questionId,
                        percentage,
                    )
                }
            >
                {({ percentage, inView }: ScrollPercentageProps) => {
                    return this.renderQuestionWrapperChild(percentage, inView);
                }}
            </ScrollPercentage>
        );
    }
    private renderQuestionWrapperChild(percentage: number, inView: boolean) {
        return (
            <div
                className={classnames('question-wrapper', {
                    current: this.props.current,
                    'question-wrapper--embedded': isEmbedded(),
                })}
            >
                {this.props.children(percentage, inView)}
            </div>
        );
    }
}
