import './health-age.scss';

import * as React from 'react';
import * as d3 from 'd3';
import { observer } from 'mobx-react';
import { RadioAnswerOption } from '@big-life-lab/pbl-survey-components/src/components/RadioAnswerOption/RadioAnswerOption';
import { HotKeys } from 'react-hotkeys';
import classnames from 'classnames';
import { isEmbedded } from '@ottawamhealth/pbl-utils/lib/browser';

export interface IHealthAgeProps {
    lowerAge: number;
    upperAge: number;
    healthAge: number;
    currentAge: number;
    nextQuestion: Function;
    referenceSelected: 'mean' | 'healthy';
    sex: string;
    toggleReference: () => void;

    focus: boolean;
}

@observer
export class HealthAge extends React.Component<IHealthAgeProps> {
    healthAge!: any;

    divRef!: HTMLDivElement;

    handlers = {
        nextQuestion: () => this.props.nextQuestion(),
    };

    componentDidUpdate(prevProps: IHealthAgeProps, _prevState: {}) {
        //console.log('CDU');
        // console.log(`CDU ${prevProps.focus} - ${this.props.focus}`);
        if (!prevProps.focus && this.props.focus) {
            // Timer allows the question-wrapper's scrollIntoView call time to happen
            // before calling focus on this field intercepts the scroll
            // preventScroll option has limited browser compatibility
            setTimeout(() => {
                //@ts-ignore
                this.divRef.focus({ preventScroll: true });
            }, 100);
        }
        this.healthAge = healthAge();
        d3.select(this.rootNode)
            .datum(this.props)
            .call(this.healthAge);
    }

    //Set in ref callback
    rootNode!: HTMLDivElement;
    componentDidMount() {
        this.healthAge = healthAge();
        d3.select(this.rootNode)
            .datum(this.props)
            .call(this.healthAge);
    }
    render() {
        const keyMap = {
            nextQuestion: 'return',
        };
        const { currentAge, referenceSelected, toggleReference } = this.props;

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={this.handlers}
                focused={this.props.focus}
            >
                <div
                    ref={c => (this.divRef = c as HTMLDivElement)}
                    tabIndex={5}
                    id="mport-health-age"
                    className={classnames('health-age-wrapper', {
                        'health-age-result--embedded': isEmbedded(),
                    })}
                >
                    <h3>What is your health age?</h3>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: `Health age compares your life expectancy to the life
                        expectancy of other Canadian${' '}
        <strong>${currentAge} year old ${
                                this.props.sex
                            }</strong>. The difference is
                        added or subtracted from your current age.`,
                        }}
                    />
                    <div className="compare-me-to-div">
                        <strong>Compare me to:</strong>
                        <RadioAnswerOption
                            key={'health-age-average-canadian'}
                            isChecked={referenceSelected === 'mean'}
                            hideCheckmarks={true}
                            onChange={() => {
                                if (referenceSelected === 'healthy') {
                                    toggleReference();
                                }
                            }}
                            answerOptionText={'An Average Canadian'}
                        />
                        {/*<RadioAnswerOption
                            key={'health-age-healthy-canadian'}
                            isChecked={referenceSelected === 'healthy'}
                            hideCheckmarks={true}
                            onChange={() => {
                                if (referenceSelected === 'mean') {
                                    toggleReference();
                                }
                            }}
                        answerOptionText={'A Healthy Canadian'}/>*/}
                    </div>
                    <p>
                        The health of your body is comparable to{' '}
                        {`${getPopulationText(referenceSelected)} `}
                        <strong>{this.props.healthAge}</strong> year old
                        Canadian {this.props.sex}.
                        {/*<Tooltip
                            placement="top"
                            id="what-is-health-canadian"
                            title={'The average healthy canadian is...'}
                        >
                            <Icon className="fas fa-info">info</Icon>
                        </Tooltip>*/}
                    </p>
                    <div
                        className="health-age"
                        ref={rootNode => {
                            this.rootNode = rootNode as HTMLDivElement;
                        }}
                    />
                </div>
            </HotKeys>
        );
    }
}

function getPopulationText(
    referenceSelected: IHealthAgeProps['referenceSelected'],
): string {
    return referenceSelected === 'mean' ? 'the average' : 'a healthy';
}

const ClassNames = {
    greyBarRect: 'health-age__grey-bar-rect',
    greyBarContainer: 'health-age__grey-bar-container',
    blueBarRect: 'health-age__blue-bar-rect',
    blueBarContainer: 'health-age__blue-bar-container',
    blueBarTextContainer: 'health-age__blue-bar-text-container',
    blueBarHeartAgeNumberText: 'health-age__health-age-number-text',
    blueBarHeartAgeYearsText: 'health-age__health-age-years-text',
    currentAgeContainer: 'health-age__current-age-container',
    currentAgeCircle: 'health-age__current-age-circle',
    currentAgeLine: 'health-age__current-age-line',
    currentAgeText: 'health-age__current-age-text',
    lowerAgeBarText: 'health-age__lower-age-bar-text',
    upperAgeText: 'health-age__higher-age-bar-text',
};

function healthAge() {
    const BarHeight = 60;
    const renderCurrentAge = currentAge();

    const renderBlueBar = blueBar({
        barHeight: BarHeight,
    });

    const renderBlueBarText = blueBarText();

    return function(selection: d3.Selection<any, IHealthAgeProps, any, any>) {
        const currentSelectionRect = (selection.node() as Element).getBoundingClientRect();
        const d = selection.datum();

        const ageScale = d3
            .scaleLinear()
            .domain([d.lowerAge, d.upperAge])
            .range([0, currentSelectionRect.width]);

        const svg = selection.selectAll('svg').data([d]);
        const svgEnter = svg
            .enter()
            .append('svg')
            .attr('width', currentSelectionRect.width)
            .attr('height', currentSelectionRect.height);

        const renderGreyBar = greyBar({
            barHeight: BarHeight,
            barWidth: currentSelectionRect.width,
        });
        svgEnter
            .append('g')
            .attr('class', ClassNames.greyBarContainer)
            .call(renderGreyBar);

        const blueBarWidth = ageScale(d.healthAge);
        const blueBarData = [
            Object.assign({}, d, {
                barWidth: blueBarWidth,
            }),
        ];
        const blueBar = svg
            .selectAll<
                SVGGElement,
                IHealthAgeProps & {
                    barWidth: number;
                }
            >(`.${ClassNames.blueBarContainer}`)
            .data(blueBarData);
        const blueBarEnter = svgEnter
            .append('g')
            .data(blueBarData)
            .attr('class', ClassNames.blueBarContainer);
        blueBarEnter.merge(blueBar).call(renderBlueBar);

        const blueBarTextContainer = svg
            .selectAll<SVGGElement, IHealthAgeProps>(
                `.${ClassNames.blueBarTextContainer}`,
            )
            .data([d]);
        const blueBarTextContainerEnter = svgEnter
            .append('g')
            .attr('class', ClassNames.blueBarTextContainer)
            .data([d]);
        blueBarTextContainerEnter
            .merge(blueBarTextContainer)
            .call(renderBlueBarText);

        const currentAgeTranslateX =
            ageScale(d.currentAge) - renderCurrentAge.getCircleRadius();
        const currentAge = svg
            .selectAll<SVGGElement, IHealthAgeProps>(
                `.${ClassNames.currentAgeContainer}`,
            )
            .data([d]);
        const currentAgeEnter = svgEnter
            .append('g')
            .attr('class', ClassNames.currentAgeContainer)
            .data([d]);
        currentAgeEnter
            .merge(currentAge)
            .call(renderCurrentAge)
            .attr('transform', function() {
                return `translate(${currentAgeTranslateX}, ${BarHeight / 2 - renderCurrentAge.getCircleRadius()})`;
            });

        const blueBarTextRightMargin = 15;
        const blueBarTextWidth = 122 + blueBarTextRightMargin;

        blueBarTextContainerEnter
            .merge(blueBarTextContainer)
            .attr('transform', function(d) {
                const node = d3.select(this).node() as SVGGElement;
                const width = node.getBoundingClientRect().width;

                let blueBarTextTranslateX =
                    ageScale(d.healthAge) - width - blueBarTextRightMargin;
                /* get the x corrdinate of the top right corner of the 
                currentAgeText g box */
                const currentAgeYEnd =
                    currentAgeTranslateX +
                    renderCurrentAge.getCircleRadius() * 2;
                /* Check whether the blue bar text is on top of the current age 
                circle*/
                if (
                    blueBarTextTranslateX < currentAgeTranslateX &&
                    blueBarTextTranslateX + blueBarTextWidth > currentAgeYEnd
                ) {
                    /* If it is then check whether there is enough of the blue
                     bar on the other side of it to move it there */
                    if (blueBarWidth - currentAgeYEnd > blueBarTextWidth) {
                        blueBarTextTranslateX =
                            currentAgeYEnd + blueBarTextRightMargin;
                    } else {
                        /* Otherwise move it to be behind the current age circle */
                        blueBarTextTranslateX =
                            currentAgeTranslateX - blueBarTextWidth;
                    }
                }

                return `translate(${blueBarTextTranslateX}, ${-5})`;
            });
    };
}

function greyBar({
    barHeight,
    barWidth,
}: {
    barHeight: number;
    barWidth: number;
}) {
    const MarginBetweenBarAndText = 10;

    const TextFontSize = 20;

    return function(selection: d3.Selection<any, IHealthAgeProps, any, any>) {
        selection.each(function(d) {
            const currentSelection = d3.select(this);

            const bar = currentSelection
                .selectAll(`.${ClassNames.greyBarRect}`)
                .data([d]);
            bar.enter()
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', barWidth)
                .attr('height', barHeight)
                .attr('rx', 5)
                .attr('ry', 5)
                .attr('class', ClassNames.greyBarRect);

            currentSelection
                .selectAll(`.${ClassNames.lowerAgeBarText}`)
                .data([d])
                .enter()
                .append('text')
                .attr('x', 0)
                .attr('y', barHeight + MarginBetweenBarAndText + TextFontSize)
                .attr('class', ClassNames.lowerAgeBarText)
                .attr('font-size', TextFontSize)
                .text(function(d) {
                    return `Age ${d.lowerAge}`;
                });

            currentSelection
                .selectAll(`.${ClassNames.upperAgeText}`)
                .data([d])
                .enter()
                .append('text')
                .attr('x', barWidth - 70)
                .attr('y', barHeight + MarginBetweenBarAndText + TextFontSize)
                .attr('class', ClassNames.lowerAgeBarText)
                .attr('font-size', TextFontSize)
                .text(function(d) {
                    return `Age ${d.upperAge}`;
                });
        });
    };
}

function blueBar({ barHeight }: { barHeight: number }) {
    return function(
        selection: d3.Selection<
            any,
            IHealthAgeProps & { barWidth: number },
            any,
            any
        >,
    ) {
        selection.each(function(d) {
            const container = d3.select(this);

            const blueBar = container
                .selectAll<SVGRectElement, IHealthAgeProps>(
                    `.${ClassNames.blueBarRect}`,
                )
                .data([d]);
            const blueBarEnter = blueBar
                .enter()
                .append('rect')
                .attr('class', ClassNames.blueBarRect)
                .attr('x', 0)
                .attr('y', 0)
                .attr('height', barHeight)
                .attr('rx', 5)
                .attr('ry', 5);
            blueBarEnter.merge(blueBar).attr('width', function(d) {
                return d.barWidth;
            });
        });
    };
}

function blueBarText() {
    const HeartAgeFontSize = 50;
    const YearsTestFontSize = 25;

    return function(selection: d3.Selection<any, IHealthAgeProps, any, any>) {
        selection.each(function(d) {
            const container = d3.select(this);

            const heartAgeText = container
                .selectAll<SVGTextElement, IHealthAgeProps>(
                    `.${ClassNames.blueBarHeartAgeNumberText}`,
                )
                .data([d]);
            const heartAgeTextEnter = heartAgeText
                .enter()
                .append('text')
                .attr('class', ClassNames.blueBarHeartAgeNumberText)
                .attr('x', 0)
                .attr('y', HeartAgeFontSize)
                .attr('font-size', HeartAgeFontSize);
            heartAgeTextEnter.merge(heartAgeText).text(function(d) {
                return d.healthAge;
            });

            heartAgeText
                .enter()
                .append('text')
                .attr('class', ClassNames.blueBarHeartAgeYearsText)
                .attr('font-size', YearsTestFontSize)
                .attr('x', 60)
                .attr('y', HeartAgeFontSize)
                .text('Years');
        });
    };
}

function currentAge(): {
    (selection: d3.Selection<any, IHealthAgeProps, any, any>): void;
    getCircleRadius: () => number;
} {
    const CircleRadius = 10;

    const LineLength = 120;

    const MarginBetweenTextAndLine = 10;
    const TextFontSize = 20;

    const renderCurrentAge = function(
        selection: d3.Selection<any, IHealthAgeProps, any, any>,
    ) {
        selection.each(function(d) {
            const container = d3.select(this);

            container
                .selectAll(`.${ClassNames.blueBarRect}`)
                .data([d])
                .enter()
                .append('circle')
                .attr('class', ClassNames.currentAgeCircle)
                .attr('cx', CircleRadius)
                .attr('cy', CircleRadius)
                .attr('r', CircleRadius)
                .attr('class', ClassNames.currentAgeCircle);

            container
                .selectAll(`.${ClassNames.currentAgeLine}`)
                .data([d])
                .enter()
                .append('path')
                .attr('class', ClassNames.currentAgeLine)
                .attr(
                    'd',
                    d3.line()([
                        [CircleRadius, CircleRadius * 2],
                        [CircleRadius, CircleRadius * 2 + LineLength],
                    ]) as string,
                );

            const currentAgeText = container
                .selectAll<SVGTextElement, IHealthAgeProps>(
                    `.${ClassNames.currentAgeText}`,
                )
                .data([d]);
            const currentAgeTextEnter = currentAgeText
                .enter()
                .append('text')
                .attr('class', ClassNames.currentAgeText)
                .attr('font-size', TextFontSize)
                .attr('x', CircleRadius + MarginBetweenTextAndLine)
                .attr('y', CircleRadius * 2 + LineLength - 15);
            currentAgeTextEnter.merge(currentAgeText).text(function(d) {
                return `Your Age: ${d.currentAge}`;
            });
        });
    };

    return Object.assign(renderCurrentAge, {
        getCircleRadius: () => {
            return CircleRadius;
        },
    });
}
