import './cvdport-icon-graph.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import AppearingNextButton from '@big-life-lab/pbl-survey-components/src/components/AppearingNextButton/AppearingNextButton';
import { IconGraph } from '../../../../../components/dumb/IconGraph';
import { Cates } from '../../../../../icons/cates';
import { HotKeys } from 'react-hotkeys';

export interface ICVDPoRTIconGraphProps {
    risk: number;
    percentHighlighted: number;
    nextQuestion: Function;
    focus: boolean;
}

@observer
export class CVDPoRTIconGraph extends React.Component<ICVDPoRTIconGraphProps> {
    divRef!: HTMLDivElement;

    handlers = {
        nextQuestion: () => {
            this.props.nextQuestion();
        },
    };

    componentDidUpdate(prevProps: ICVDPoRTIconGraphProps, _prevState: {}) {
        //console.log('CDU');
        // console.log(`CDU ${prevProps.focus} - ${this.props.focus}`);
        if (!prevProps.focus && this.props.focus) {
            // Timer allows the question-wrapper's scrollIntoView call time to happen
            // before calling focus on this field intercepts the scroll
            // preventScroll option has limited browser compatibility
            setTimeout(() => {
                //@ts-ignore
                this.divRef.focus({ preventScroll: true });
            }, 100);
        }
    }

    render() {
        const keyMap = {
            nextQuestion: 'return',
        };

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={this.handlers}
                focused={this.props.focus}
            >
                <div
                    ref={c => (this.divRef = c as HTMLDivElement)}
                    tabIndex={5}
                    id="cvd-port-icon-graph"
                    className="cvdport-icon-graph"
                >
                    <h3>
                        In other words, for 100 people with the same
                        responses...
                    </h3>
                    <p className="cvdport-icon-graph__icon-graph-text">
                        <div>
                            <strong className="survival">
                                {`${this.props.percentHighlighted}`}
                            </strong>{' '}
                            people will have a heart attack or stroke.
                            <strong className="mortality">
                                {' '}
                                {`${100 - this.props.percentHighlighted}`}
                            </strong>{' '}
                            people will not have a heart attack or stroke.
                        </div>
                    </p>
                    <div className="cvdport-icon-graph__icon-graph-container">
                        <div className="cvdport-icon-graph__icon-graph">
                            <IconGraph
                                columns={10}
                                rows={10}
                                icon={Cates}
                                iconHeight={29}
                                iconWidth={20}
                                percentHighlighted={
                                    this.props.percentHighlighted
                                }
                            />
                        </div>
                    </div>

                    <AppearingNextButton onClick={this.props.nextQuestion} />
                </div>
            </HotKeys>
        );
    }
}
