import * as React from 'react';
import { QuestionText } from '../QuestionText/QuestionText';
import AppearingNextButton from '../AppearingNextButton/AppearingNextButton';
import classnames from 'classnames';
import { HotKeys } from 'react-hotkeys';
import { computed } from 'mobx';
import {
    Question as LimesurveyQuestion,
    getQuestionText,
    QuestionType,
    isConnectedQuestion,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import { Response } from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import { isEmbedded } from '../../utils/browser';

/**
 *
 *
 * @export
 * @interface QuestionProps
 * @template T {boolean} Type of showContinueButton prop
 */
export interface QuestionProps<T extends boolean> {
    rootClassName: string;
    question: LimesurveyQuestion;
    /**
     * Whether this is the first question of the entire survey
     *
     * @type {boolean}
     * @memberof QuestionProps
     */
    isFirstQuestion: boolean;
    responses: Response[];
    focus: boolean;
    onChange: () => void;
    additionalKeyMaps?: {};
    additionalKeyHandlers?: {};
    showContinueButton: T;
    scrollToNextQuestion: T extends true ? () => void : undefined;
    isContinueButtonVisible?: T extends true ? boolean : undefined;
    isContinueButtonDisabled?: T extends true ? boolean : undefined;
}

export class Question extends React.Component<QuestionProps<any>> {
    questionDivRef!: HTMLDivElement;
    componentDidUpdate(prevProps: QuestionProps<any>) {
        /* If this question has come into focus */
        if (this.shouldFocusQuestion(prevProps) === true) {
            /* Timer allows the question-wrapper's scrollIntoView call time to happen
       before calling focus on this field intercepts the scroll preventScroll
       option has limited browser compatibility */
            setTimeout(() => {
                //@ts-ignore
                this.questionDivRef.focus({ preventScroll: true });
            }, 100);
        }
    }
    render() {
        const { question, isFirstQuestion: firstQuestion } = this.props;
        const helpText = this.renderHelpText();

        return (
            <HotKeys
                focused={this.props.focus}
                keyMap={this.keyMaps}
                handlers={this.keyMapHandlers}
            >
                <div
                    id={question.questionId}
                    className={classnames(
                        'question',
                        this.props.rootClassName,
                        {
                            'question--embedded': isEmbedded(),
                            'question--large-screen-focus-fix':
                                firstQuestion === true &&
                                isEmbedded() === false,
                        },
                    )}
                    ref={(questionRef) => {
                        this.questionDivRef = questionRef as HTMLDivElement;
                    }}
                    tabIndex={-1}
                >
                    <QuestionText
                        questionText={this.questionText}
                        hasHelpText={helpText !== undefined}
                    />
                    {helpText}
                    {this.props.children}
                    {this.showContinueButton ? (
                        <AppearingNextButton
                            onClick={this.props.scrollToNextQuestion}
                            visible={this.props.isContinueButtonVisible}
                            disabled={this.props.isContinueButtonDisabled}
                        />
                    ) : null}
                </div>
            </HotKeys>
        );
    }
    private get keyMaps() {
        const QuestionKeyMaps = {
            onEnterButton: 'return',
        };

        const { additionalKeyMaps } = this.props;

        return Object.assign(
            {},
            this.props.showContinueButton ? QuestionKeyMaps : undefined,
            additionalKeyMaps,
        );
    }
    private get keyMapHandlers() {
        const QuestionHandlers = {
            onEnterButton: () => {
                this.props.scrollToNextQuestion!();
            },
        };

        const { additionalKeyHandlers } = this.props;

        return Object.assign(
            {},
            this.props.showContinueButton ? QuestionHandlers : undefined,
            additionalKeyHandlers,
        );
    }
    @computed
    private get questionText() {
        const { responses } = this.props;

        return getQuestionText(this.props.question, responses);
    }
    @computed
    private get showContinueButton() {
        return this.props.showContinueButton && isEmbedded() === false;
    }

    private shouldFocusQuestion(prevProps: QuestionProps<any>) {
        const { questionType } = this.props.question;

        /* Only focus questions that don't have a text or number input field. Otherwise,
        the question would be focused when the input field should be focused */
        const isQuestionWithoutTextField =
            questionType === QuestionType.MultipleChoiceQuestion ||
            questionType === QuestionType.RadioQuestion ||
            questionType === QuestionType.TextDisplayQuestion;

        const hasComeIntoFocus =
            prevProps.focus === false && this.props.focus === true;

        // Focus only if:
        // - It's a question without a text field
        // - It was previously not focused but now should be focused
        return isQuestionWithoutTextField && hasComeIntoFocus;
    }

    private renderHelpText() {
        let helpText: string;
        if (isConnectedQuestion(this.props.question)) {
            helpText = this.props.question.questions[0].help;
        } else {
            helpText = this.props.question.help;
        }

        if (helpText.trim().length === 0) {
            return undefined;
        }

        return (
            <div
                className="question__help-text"
                dangerouslySetInnerHTML={{ __html: helpText }}
            ></div>
        );
    }
}
