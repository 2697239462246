import '../../../styles/question-text.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { QuestionHelper } from '../QuestionHelper/QuestionHelper';

export interface IQuestionTextProps {
    questionText: string;
    hasHelpText: boolean;
}

// @ts-ignore
export const QuestionText: React.SFC<IQuestionTextProps> = observer(
    ({ questionText, hasHelpText }: IQuestionTextProps) => {
        return (
            <div className="question-text-container">
                <div
                    className={classNames('question-text', {
                        'question-text--with-help-text': hasHelpText,
                    })}
                    dangerouslySetInnerHTML={{ __html: questionText }}
                ></div>

                <QuestionHelper questionText={questionText} />
            </div>
        );
    },
);
