import 'babel-polyfill';
import './ga';

import { MPoRTSurveyId, CVDPoRTSurveyId } from './constants/constants';
import * as React from 'react';
import { render } from 'react-dom';
import { ProjectBigLife } from './components/smart/ProjectBigLife';
import { CVDPoRTCalculator } from './components/smart/CVDPoRTCalculator/CVDPoRTCalculator';
import { env } from '@big-life-lab/pbl-calculator-engine';
import { isEmbedded } from '@ottawamhealth/pbl-utils/lib/browser';
//@ts-ignore
window.env = env;
// Maps the id of a certain HTML element to the component that should be rendered within it
const IdToComponentMappings = [
    {
        nodeId: MPoRTSurveyId,
        component: <ProjectBigLife />,
    },
    {
        nodeId: CVDPoRTSurveyId,
        component: <CVDPoRTCalculator />,
    },
];
IdToComponentMappings
    // Go through each mapping and find the HTML element found for the nodeId
    .map(({ nodeId }) => {
        return document.getElementById(nodeId);
    })
    //If the node is defined i.e. it exists in the DOM then render the corresponding component mapped to the node with that id
    .forEach((node, index) => {
        if (node) {
            if (isEmbedded()) {
                /* We do this here since this class is added on the webflow and
        there's no easy webflow way of detecting if the website is embedded
        and add classes based on that. */
                $('.survey-container').addClass('survey-container--embedded');
            }

            render(IdToComponentMappings[index].component, node);
        }
    });

addWindowGlobalVariables();
console.log(
    `To enter debugging mode run the code in the console:
    window.env.setEnvironmentToDebugging()
    For less debugging:
    window.env.setEnvironmentToLessDebugging()`,
);

function addWindowGlobalVariables() {
    // @ts-ignore
    window.env = env;
    // @ts-ignore
    window.VERSION = process.env.VERSION;
}
