import { ICoxSurvivalAlgorithmJson } from '@big-life-lab/pbl-calculator-engine/src/parsers/json/json-cox-survival-algorithm';
import { CoxSurvivalAlgorithm } from '@big-life-lab/pbl-calculator-engine/src/engine/algorithm/regression-algorithm/cox-survival-algorithm/cox-survival-algorithm';
import { Data } from '@big-life-lab/pbl-calculator-engine';
import { findDatumWithName } from '@big-life-lab/pbl-calculator-engine/src/engine/data';
import { getHealthAge } from '@big-life-lab/pbl-calculator-engine/src/engine/ref-pop/health-age';
import { ReferencePopulation } from '@big-life-lab/pbl-calculator-engine/src/engine/ref-pop/reference-population';

export class CVDPoRTAlgorithm {
    private models: {
        maleModel: CoxSurvivalAlgorithm;
        femaleModel: CoxSurvivalAlgorithm;
    };
    private healthyRefPops: {
        maleRefPop: ReferencePopulation;
        femaleRefPop: ReferencePopulation;
    };
    private meanRefPops: {
        maleRefPop: ReferencePopulation;
        femaleRefPop: ReferencePopulation;
    };

    constructor(
        maleModelJson: ICoxSurvivalAlgorithmJson,
        femaleModelJson: ICoxSurvivalAlgorithmJson,
        meanRefPopJson: any,
        healthyRefPopJson: any,
    ) {
        this.models = {
            maleModel: new CoxSurvivalAlgorithm(maleModelJson),
            femaleModel: new CoxSurvivalAlgorithm(femaleModelJson),
        };
        this.healthyRefPops = {
            maleRefPop: healthyRefPopJson.male,
            femaleRefPop: healthyRefPopJson.female,
        };
        this.meanRefPops = {
            maleRefPop: meanRefPopJson.male,
            femaleRefPop: meanRefPopJson.female,
        };
    }

    getRiskToTime(data: Data): number {
        let risk: ReturnType<CoxSurvivalAlgorithm['getRiskToTime']>;
        const sex = findDatumWithName('sex', data).coefficent as string;
        if (sex === 'male') {
            risk = this.models.maleModel.getRiskToTime(data);
        } else {
            risk = this.models.femaleModel.getRiskToTime(data);
        }
        if ('risk' in risk) {
            return risk.risk;
        }

        console.log('Errors when calculating risk');
        console.log(JSON.stringify(risk, null, 4));
        return NaN;
    }

    getMeanHealthAge(data: Data): number {
        let meanHealthAge: ReturnType<typeof getHealthAge>;
        const sex = findDatumWithName('sex', data).coefficent as string;
        if (sex === 'male') {
            meanHealthAge = getHealthAge(
                this.meanRefPops.maleRefPop,
                data,
                this.models.maleModel,
            );
        } else {
            meanHealthAge = getHealthAge(
                this.meanRefPops.femaleRefPop,
                data,
                this.models.femaleModel,
            );
        }
        if ('healthAge' in meanHealthAge) {
            return meanHealthAge.healthAge;
        }

        console.log('Errors when calculating mean health age');
        console.log(JSON.stringify(meanHealthAge, null, 4));
        return NaN;
    }

    getHealthyHealthAge(data: Data): number {
        let healthyHealthAge: ReturnType<typeof getHealthAge>;
        const sex = findDatumWithName('sex', data).coefficent as string;
        if (sex === 'male') {
            healthyHealthAge = getHealthAge(
                this.healthyRefPops.maleRefPop,
                data,
                this.models.maleModel,
            );
        } else {
            healthyHealthAge = getHealthAge(
                this.healthyRefPops.femaleRefPop,
                data,
                this.models.femaleModel,
            );
        }
        if ('healthAge' in healthyHealthAge) {
            return healthyHealthAge.healthAge;
        }

        console.log('Errors when calculating healthy health age');
        console.log(JSON.stringify(healthyHealthAge, null, 4));
        return NaN;
    }
}
