import {
    RefLifeTable,
    IGenderSpecificRefLifeTable,
    CompleteLifeTable,
    getCompleteLifeTableForDataUsingAlgorithm,
} from './life-table';
import { getLifeExpectancyForAge } from './life-expectancy';
import { getSurvivalToAge } from './survival-to-age';
import { Data, findDatumWithName } from '../data';
import { NoLifeTableFoundError } from '../errors';
import { Model } from '../model/model';
import { autobind } from 'core-decorators';
import memoizeOne from 'memoize-one';
import { CoxSurvivalAlgorithm } from '../model';
import { IValidationError } from '../../validation/validate/validation-error';

@autobind
export class LifeTableFunctions {
    model: Model<CoxSurvivalAlgorithm>;
    private genderSpecificRefLifeTable: IGenderSpecificRefLifeTable;
    private useExFromAge: number;

    constructor(
        model: Model<CoxSurvivalAlgorithm>,
        genderSpecificRefLifeTable: IGenderSpecificRefLifeTable,
        useExFromAge: number = 99,
    ) {
        this.model = model;
        this.genderSpecificRefLifeTable = genderSpecificRefLifeTable;
        this.useExFromAge = useExFromAge;
    }

    public getLifeExpectancy(
        data: Data,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              lifeExpectancy: number;
          } {
        const completeLifeTableCalculation = this.getCompleteLifeTable(data);
        if (completeLifeTableCalculation instanceof Array) {
            return completeLifeTableCalculation;
        }

        return {
            warnings: completeLifeTableCalculation.warnings,
            lifeExpectancy: getLifeExpectancyForAge(
                Number(findDatumWithName('age', data).coefficent as number),
                completeLifeTableCalculation.completeLifeTable,
            ),
        };
    }

    public getSurvivalToAge(
        data: Data,
        toAge: number,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              survivalProbability: number;
          } {
        const completeLifeTableCalculation = this.getCompleteLifeTable(data);
        if (completeLifeTableCalculation instanceof Array) {
            return completeLifeTableCalculation;
        }
        return {
            warnings: completeLifeTableCalculation.warnings,
            survivalProbability: getSurvivalToAge(
                completeLifeTableCalculation.completeLifeTable,
                toAge,
            ),
        };
    }

    private getCompleteLifeTable = memoizeOne((data: Data):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              completeLifeTable: CompleteLifeTable;
          } => {
        return getCompleteLifeTableForDataUsingAlgorithm(
            this.getRefLifeTable(data),
            data,
            this.model.getAlgorithmForData(data),
            this.useExFromAge,
        );
    });

    private getRefLifeTable(data: Data): RefLifeTable {
        const sex = findDatumWithName('sex', data).coefficent as
            | 'male'
            | 'female';

        const refLifeTable = this.genderSpecificRefLifeTable[sex];

        if (refLifeTable) {
            return refLifeTable;
        } else {
            throw new NoLifeTableFoundError(sex as string);
        }
    }
}
