import '../../../styles/survey-progress-bar.scss';

import * as React from 'react';
import { observer } from 'mobx-react';

export interface ISurveyProgressBarProps {
    groups: Array<{
        name: string;
        percentComplete: number;
    }>;
    total: Array<{
        name: string;
        percentComplete: number;
    }>;
}

const SurveyProgressBar = observer(
    ({ groups, total }: ISurveyProgressBarProps) => {
        return (
            <div className="survey-progress-bar">
                <div className="groups">
                    {groups.map(({ name, percentComplete }) => {
                        return (
                            <div
                                key={name}
                                className="survey-progress-bar__group-container"
                            >
                                <div className="survey-progress-bar__group-name">
                                    {name}
                                </div>
                                <div className="survey-progress-bar__group-progress-bar">
                                    <div
                                        style={{
                                            width: `${percentComplete}%`,
                                        }}
                                        className="survey-progress-bar__group-progress-bar-progress"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="total">
                    {total.map(({ name, percentComplete }) => {
                        return (
                            <div
                                key={name}
                                className="survey-progress-bar__group-container"
                            >
                                <div className="survey-progress-bar__group-name">
                                    {name}
                                </div>
                                <div className="survey-progress-bar__group-progress-bar">
                                    <div
                                        style={{
                                            width: `${percentComplete}%`,
                                        }}
                                        className="survey-progress-bar__group-progress-bar-progress"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    },
);

const styledSurveyProgressBar = SurveyProgressBar;
export { styledSurveyProgressBar as SurveyProgressBar };
