import '../../../styles/radio_question.scss';

//react
import * as React from 'react';
import { observer } from 'mobx-react';

import { RadioAnswerOption } from '../RadioAnswerOption/RadioAnswerOption';
import classNames from 'classnames';
import { Question, QuestionProps } from '../Question/Question';
import {
    getKeyMapForChoices,
    getKeyHandlersForChoiceQuestion,
} from '../../utils/choice-question-util';
import { Omit } from 'utility-types';
import { IRadioQuestionConfig } from '../../models/survey-config';
import classnames from 'classnames';
import {
    IRadioQuestion,
    getText,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    Response,
    IAnswerOptionResponse,
    findResponseWithQuestionId,
    hasResponse,
    setResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import { autobind } from 'core-decorators';

export interface ICustomRadioQuestionProps {
    renderAnswerOptions?: (
        answerOptions: React.ReactElement<any>[],
    ) => React.ReactElement<any>[] | React.ReactElement<any>;
    className?: string;
}

export interface IRadioQuestionProps
    extends Omit<
            QuestionProps<false>,
            'rootClassName' | 'showContinueButton' | 'scrollToNextQuestion'
        >,
        ICustomRadioQuestionProps {
    question: IRadioQuestion;
    responses: Response[];
    scrollToNextQuestion: () => void;
    focus: boolean;
    questionConfig?: IRadioQuestionConfig;
}

@observer
@autobind
export class RadioQuestion extends React.Component<IRadioQuestionProps> {
    render() {
        const hasImage = this.props.questionConfig !== undefined;

        return (
            <Question
                {...this.props}
                rootClassName={classnames(
                    'radio-question',
                    this.props.className,
                )}
                showContinueButton={false}
                additionalKeyMaps={getKeyMapForChoices(
                    this.props.question.answerOptions,
                )}
                additionalKeyHandlers={getKeyHandlersForChoiceQuestion(
                    this.props,
                    this.updateResponseOnKeyPress,
                    true,
                )}
            >
                <div
                    className={classNames('radio-question-answer-wrapper', {
                        'radio-questions__image-answer-options-container': hasImage,
                    })}
                >
                    {this.renderAnswerOptions()}
                </div>
            </Question>
        );
    }

    private renderAnswerOptions() {
        const {
            question,
            responses,
            questionConfig,
            renderAnswerOptions,
        } = this.props;

        const responseForCurrentQuestion = findResponseWithQuestionId(
            question.questionId,
            responses,
        ) as IAnswerOptionResponse;

        const radioAnswerOptions = question.answerOptions.map(
            (answerOption, index) => {
                const foundAnswerOptionConfig = questionConfig
                    ? (questionConfig as IRadioQuestionConfig).answerOptions.find(
                          ({ answerOptionId }) => {
                              return (
                                  answerOptionId === answerOption.answerOptionId
                              );
                          },
                      )
                    : undefined;

                return (
                    <RadioAnswerOption
                        key={index}
                        question={question}
                        isChecked={hasResponse(
                            responseForCurrentQuestion,
                            answerOption.answerOptionId,
                        )}
                        onChange={() => {
                            this.setResponse(
                                responseForCurrentQuestion,
                                answerOption.answerOptionId,
                            );

                            this.props.onChange();

                            this.props.scrollToNextQuestion();
                        }}
                        answerOptionText={getText(answerOption, responses)}
                        keypressValue={String.fromCharCode(65 + index)}
                        imageUrl={
                            foundAnswerOptionConfig
                                ? foundAnswerOptionConfig.imageUrl
                                : undefined
                        }
                    />
                );
            },
        );

        return renderAnswerOptions
            ? renderAnswerOptions(radioAnswerOptions)
            : radioAnswerOptions;
    }

    private updateResponseOnKeyPress(
        question: IRadioQuestion,
        response: IAnswerOptionResponse,
        e: KeyboardEvent,
    ) {
        this.setResponse(
            response,
            question.answerOptions[e.key.charCodeAt(0) - 97].answerOptionId,
        );
    }

    private setResponse(
        response: IAnswerOptionResponse,
        answerOptionId: string,
    ) {
        setResponse(response, answerOptionId);

        this.props.onChange();
    }
}
