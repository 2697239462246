import { Data } from '../../data';
import { DataField } from '../data-field';
import { RcsCustomFunction } from './custom-function/rcs-custom-function';
import { Coefficent } from '../../data';
import { DerivedField } from '../derived-field/derived-field';
import { autobind } from 'core-decorators';
import { ICovariateJson } from '../../../parsers/json/json-covariate';
import { IUserFunctions } from '../../algorithm/user-functions/user-functions';
import { ITables } from '../../algorithm/tables/tables';
import { findDatumWithName } from '../../data/data';
import { NoDatumFoundError } from '../../errors';
import { RiskFactor } from '../../../risk-factors';
import { debugRisk } from '../../../debug/debug-risk';
import { IValidationError } from '../../../validation/validate/validation-error';

@autobind
export abstract class Covariate extends DataField {
    beta: number;
    groups: RiskFactor[];
    referencePoint?: number;
    customFunction?: RcsCustomFunction;
    derivedField?: DerivedField;

    constructor(
        covariateJson: ICovariateJson,
        customFunction: RcsCustomFunction | undefined,
        derivedField: DerivedField | undefined,
    ) {
        super(covariateJson);

        this.beta = covariateJson.beta;
        this.groups = covariateJson.groups;
        this.referencePoint = covariateJson.referencePoint;
        this.customFunction = customFunction;
        this.derivedField = derivedField;
    }

    getComponent(
        data: Data,
        userFunctions: IUserFunctions,
        tables: ITables,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              component: number;
          } {
        const coefficientCalculation = this.calculateCoefficient(
            data,
            userFunctions,
            tables,
        );

        if (coefficientCalculation instanceof Array) {
            return coefficientCalculation;
        }

        const component = this.calculateComponent(
            coefficientCalculation.coefficient as number,
        );

        debugRisk.addCovariateDebugInfo(this.name, {
            coefficient: coefficientCalculation.coefficient,
            component,
            beta: this.beta,
        });

        return {
            warnings: coefficientCalculation.warnings,
            component,
        };
    }

    calculateCoefficient(
        data: Data,
        userDefinedFunctions: IUserFunctions,
        tables: ITables,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              coefficient: Coefficent;
          } {
        let coefficent: Coefficent = 0;
        let coefficientWarnings: IValidationError[] = [];
        try {
            coefficent = findDatumWithName(this.name, data).coefficent;
        } catch (err) {
            if (err instanceof NoDatumFoundError) {
                if (this.customFunction) {
                    coefficent = this.customFunction.calculateCoefficient(data);
                } else if (this.derivedField) {
                    const coefficentCalculation = this.derivedField.calculateCoefficient(
                        data,
                        userDefinedFunctions,
                        tables,
                    );
                    if (coefficentCalculation instanceof Array) {
                        return coefficentCalculation;
                    }
                    coefficent = coefficentCalculation.coefficient;
                    coefficientWarnings = coefficentCalculation.warnings;
                }
            } else {
                throw err;
            }
        }

        const { errors, warnings } = this.validate(coefficent);

        if (errors.length > 0) {
            return errors;
        }

        const formattedCoefficient = this.formatCoefficient(
            coefficent,
            warnings,
        );
        if (isNaN(Number(formattedCoefficient))) {
            throw new Error(
                `Coefficient for covariate ${this.name} is not a number. Calculated coefficient is ${formattedCoefficient}`,
            );
        }

        return {
            warnings: warnings.concat(coefficientWarnings),
            coefficient: formattedCoefficient,
        };
    }

    calculateDataToCalculateCoefficent(
        data: Data,
        userDefinedFunctions: IUserFunctions,
        tables: ITables,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              data: Data;
          } {
        // Try to find a datum with the same name field in the data arg
        const datumFound = this.getDatumForField(data);

        /* If we did not find anything then we need to calculate the coefficent
        using either a custom function or the coresponding derived field */
        if (!datumFound) {
            if (this.customFunction) {
                return this.customFunction.calculateDataToCalculateCoefficent(
                    data,
                    userDefinedFunctions,
                    tables,
                );
            } else if (this.derivedField) {
                // Custom function has higher priority
                // Fall back to derived field
                try {
                    return this.derivedField.calculateDataToCalculateCoefficent(
                        data,
                        userDefinedFunctions,
                        tables,
                    );
                } catch (err) {
                    return [];
                }
            } else {
                return [];
            }
        } else {
            const coefficientValidation = this.validate(datumFound.coefficent);
            if (coefficientValidation.errors.length !== 0) {
                return coefficientValidation.errors;
            }
            return {
                warnings: coefficientValidation.warnings,
                data: [datumFound],
            };
        }
    }

    /**
     * Returns all the fields which are part of this Covariate's dependency
     * tree. **Does not return the covariate itself**.
     *
     * @returns {DataField[]}
     * @memberof Covariate
     */
    getDescendantFields(): DataField[] {
        return this.derivedField
            ? this.derivedField.getDescendantFields()
            : this.customFunction
            ? this.customFunction.firstVariableCovariate
                  .getDescendantFields()
                  .concat(this.customFunction.firstVariableCovariate)
            : [];
    }

    isPartOfGroup(group: RiskFactor): boolean {
        return this.groups.indexOf(group) !== -1;
    }

    private calculateComponent(coefficent: number): number {
        const component = coefficent * this.beta;

        return component;
    }
}
