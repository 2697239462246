import { IArrayBooleanResponse } from './array-boolean-response';
import { IArrayNumberResponse } from './array-number-response';
import { ISubQuestionResponse } from './sub-question-response';
import { IArrayAnswerOptionResponse } from './array-answer-option-response';
import { Response } from '../response';
import { ResponseType } from '../response-type';
import { UnionToIntersection } from 'utility-types';

export type ArrayResponse =
    | IArrayBooleanResponse
    | IArrayNumberResponse
    | IArrayAnswerOptionResponse;

type GetArrayResponseType<T extends ArrayResponse> =
    T extends IArrayBooleanResponse
        ? IArrayBooleanResponse['response'][0]
        : T extends IArrayNumberResponse
        ? IArrayNumberResponse['response'][0]
        : T extends IArrayAnswerOptionResponse
        ? IArrayAnswerOptionResponse['response'][0]
        : never;

export function hasResponse<T extends ArrayResponse>(
    response: T,
    responseVal: GetArrayResponseType<T>,
): boolean {
    try {
        const foundSubQuestionResponse = findSubQuestionResponse(
            response,
            responseVal.subQuestionId,
        );

        return foundSubQuestionResponse.response === responseVal.response;
    } catch (err) {
        return false;
    }
}

export function hasAnsweredSubQuestionWithId(
    arrayResponse: ArrayResponse,
    subQuestionId: string,
): boolean {
    if (arrayResponse.response === undefined) {
        return false;
    }

    const subQuestionResponse = findSubQuestionResponse(
        arrayResponse,
        subQuestionId,
    );

    return subQuestionResponse.response !== undefined;
}

export function findSubQuestionResponse<T extends ArrayResponse>(
    response: T,
    subQuestionId: string,
): NonNullable<GetArrayResponseType<T>> {
    const foundSubQuestionResponse = (
        response.response as ISubQuestionResponse<any>[]
    ).find((subQuestionResponse) => {
        return subQuestionResponse.subQuestionId === subQuestionId;
    });
    if (!foundSubQuestionResponse) {
        throw new Error(
            `No sub question response found with for subquestion ID ${subQuestionId}`,
        );
    }

    return foundSubQuestionResponse as NonNullable<GetArrayResponseType<T>>;
}

export function filterArrayResponses(responses: Response[]): ArrayResponse[] {
    return responses.filter((response) => {
        return (
            response.responseType === ResponseType.ArrayBooleanResponse ||
            response.responseType === ResponseType.ArrayAnswerOptionResponse ||
            response.responseType === ResponseType.ArrayNumberResponse
        );
    }) as ArrayResponse[];
}

type GenericSetResponse<T> = T extends ArrayResponse
    ? (response: T, responseVal: T['response'][0]) => void
    : never;
export const setResponse: UnionToIntersection<
    GenericSetResponse<ArrayResponse>
> = function (
    response: ArrayResponse,
    responseVal: ISubQuestionResponse<any>,
): void {
    try {
        const foundSubQuestionResponse = findSubQuestionResponse(
            response,
            responseVal.subQuestionId,
        );
        foundSubQuestionResponse.response = responseVal.response;
    } catch (err) {
        response.response.push(responseVal);
    }
};

export function isArrayResponse(response: Response): response is ArrayResponse {
    switch (response.responseType) {
        case ResponseType.ArrayAnswerOptionResponse:
        case ResponseType.ArrayBooleanResponse:
        case ResponseType.ArrayNumberResponse: {
            return true;
        }
        default: {
            return false;
        }
    }
}
