import '../../../styles/short-text-question.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { SurveyInput } from '../Inputs/SurveyInput';
import { Question, QuestionProps } from '../Question/Question';
import { Omit } from 'utility-types';
import {
    IShortTextQuestion,
    ITextQuestion,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    findResponseWithQuestionId,
    Response,
    ITextResponse,
    setResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';

export interface ITextQuestionProps
    extends Omit<QuestionProps<true>, 'rootClassName' | 'showContinueButton'> {
    question: IShortTextQuestion | ITextQuestion;
    responses: Response[];
    focus: boolean;
    nextQuestion: () => void;
}

@observer
export class TextQuestion extends React.Component<ITextQuestionProps> {
    constructor(props: ITextQuestionProps) {
        super(props);

        this.state = {
            isDirty: false,
        };
    }

    onBecomeDirty = () => {
        this.setState({ isDirty: true });
    };

    render() {
        let { question, responses } = this.props;

        const response = findResponseWithQuestionId(
            question.questionId,
            responses,
        ) as ITextResponse;

        return (
            <Question
                {...this.props}
                rootClassName="short-text-question"
                showContinueButton={true}
            >
                <SurveyInput
                    inputType="text"
                    question={question}
                    response={response.response}
                    onBecomeDirty={this.onBecomeDirty}
                    focus={this.props.focus}
                    onChange={(e) => {
                        setResponse(response, e.target.value);
                        this.props.onChange();
                    }}
                />
            </Question>
        );
    }
}
