import * as React from 'react';
import {
    Question,
    QuestionType,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';

export interface IGaQuestionAnsweredProps {
    question: Question;
    // TODO: This was yelling at me
    children: any; //React.ReactElement<{ onChange: () => void }>;
}

export const GaInputChange: React.SFC<IGaQuestionAnsweredProps> = ({
    question,
    children,
}) => {
    return React.cloneElement(children, {
        onChange: function () {
            // @ts-ignore
            gtag('event', getGaActionForQuestion(question), {
                event_category: 'survey',
                event_label: question.questionId,
            });
            children.props.onChange.apply(this, Array.from(arguments));
        },
    });
};

function getGaActionForQuestion(question: Question): string {
    switch (question.questionType) {
        case QuestionType.RadioQuestion: {
            return 'radio_question_answer';
        }
        case QuestionType.NumberQuestion: {
            return 'number_question_answer';
        }
        case QuestionType.MultipleNumberQuestion: {
            return 'multiple_number_question_answer';
        }
        case QuestionType.MultipleChoiceQuestion: {
            return 'multiple_choice_question_answer';
        }
        case QuestionType.FlexibleRowArrayQuestion: {
            return 'flexible_row_array_question_answer';
        }
        default: {
            return 'unknown_answer';
        }
    }
}
