import { observable } from 'mobx';
import { autobind } from 'core-decorators';

@autobind
export class HealthAge {
    @observable referenceSelected: ReferenceSelected;

    constructor() {
        this.referenceSelected = ReferenceSelected.Mean;
    }

    toggleReference() {
        if (this.referenceSelected === ReferenceSelected.Mean) {
            this.referenceSelected = ReferenceSelected.Healthy;
        } else {
            this.referenceSelected = ReferenceSelected.Mean;
        }
    }
}

export enum ReferenceSelected {
    Mean,
    Healthy,
}
