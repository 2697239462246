import { observable } from 'mobx';

export abstract class Store {
    @observable
    isConstructed: boolean;

    protected constructor() {
        this.isConstructed = false;
    }

    protected abstract constructStoreSingleton(): Promise<void>;
}
