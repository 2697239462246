import './life-years-lost-result.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import AppearingNextButton from '@big-life-lab/pbl-survey-components/src/components/AppearingNextButton/AppearingNextButton';
import { HotKeys } from 'react-hotkeys';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Icon from '@material-ui/core/Icon/Icon';
import classnames from 'classnames';
import CalculateButton from '../AppearingNextButton/AppearingNextButton';
import { computed } from 'mobx';
import { isEmbedded } from '@ottawamhealth/pbl-utils/lib/browser';

export interface ILifeYearsLostResultProps {
    nextQuestion: Function;
    focus: boolean;
    smokingLyl: number;
    alcoholLyl: number;
    dietLyl: number;
    activityLyl: number;
    calculateLifeYearsLost: () => void;
    showLifeYearsLost: boolean;
}

@observer
export class LifeYearsLostResult extends React.Component<
    ILifeYearsLostResultProps
> {
    divRef!: HTMLDivElement;

    handlers = {
        nextQuestion: () => this.props.nextQuestion(),
    };

    componentDidUpdate(prevProps: ILifeYearsLostResultProps, _prevState: {}) {
        //console.log('CDU');
        // console.log(`CDU ${prevProps.focus} - ${this.props.focus}`);
        if (!prevProps.focus && this.props.focus) {
            // Timer allows the question-wrapper's scrollIntoView call time to happen
            // before calling focus on this field intercepts the scroll
            // preventScroll option has limited browser compatibility
            setTimeout(() => {
                //@ts-ignore
                this.divRef.focus({ preventScroll: true });
            }, 100);
        }
    }
    render() {
        const keyMap = {
            nextQuestion: 'return',
        };

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={this.handlers}
                focused={this.props.focus}
            >
                <div
                    ref={c => (this.divRef = c as HTMLDivElement)}
                    tabIndex={5}
                    id="mport-life-years-lost"
                    className={classnames('life-years-lost-result', {
                        'result--embedded': isEmbedded(),
                    })}
                >
                    <h3>What affects your life expectancy?</h3>
                    {this.props.showLifeYearsLost ? (
                        <>
                            <div className="risk-factors-container">
                                {this.renderRiskFactorContainer(
                                    'Smoking',
                                    'smoking',
                                    this.props.smokingLyl,
                                )}
                                {this.renderRiskFactorContainer(
                                    'Alcohol',
                                    'alcohol',
                                    this.props.alcoholLyl,
                                )}
                                {this.renderRiskFactorContainer(
                                    'Diet',
                                    'diet',
                                    this.props.dietLyl,
                                )}
                                {this.renderRiskFactorContainer(
                                    'Physical Activity',
                                    'activity',
                                    this.props.activityLyl,
                                )}
                            </div>
                            <p>
                                Already in the healthiest group?{' '}
                                <Tooltip
                                    placement="top"
                                    id="what-is-health-canadian"
                                    title={`You'll see 0 years if you are in the healthiest group. Have a look at the FAQ section to see where these recommendations come from`}
                                >
                                    <Icon className="fas fa-info">info</Icon>
                                </Tooltip>
                            </p>
                        </>
                    ) : (
                        <>
                            <p>
                                Calculate how life expectancy is affected by
                                smoking, alcohol, diet, physical activity
                            </p>
                            <div className="calculate-life-years-lost">
                                <CalculateButton
                                    onClick={this.props.calculateLifeYearsLost}
                                />
                            </div>
                        </>
                    )}

                    {isEmbedded() === false ? (
                        <AppearingNextButton
                            onClick={this.props.nextQuestion}
                        />
                    ) : (
                        <div className="buttom-prompt button-prompt--embedded">
                            Scroll for the next report
                        </div>
                    )}
                </div>
            </HotKeys>
        );
    }
    private renderRiskFactorContainer(
        riskFactorName: string,
        riskFactorImg: string,
        lifeYearsLost: number,
    ) {
        const isLargestRiskFactor = this.isLargestRiskFactor(lifeYearsLost);

        return (
            <div
                className={classnames('risk-factor-container', {
                    'largest-risk': isLargestRiskFactor,
                })}
            >
                {isLargestRiskFactor ? (
                    <div className="largest-risk-header">Largest Risk</div>
                ) : null}
                <div className="risk-factor-image-container">
                    <img
                        className="risk-factor-image"
                        src={`https://s3.ca-central-1.amazonaws.com/project-big-life/img/${riskFactorImg}.png`}
                    />
                </div>
                <div className="risk-factor-title">{riskFactorName}</div>
                <div className="risk-factor-value">{`+${lifeYearsLost}`}</div>
                <div className="risk-factor-years-label">Years</div>
            </div>
        );
    }
    @computed
    private get largestRiskFactorValue() {
        const { smokingLyl, alcoholLyl, dietLyl, activityLyl } = this.props;

        return Math.max(smokingLyl, alcoholLyl, dietLyl, activityLyl);
    }
    private isLargestRiskFactor(lifeYearsLostDueToRiskFactor: number): boolean {
        /* If the largest amount of life years lost due to any risk factor
        is 0 then the life years lost due to all risk factors is zero meaning
        no one of them is the largest, thus return false */
        return this.largestRiskFactorValue === 0
            ? false
            : lifeYearsLostDueToRiskFactor === this.largestRiskFactorValue;
    }
}
