import { Range } from '../../../range/range';
import { JsonInterval } from '../../../parsers/json/json-interval';
import { ErrorCode } from './error-code';

export class Interval extends Range {
    description: string;

    constructor(intervalJson: JsonInterval) {
        super(intervalJson);

        this.description = intervalJson.description;
    }

    limitNumber(num: number): number {
        if (this.lowerMargin && this.validateLowerMargin(num) !== true) {
            return this.lowerMargin.margin;
        } else if (
            this.higherMargin &&
            this.validateHigherMargin(num) !== true
        ) {
            return this.higherMargin.margin;
        } else {
            return num;
        }
    }

    /**
     * Validates whether the num arg is greater than for an open margin or
     * greater than or equal to for a closed margin
     *
     * @param {number} num value to validate
     * @returns {(ErrorCode | true)} Returns an ErrorCode if validation
     * fails. True if validation passes.
     * @memberof Interval
     */
    validateLowerMargin(num: number): ErrorCode | true {
        if (this.isWithinLowerMargin(num) === true) {
            return true;
        }

        if (this.lowerMargin!.isOpen === false) {
            return ErrorCode.LessThanOrEqualTo;
        }

        return ErrorCode.LessThan;
    }

    /**
     * Validates whether the num arg is less than for an open margin or
     * less than or equal to for a closed margin
     * @param {number} num value to validate
     * @returns {(ErrorCode | true)} Returns an ErrorCode if validation fails.
     * True if validation passes.
     * @memberof Interval
     */
    validateHigherMargin(num: number): ErrorCode | true {
        if (this.isWithinHigherMargin(num) === true) {
            return true;
        }

        if (this.higherMargin!.isOpen === false) {
            return ErrorCode.GreaterThanOrEqualTo;
        }

        return ErrorCode.GreaterThan;
    }

    validate(num: number): boolean {
        return (
            this.validateHigherMargin(num) === true &&
            this.validateLowerMargin(num) === true
        );
    }
}
