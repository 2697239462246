import { Algorithm } from '../algorithm';
import { DerivedField } from '../../data-field/derived-field/derived-field';
import { Data } from '../../data/data';
import { IJsonSimpleAlgorithm } from '../../../parsers/json/json-simple-algorithm';
import { parseDerivedFieldJsonToDerivedField } from '../../../parsers/json/json-derived-field';
import { DataField } from '../../data-field/data-field';

export class SimpleAlgorithm extends Algorithm {
    output: DerivedField;

    constructor(simpleAlgorithmJson: IJsonSimpleAlgorithm) {
        super(simpleAlgorithmJson);

        const derivedField = simpleAlgorithmJson.derivedFields.find(
            ({ name }) => {
                return name === simpleAlgorithmJson.output;
            },
        );
        if (!derivedField) {
            throw new Error(
                // tslint:disable-next-line:max-line-length
                `Constructing SimpleAlgorithm but no derived field with name ${simpleAlgorithmJson.name} found in JSON's derivedFields array`,
            );
        }
        this.output = parseDerivedFieldJsonToDerivedField(
            derivedField,
            simpleAlgorithmJson.derivedFields,
            [],
            simpleAlgorithmJson.dataDictionary,
        );
    }

    evaluate(data: Data) {
        const coefficientCalculation = this.output.calculateCoefficient(
            data,
            this.userFunctions,
            this.tables,
        );
        if (coefficientCalculation instanceof Array) {
            return coefficientCalculation;
        }
        return {
            warnings: coefficientCalculation.warnings,
            value: coefficientCalculation.coefficient as number,
        };
    }

    findDataField(fieldName: string): DataField {
        const foundDataField = this.output
            .getDescendantFields()
            .find(descendantField => {
                return descendantField.name === fieldName;
            });

        if (!foundDataField) {
            throw new Error(`No data field found with name ${fieldName}`);
        }

        return foundDataField;
    }
}
