import '../../../styles/image-sub-number-question-input.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import {
    ISubQuestionNumberInputProps,
    SubQuestionNumberInput,
} from '../SubQuestionNumberInput/SubQuestionNumberInput';

export interface IImageSubNumberQuestionInputProps {
    subNumberQuestionInputProps: ISubQuestionNumberInputProps;
    imageUrl: string;
    subQuestionText: string;
    onBecomeDirty: Function;
}

//@ts-ignore
export const ImageSubNumberQuestionInput: React.SFC<IImageSubNumberQuestionInputProps> = observer(
    ({
        imageUrl,
        subNumberQuestionInputProps,
        subQuestionText,
    }: IImageSubNumberQuestionInputProps) => {
        return (
            <div className="image-sub-number-question-input">
                <div className="image-sub-number-question-input__text">
                    {subQuestionText}
                </div>
                <img
                    src={imageUrl}
                    className="image-sub-number-question-input__image"
                />
                <SubQuestionNumberInput
                    {...subNumberQuestionInputProps}
                    inputLabel=""
                />
            </div>
        );
    },
);
