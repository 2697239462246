import * as React from 'react';
import classnames from 'classnames';
import '../../../styles/appearing-next-button.scss';

interface AppearingNextButtonProps {
    title?: string;
    visible?: boolean;
    disabled?: boolean;
    //idk ts
    onClick: any; //((event: MouseEvent<HTMLButtonElement>) => void) | undefined;
}

const AppearingNextButton = (props: AppearingNextButtonProps) => {
    return (
        <div
            className={classnames(
                'appearing-button-wrapper',
                { disabled: props.disabled },
                { visible: props.visible === undefined ? true : props.visible },
                {
                    hidden:
                        props.visible === undefined ? false : !props.visible,
                },
            )}
        >
            <button
                className={classnames('appearing-button')}
                onClick={props.onClick}
            >
                {props.title || 'Continue'}
            </button>
            <div className="buttom-prompt">Press Enter</div>
        </div>
    );
};

export default AppearingNextButton;
