import { SurveyStore } from '../../../../models/survey-store';
import { CVDPoRTAlgorithm } from '../domain/cvdport-algorithm-singleton';
import { computed, observable } from 'mobx';
import { HeartAge } from './heart-age';
import { Store } from '../../../../../src/models/store';

// tslint:disable-next-line: no-var-requires
const heartSurveyJsonUrl = require('../../../../../assets/calculators/heart/survey/heart-survey.json')
    .default;
// tslint:disable-next-line: no-var-requires
const heartSurveyConfigJsonUrl = require('../../../../../assets/calculators/heart/survey/heart-survey-config.json')
    .default;
// tslint:disable-next-line: no-var-requires
const heartMaleModelJsonUrl = require('../../../../../assets/calculators/heart/algorithm/heart-male-model.json')
    .default;
// tslint:disable-next-line: no-var-requires
const heartFemaleModelJsonUrl = require('../../../../../assets/calculators/heart/algorithm/heart-female-model.json')
    .default;
// tslint:disable-next-line: no-var-requires
const heartMeanRefPopJsonUrl = require('../../../../../assets/calculators/heart/algorithm/ref-pops/heart-mean-ref-pop.json')
    .default;
// tslint:disable-next-line: no-var-requires
const heartHealthRefPopJsonUrl = require('../../../../../assets/calculators/heart/algorithm/ref-pops/heart-healthy-ref-pop.json')
    .default;
// tslint:disable-next-line: no-var-requires
const heartTruncateConfigJsonUrl = require('../../../../../assets/calculators/heart/algorithm/heart-truncate-config.json')
    .default;

export class CVDPoRT extends Store {
    surveySingleton!: SurveyStore;
    algorithm!: CVDPoRTAlgorithm;
    @observable heartAge!: HeartAge;

    private static singletonInstance?: CVDPoRT;

    @computed
    get riskToTime(): number {
        const responsesData = this.surveySingleton.dataFromResponses;

        return this.algorithm.getRiskToTime(responsesData);
    }

    @computed
    get meanHeartAge(): number {
        const responsesData = this.surveySingleton.dataFromResponses;
        return this.algorithm.getMeanHealthAge(responsesData);
    }

    @computed
    get healthyHeartAge(): number {
        const responsesData = this.surveySingleton.dataFromResponses;

        return this.algorithm.getHealthyHealthAge(responsesData);
    }

    get showShortReport(): boolean {
        return false;
    }

    protected async constructStoreSingleton(): Promise<void> {
        const assetsUrls: string[] = [
            heartSurveyJsonUrl,
            heartSurveyConfigJsonUrl,
            heartMaleModelJsonUrl,
            heartFemaleModelJsonUrl,
            heartMeanRefPopJsonUrl,
            heartHealthRefPopJsonUrl,
            heartTruncateConfigJsonUrl,
        ];

        const assets = await Promise.all(
            assetsUrls.map(assetUrl => {
                return fetch(assetUrl).then(response => {
                    return response.json();
                });
            }),
        );

        this.surveySingleton = new SurveyStore(assets[0], assets[1]);

        this.algorithm = new CVDPoRTAlgorithm(
            assets[2],
            assets[3],
            assets[4],
            assets[5],
        );

        this.heartAge = new HeartAge();

        this.isConstructed = true;
    }

    static getSingleton(): CVDPoRT {
        if (CVDPoRT.singletonInstance === undefined) {
            CVDPoRT.singletonInstance = new CVDPoRT();
            CVDPoRT.singletonInstance.constructStoreSingleton();
        }

        return CVDPoRT.singletonInstance;
    }
}
