import '../../../styles/radio_question.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { Question, QuestionProps } from '../Question/Question';
import { Omit } from 'utility-types';

export interface TextDisplayQuestionProps
    extends Omit<QuestionProps<true>, 'rootClassName' | 'showContinueButton'> {}

export const TextDisplayQuestion = observer(
    (props: TextDisplayQuestionProps) => {
        return (
            <Question
                {...props}
                rootClassName="text-display-question"
                showContinueButton={true}
            />
        );
    },
);
