import { observable } from 'mobx';
import { autobind } from 'core-decorators';
import { isEmbedded } from '@ottawamhealth/pbl-utils/lib/browser';

@autobind
export class WillYouLiveToSeeIt {
    @observable
    sliderValue: number;

    bigLifeText: string;
    bigLifeYear: string;
    bigLifeYearNumber: number;

    constructor() {
        this.sliderValue = 75;

        this.bigLifeYear = isEmbedded()
            ? this.defaultBigLifeYear
            : window.localStorage.getItem('bigLifeYear') ||
              this.defaultBigLifeYear;
        var bigLifeYearInt = parseInt(this.bigLifeYear);
        this.bigLifeYearNumber = isNaN(bigLifeYearInt)
            ? 2068
            : (bigLifeYearInt as number);

        this.bigLifeText = isEmbedded()
            ? this.defaultBigLifeText
            : window.localStorage.getItem('bigLife') || this.defaultBigLifeText;
    }

    setSliderValue(sliderValue: number) {
        this.sliderValue = sliderValue;
    }

    private get defaultBigLifeYear(): string {
        return '2061';
    }

    private get defaultBigLifeText(): string {
        return `Next return of Halley's Comet`;
    }
}
