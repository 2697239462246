import { observable, computed, autorun } from 'mobx';
import { WillYouLiveToSeeIt } from './will-you-live-to-see-it';
import { MPoRTSurveyStore } from '../domain/mport-survey-singleton';
import { MPoRTAlgorithmSingleton } from '../domain/mport-algorithm-singleton';
import { findDatumWithName } from '@big-life-lab/pbl-calculator-engine/src/engine/data';
import { env } from '@big-life-lab/pbl-calculator-engine/src/index';
import { HealthAge } from './health-age';
import { autobind } from 'core-decorators';
import { LifeYearsLost } from '@big-life-lab/pbl-calculator-engine/src/engine/life-years-lost/life-years-lost';
import { Store } from '../../../../../src/models/store';
import { getHealthAge } from '@big-life-lab/pbl-calculator-engine/src/engine/ref-pop/health-age';
import { UnAbridgedLifeExpectancy } from '@big-life-lab/pbl-calculator-engine/src/engine/unabridged-life-expectancy/unabridged-life-expectancy';
import { RiskFactor } from '@big-life-lab/pbl-calculator-engine/src/risk-factors';

// tslint:disable-next-line: no-var-requires
const leLifeTableJsonUrl = require('../../../../../assets/calculators/le/algorithm/life-table.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leCauseEffectRefJsonUrl = require('../../../../../assets/calculators/le/algorithm/le-cause-effect-ref.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leMaleModelJsonUrl = require('../../../../../assets/calculators/le/algorithm/models/le-male-model.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leFemaleModelJsonUrl = require('../../../../../assets/calculators/le/algorithm/models/le-female-model.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leCalibrationJsonUrl = require('../../../../../assets/calculators/le/algorithm/le-calibration.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leMeanRefPopJsonUrl = require('../../../../../assets/calculators/le/algorithm/ref-pops/le-mean-ref-pop.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leHealthyRefPopJsonUrl = require('../../../../../assets/calculators/le/algorithm/ref-pops/le-healthy-ref-pop.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leSurveyJsonUrl = require('../../../../../assets/calculators/le/survey/le-survey.json')
    .default;
// tslint:disable-next-line: no-var-requires
const leSurveyConfigJsonUrl = require('../../../../../assets/calculators/le/survey/le-survey-config.json')
    .default;

@autobind
export class MPoRT extends Store {
    private static singletonInstance?: MPoRT;

    @observable
    survey!: MPoRTSurveyStore;
    @observable
    willYouLiveToSeeIt!: WillYouLiveToSeeIt;
    @observable
    algorithm!: MPoRTAlgorithmSingleton;
    @observable
    healthAge!: HealthAge;
    @observable
    lifeYearsLost!: {
        smoking: number | undefined;
        alcohol: number | undefined;
        diet: number | undefined;
        physicalActivity: number | undefined;
    };
    lifeYearsLostFunction!: {
        male: LifeYearsLost;
        female: LifeYearsLost;
    };
    @observable
    lifeExpectancy!: {
        male: UnAbridgedLifeExpectancy;
        female: UnAbridgedLifeExpectancy;
    };

    async constructStoreSingleton(): Promise<void> {
        const assets = await Promise.all(
            [
                leLifeTableJsonUrl,
                leCauseEffectRefJsonUrl,
                leMaleModelJsonUrl,
                leFemaleModelJsonUrl,
                leCalibrationJsonUrl,
                leMeanRefPopJsonUrl,
                leHealthyRefPopJsonUrl,
                leSurveyJsonUrl,
                leSurveyConfigJsonUrl,
            ].map(assetUrl => {
                return fetch(assetUrl).then(response => {
                    return response.json();
                });
            }),
        );

        this.survey = new MPoRTSurveyStore(assets[7], assets[8]);
        this.willYouLiveToSeeIt = new WillYouLiveToSeeIt();
        this.algorithm = new MPoRTAlgorithmSingleton(
            {
                male: assets[2],
                female: assets[3],
            },
            assets[4],
            assets[5],
            assets[6],
        );
        this.healthAge = new HealthAge();
        this.lifeYearsLost = {
            smoking: undefined,
            alcohol: undefined,
            diet: undefined,
            physicalActivity: undefined,
        };
        this.lifeExpectancy = {
            male: new UnAbridgedLifeExpectancy(
                this.algorithm.models.male,
                assets[0].male,
            ),
            female: new UnAbridgedLifeExpectancy(
                this.algorithm.models.female,
                assets[0].female,
            ),
        };
        this.lifeYearsLostFunction = {
            male: new LifeYearsLost(assets[1].male, this.lifeExpectancy.male),
            female: new LifeYearsLost(assets[1].male, this.lifeExpectancy.male),
        };

        autorun(() => {
            if (this.currentAge > this.willYouLiveToSeeIt.sliderValue) {
                this.willYouLiveToSeeIt.sliderValue = this.currentAge;
            }
        });

        autorun(() => {
            this.lifeYearsLost = {
                smoking: undefined,
                alcohol: undefined,
                diet: undefined,
                physicalActivity: undefined,
            };

            if (env.isEnvironmentDebugging()) {
                this.calculateLifeYearsLost();
            }
        });

        this.isConstructed = true;
    }

    static getSingleton(): MPoRT {
        if (MPoRT.singletonInstance === undefined) {
            MPoRT.singletonInstance = new MPoRT();
            MPoRT.singletonInstance.constructStoreSingleton();
        }

        return MPoRT.singletonInstance;
    }

    @computed
    get currentAlgorithm() {
        return this.algorithm.getModel(this.survey.dataFromResponses);
    }

    @computed
    get riskToTime(): number {
        console.log('5 Year Risk Debug');
        const risk = this.currentAlgorithm.getRiskToTime(
            this.survey.dataFromResponses,
        );
        if ('risk' in risk) {
            return risk.risk;
        }
        console.log(JSON.stringify(risk, null, 4));
        throw new Error('Errors when calculating risk');
    }

    @computed
    get meanHealthAge(): number {
        const meanHealthAge = getHealthAge(
            this.algorithm.getMeanRefPop(this.survey.dataFromResponses),
            this.survey.dataFromResponses,
            this.algorithm.getModel(this.survey.dataFromResponses),
        );
        if ('healthAge' in meanHealthAge) {
            return meanHealthAge.healthAge;
        }
        console.log(JSON.stringify(meanHealthAge, null, 4));
        throw new Error('Errors when calculating mean health age');
    }

    @computed
    get healthyHealthAge(): number {
        const healthyHealthAge = getHealthAge(
            this.algorithm.getHealthyRefPop(this.survey.dataFromResponses),
            this.survey.dataFromResponses,
            this.algorithm.getModel(this.survey.dataFromResponses),
        );
        if ('healthAge' in healthyHealthAge) {
            return healthyHealthAge.healthAge;
        }
        console.log(JSON.stringify(healthyHealthAge, null, 4));
        throw new Error('Errors when calculating healthy health age');
    }

    @computed
    get currentAge(): number {
        const age = findDatumWithName('age', this.survey.dataFromResponses)
            .coefficent as number;
        return age !== undefined ? age : 21;
    }

    @computed
    get currentSex(): 'male' | 'female' {
        return findDatumWithName('sex', this.survey.dataFromResponses)
            .coefficent as 'male' | 'female';
    }

    @computed
    get survivalToBigLife(): number {
        if (!this.willYouLiveToSeeItAge) {
            return 0;
        }

        const toAge =
            this.willYouLiveToSeeItAge > 110 ? 110 : this.willYouLiveToSeeItAge;
        let survivalToAge: ReturnType<MPoRT['lifeExpectancy']['male']['getSurvivalToAge']>;
        if (
            findDatumWithName('sex', this.survey.dataFromResponses)
                .coefficent === 'male'
        ) {
            survivalToAge = this.lifeExpectancy.male.getSurvivalToAge(
                this.survey.dataFromResponses,
                toAge,
            );
        } else {
            survivalToAge = this.lifeExpectancy.female.getSurvivalToAge(
                this.survey.dataFromResponses,
                toAge,
            );
        }
        if ('survivalProbability' in survivalToAge) {
            return survivalToAge.survivalProbability;
        }
        console.log(JSON.stringify(survivalToAge, null, 4));
        throw new Error('Errors when calculating will you live to see it');
    }

    @computed
    get currentLifeExpectancy(): number {
        let lifeExpectancy: ReturnType<MPoRT['lifeExpectancy']['male']['calculateForIndividual']>;
        if (
            findDatumWithName('sex', this.survey.dataFromResponses)
                .coefficent === 'male'
        ) {
            lifeExpectancy = this.lifeExpectancy.male.calculateForIndividual(
                this.survey.dataFromResponses,
            );
        } else {
            lifeExpectancy = this.lifeExpectancy.female.calculateForIndividual(
                this.survey.dataFromResponses,
            );
        }
        if ('lifeExpectancy' in lifeExpectancy) {
            return lifeExpectancy.lifeExpectancy;
        }
        console.log(JSON.stringify(lifeExpectancy, null, 4));
        throw new Error('Errors when calculating life expectancy');
    }

    @computed
    get willYouLiveToSeeItSliderAgeSurvivalPercent(): number {
        let survivalToAge: ReturnType<MPoRT['lifeExpectancy']['male']['getSurvivalToAge']>;
        if (
            findDatumWithName('sex', this.survey.dataFromResponses)
                .coefficent === 'male'
        ) {
            survivalToAge = this.lifeExpectancy.male.getSurvivalToAge(
                this.survey.dataFromResponses,
                this.willYouLiveToSeeIt.sliderValue,
            );
        } else {
            survivalToAge = this.lifeExpectancy.female.getSurvivalToAge(
                this.survey.dataFromResponses,
                this.willYouLiveToSeeIt.sliderValue,
            );
        }
        if ('survivalProbability' in survivalToAge) {
            return survivalToAge.survivalProbability;
        }
        console.log(JSON.stringify(survivalToAge, null, 4));
        throw new Error('Errors when calculating survival to age');
    }

    @computed
    get willYouLiveToSeeItAge(): number {
        const age: number =
            this.willYouLiveToSeeIt.bigLifeYearNumber -
            new Date().getFullYear() +
            this.currentAge;

        console.log(`
        biglifeyear: ${this.willYouLiveToSeeIt.bigLifeYear}
        currentAge: ${this.currentAge}`);

        return age;
    }

    get showShortReport(): boolean {
        return this.survey.hasAnsweredAgeAndSexQuestions ? true : false;
    }

    @computed
    get formattedLifeExpectancy(): number {
        return Math.round(this.currentLifeExpectancy);
    }

    getLifeYearsLostDueToRiskFactor(riskFactor: RiskFactor) {
        if (
            findDatumWithName('sex', this.survey.dataFromResponses)
                .coefficent === 'male'
        ) {
            return this.lifeYearsLostFunction.male.getLifeYearsLostDueToRiskFactor(
                riskFactor,
                this.survey.dataFromResponses,
            );
        }

        return this.lifeYearsLostFunction.female.getLifeYearsLostDueToRiskFactor(
            riskFactor,
            this.survey.dataFromResponses,
        );
    }

    calculateLifeYearsLost() {
        const wasEnvironmentDebugging = env.isEnvironmentDebugging();

        env.setEnvironmentToProduction();

        if (wasEnvironmentDebugging) {
            env.setEnvironmentToDebugging();
        } else {
            env.setEnvironmentToProduction();
        }

        const data = this.survey.dataFromResponses;

        const smokingLifeYearsLost = this.getLifeYearsLostDueToRiskFactor(
            'SMOKING',
        );
        if (smokingLifeYearsLost instanceof Array) {
            console.log(JSON.stringify(smokingLifeYearsLost, null, 4));
            throw new Error('Error when calculating smoking life years lost');
        }

        const alcoholLifeYearsLost = this.getLifeYearsLostDueToRiskFactor(
            'ALCOHOL',
        );
        if (alcoholLifeYearsLost instanceof Array) {
            console.log(JSON.stringify(alcoholLifeYearsLost, null, 4));
            throw new Error('Error when calculating alcohol life years lost');
        }

        const dietLifeYearsLost = this.getLifeYearsLostDueToRiskFactor('DIET');
        if (dietLifeYearsLost instanceof Array) {
            console.log(JSON.stringify(dietLifeYearsLost, null, 4));
            throw new Error('Error when calculating diet life years lost');
        }

        const activityLifeYearsLost = this.getLifeYearsLostDueToRiskFactor(
            'ACTIVITY',
        );
        if (activityLifeYearsLost instanceof Array) {
            console.log(JSON.stringify(activityLifeYearsLost, null, 4));
            throw new Error('Error when calculating activity life years lost');
        }

        this.lifeYearsLost = {
            smoking: smokingLifeYearsLost.lifeYearsLost,
            alcohol: alcoholLifeYearsLost.lifeYearsLost,
            diet: dietLifeYearsLost.lifeYearsLost,
            physicalActivity: activityLifeYearsLost.lifeYearsLost,
        };
    }
}
