import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { LifeExpectancyResult as DumbLifeExpectancyResult } from '../dumb/LifeExpectancyResult/LifeExpectancyResult';
import { MPoRT } from '../../store/ui/mport';
import { findDatumWithName } from '@big-life-lab/pbl-calculator-engine/src/engine/data';

export interface ILifeExpectancyResultProps {
    nextQuestion: Function;
    focus: boolean;
    mport?: MPoRT;
}

@inject('mport')
@observer
export class LifeExpectancyResult extends React.Component<
    ILifeExpectancyResultProps
> {
    render() {
        const {
            survey,
            meanHealthAge,
            formattedLifeExpectancy,
        } = this.props.mport!;

        return (
            <DumbLifeExpectancyResult
                nextQuestion={this.props.nextQuestion}
                focus={this.props.focus}
                lifeExpectancy={formattedLifeExpectancy}
                currentAge={
                    findDatumWithName('age', survey.dataFromResponses)
                        .coefficent as number
                }
                meanHealthAge={meanHealthAge}
            />
        );
    }
}
