import { JsonRange } from '../parsers/json/json-range';

export enum RuleType {
    Nullable = 'nullable',
    Type = 'type',
    Allowed = 'allowed',
    Range = 'range',
}

export enum ErrorHandle {
    Error = 'error',
    Warning = 'warning',
    Truncate = 'truncate',
}

export interface IBaseValidationObject {
    variable: string;
    location: string;
    errorReplace: any;
}

export interface IBaseNonRangeValidation extends IBaseValidationObject {
    errorHandle: ErrorHandle.Error | ErrorHandle.Warning;
}

export interface INullableValidation extends IBaseNonRangeValidation {
    ruleType: RuleType.Nullable;
    isNullable: boolean;
}

export enum TypeValidationType {
    Number = 'number',
    String = 'string',
    Date = 'date',
}

export interface INonDateTypeValidation extends IBaseNonRangeValidation {
    ruleType: RuleType.Type;
    type: TypeValidationType.Number | TypeValidationType.String;
}

export interface IDateTypeValidation extends IBaseNonRangeValidation {
    ruleType: RuleType.Type;
    type: TypeValidationType.Date;
    dateFormat: string;
}

export type TypeValidationTypes = INonDateTypeValidation | IDateTypeValidation;

export interface IAllowedValidation extends IBaseNonRangeValidation {
    ruleType: RuleType.Allowed;
    allowed: string[];
}

export interface IRangeValidation extends IBaseValidationObject {
    ruleType: RuleType.Range;
    range: JsonRange;
    errorHandle: ErrorHandle;
}

export type ValidationTypes =
    | INullableValidation
    | TypeValidationTypes
    | IRangeValidation
    | IAllowedValidation;

export type Validations = ValidationTypes[];
