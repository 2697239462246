import '../../../styles/multiple_choice_question.scss';

import * as React from 'react';
import { GaInputChange } from '../GaInputChange/GaInputChange';
import { observer } from 'mobx-react';
import ChoiceInputContainer from '../ChoiceQuestionInputContainer/ChoiceQuestionInputContainer';
import { Question, QuestionProps } from '../Question/Question';
import {
    getKeyMapForChoices,
    getKeyHandlersForChoiceQuestion,
} from '../../utils/choice-question-util';
import { Omit } from 'utility-types';
import {
    IMultipleChoiceQuestion,
    getText,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    IArrayBooleanResponse,
    findSubQuestionResponse,
    findResponseWithQuestionId,
    setResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import { autobind } from 'core-decorators';

export interface MultipleChoiceQuestionProps
    extends Omit<QuestionProps<true>, 'rootClassName' | 'showContinueButton'> {
    question: IMultipleChoiceQuestion;
}

@observer
@autobind
export class MultipleChoiceQuestion extends React.Component<
    MultipleChoiceQuestionProps
> {
    render() {
        return (
            <Question
                {...this.props}
                rootClassName="multiple-choice-question"
                additionalKeyHandlers={getKeyHandlersForChoiceQuestion(
                    this.props,
                    this.updateResponseOnKeyPress,
                    false,
                )}
                additionalKeyMaps={getKeyMapForChoices(
                    this.props.question.subQuestions,
                )}
                showContinueButton
            >
                <div className="subquestion-wrapper">
                    {this.renderSubQuestions()}
                </div>
            </Question>
        );
    }

    private renderSubQuestions() {
        const { question, responses } = this.props;

        const response = findResponseWithQuestionId(
            question.questionId,
            responses,
        ) as IArrayBooleanResponse;

        return question.subQuestions.map((subQuestion, index) => (
            <ChoiceInputContainer
                key={index}
                keypressValue={String.fromCharCode(65 + index)}
                labelString={getText(subQuestion, responses)}
                isInputSelected={this.isSubQuestionSelected(
                    response,
                    subQuestion.subQuestionId,
                )}
                isDisabled={false}
                input={
                    <GaInputChange
                        question={question}
                        key={`${question.questionId}_${subQuestion.subQuestionId}`}
                    >
                        <input
                            type="checkbox"
                            onChange={() =>
                                this.updateSubQuestionResponse(
                                    response,
                                    subQuestion.subQuestionId,
                                )
                            }
                            className="sub-question-text"
                        />
                    </GaInputChange>
                }
            />
        ));
    }

    private updateResponseOnKeyPress(
        question: IMultipleChoiceQuestion,
        response: IArrayBooleanResponse,
        e: KeyboardEvent,
    ) {
        const subQuestionId =
            question.subQuestions[e.key.charCodeAt(0) - 97].subQuestionId;

        this.updateSubQuestionResponse(response, subQuestionId);
    }

    private updateSubQuestionResponse(
        response: IArrayBooleanResponse,
        subQuestionId: string,
    ) {
        const newResponseValue = this.isSubQuestionSelected(
            response,
            subQuestionId,
        )
            ? undefined
            : true;

        this.setResponse(response, subQuestionId, newResponseValue);
    }

    private setResponse(
        response: IArrayBooleanResponse,
        subQuestionId: string,
        newResponseValue?: boolean,
    ) {
        const { question } = this.props;

        const hasSelectedExcludeOption =
            subQuestionId === question.excludeOption &&
            newResponseValue === true;

        if (hasSelectedExcludeOption) {
            question.subQuestions.forEach((subQuestion) =>
                this.unsetSubQuestionResponse(
                    response,
                    subQuestion.subQuestionId,
                ),
            );
        } else {
            if (question.excludeOption) {
                this.unsetSubQuestionResponse(response, question.excludeOption);
            }
        }

        setResponse(response, {
            subQuestionId,
            response: newResponseValue,
        });

        this.props.onChange();
    }

    private isSubQuestionSelected(
        response: IArrayBooleanResponse,
        subQuestionId: string,
    ) {
        const subQuestionResponse = findSubQuestionResponse(
            response,
            subQuestionId,
        );

        return subQuestionResponse.response === true;
    }

    private unsetSubQuestionResponse(
        response: IArrayBooleanResponse,
        subQuestionId: string,
    ) {
        setResponse(response, {
            subQuestionId,
            response: undefined,
        });
    }
}
