import '../../../styles/group.scss';

import * as React from 'react';
import classnames from 'classnames';

import { RadioQuestion as BaseRadioQuestion } from '../RadioQuestion/RadioQuestion';
import { NumberQuestion } from '../NumberQuestion/NumberQuestion';
import { MultipleNumberQuestion } from '../MultipleNumberQuestion/MultipleNumberQuestion';
import { MultipleChoiceQuestion } from '../MultipleChoiceQuestion/MultipleChoiceQuestion';
import { FlexibleRowArrayQuestion } from '../FlexibleRowArrayQuestion/FlexibleRowArrayQuestion';
import QuestionWrapper from './question-wrapper';

import { observer } from 'mobx-react';
import { TextDisplayQuestion } from '../TextDisplayQuestion/TextDisplayQuestion';
import { ConnectedQuestions } from '../ConnectedQuestions/ConnectedQuestions';
import { TextQuestion } from '../TextQuestion/TextQuestion';
import {
    ISurveyConfig,
    IRadioQuestionConfig,
    INumberQuestionConfig,
    IMultipleNumberQuestionConfig,
} from '../../models/survey-config';
import { ICustomizeSurveyProps } from '../../custom-components/customize-survey-props';
import {
    IGroup,
    getRelevantQuestions,
} from '@big-life-lab/pbl-limesurvey-engine/lib/group';
import { Response } from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import { QuestionType } from '@big-life-lab/pbl-limesurvey-engine/lib/question';

export interface GroupProps extends ICustomizeSurveyProps {
    group: IGroup;
    responses: Response[];
    updateQuestionVisibility: Function;
    currentQuestionId: string;
    targetQuestionId: string;
    nextQuestion: (id: string) => void;
    onChange: () => void;
    firstGroup: boolean;
    surveyConfig: ISurveyConfig;
}

@observer
export default class Group extends React.Component<GroupProps, {}> {
    render() {
        const { group, responses, customComponents } = this.props;

        return (
            <div
                id={group.groupId}
                className={classnames('group', {
                    'first-group': this.props.firstGroup,
                })}
            >
                {!group.hideGroupText && (
                    <div className="group-header">
                        <div className="group-name">
                            {group.text.toUpperCase()}
                        </div>
                        {/* Progress indicator divs*/}
                        {/* <div className="progress-indicator-bg">
            <div className="progress-indicator" style={{
              width: `${this.props.group.getPercentComplete(this.props.responses)}%`,
              backgroundColor:this.props.color
            }} />
          </div> */}
                    </div>
                )}
                {/* <div>
            <div
              className="group-description"
              dangerouslySetInnerHTML={{
                __html: this.props.group.description.getText(
                  this.props.responses
                )
              }}
            />
          </div> */}
                {getRelevantQuestions(group, responses).map(
                    (question, index) => {
                        const props = {
                            key: question.questionId,
                            responses,
                            onChange: this.props.onChange,
                            scrollToNextQuestion: () =>
                                this.props.nextQuestion(question.questionId),
                            focus:
                                this.props.currentQuestionId ===
                                question.questionId,
                            isFirstQuestion:
                                index === 0 && this.props.firstGroup,
                        };

                        const questionConfig = this.props.surveyConfig.questionsConfig.find(
                            ({ questionId }) => {
                                return questionId === question.questionId;
                            },
                        );

                        // Must be uppercase
                        //https://stackoverflow.com/questions/45582087/how-to-pass-props-to-react-component-that-wrapped-in-variable
                        let QuestionComp: any;

                        switch (question.questionType) {
                            case QuestionType.RadioQuestion: {
                                const RadioQuestion =
                                    customComponents &&
                                    customComponents.radioQuestion
                                        ? customComponents.radioQuestion
                                        : BaseRadioQuestion;

                                QuestionComp = (questionProps: {}) => (
                                    <RadioQuestion
                                        question={question}
                                        questionConfig={
                                            questionConfig as
                                                | IRadioQuestionConfig
                                                | undefined
                                        }
                                        {...props}
                                        {...questionProps}
                                    />
                                );
                                break;
                            }
                            case QuestionType.MultipleChoiceQuestion: {
                                QuestionComp = (questionProps: {}) => (
                                    <MultipleChoiceQuestion
                                        question={question}
                                        {...props}
                                        {...questionProps}
                                    />
                                );
                                break;
                            }
                            case QuestionType.FlexibleRowArrayQuestion: {
                                QuestionComp = (questionProps: any) => (
                                    <FlexibleRowArrayQuestion
                                        question={question}
                                        {...props}
                                        {...questionProps}
                                    />
                                );
                                break;
                            }
                            case QuestionType.NumberQuestion: {
                                QuestionComp = (questionProps: {}) => (
                                    <NumberQuestion
                                        question={question}
                                        {...props}
                                        {...questionProps}
                                        questionConfig={
                                            questionConfig as
                                                | INumberQuestionConfig
                                                | undefined
                                        }
                                    />
                                );
                                break;
                            }
                            case QuestionType.MultipleNumberQuestion: {
                                QuestionComp = (questionProps: any) => (
                                    <MultipleNumberQuestion
                                        question={question}
                                        questionConfig={
                                            questionConfig as
                                                | IMultipleNumberQuestionConfig
                                                | undefined
                                        }
                                        {...props}
                                        {...questionProps}
                                    />
                                );
                                break;
                            }
                            case QuestionType.TextDisplayQuestion: {
                                QuestionComp = (questionProps: any) => (
                                    <TextDisplayQuestion
                                        question={question}
                                        {...props}
                                        {...questionProps}
                                    />
                                );
                                break;
                            }
                            case QuestionType.ConnectedQuestions: {
                                QuestionComp = (questionProps: {}) => (
                                    <ConnectedQuestions
                                        question={question}
                                        {...props}
                                        {...questionProps}
                                    />
                                );
                                break;
                            }
                            case QuestionType.ShortTextQuestion:
                            case QuestionType.TextQuestion: {
                                QuestionComp = (questionProps: any) => (
                                    <TextQuestion
                                        question={question}
                                        {...props}
                                        {...questionProps}
                                    />
                                );
                                break;
                            }
                        }

                        // Wraps each question in a question wrapper which provides
                        // the react-scroll-percentage props
                        return (
                            <QuestionWrapper
                                key={question.questionId}
                                updateQuestionVisibility={
                                    this.props.updateQuestionVisibility
                                }
                                questionId={question.questionId}
                                current={
                                    this.props.currentQuestionId ===
                                    question.questionId
                                }
                                targetQuestion={
                                    this.props.targetQuestionId ===
                                    question.questionId
                                }
                            >
                                {(percentage: number, inView: boolean) => {
                                    return QuestionComp({ percentage, inView });
                                }}
                            </QuestionWrapper>
                        );
                    },
                )}
            </div>
        );
    }
}
