import '../../../styles/radio-answer-option.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { GaInputChange } from '../GaInputChange/GaInputChange';
import { Question } from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import ChoiceInputContainer from '../ChoiceQuestionInputContainer/ChoiceQuestionInputContainer';

export interface IRadioAnswerOptionProps {
    question?: Question;
    isChecked: boolean;
    onChange: () => void;
    answerOptionText: string;
    keypressValue?: string;
    imageUrl?: string;
    hideCheckmarks?: boolean;
}

//@ts-ignore
export const RadioAnswerOption: React.SFC<IRadioAnswerOptionProps> = observer(
    ({
        question,
        isChecked,
        onChange,
        answerOptionText,
        keypressValue,
        imageUrl,
        hideCheckmarks,
    }: IRadioAnswerOptionProps) => {
        let input = (
            <input
                type="radio"
                checked={isChecked}
                onChange={onChange}
                className="radio-answer-option__input"
            />
        );

        return (
            <div className="radio-answer-question">
                <ChoiceInputContainer
                    labelString={answerOptionText}
                    keypressValue={keypressValue}
                    hideCheckmarks={hideCheckmarks}
                    input={
                        question ? (
                            <GaInputChange question={question}>
                                {input}
                            </GaInputChange>
                        ) : (
                            input
                        )
                    }
                    imageUrl={imageUrl}
                    isInputSelected={isChecked}
                    isDisabled={false}
                />
            </div>
        );
    },
);
