import { Data } from '../data';
import { ReferencePopulation } from './reference-population';
import { CoxSurvivalAlgorithm } from '../algorithm/regression-algorithm/cox-survival-algorithm/cox-survival-algorithm';
import moment from 'moment';
import { IValidationError } from '../../validation/validate/validation-error';

export function getHealthAge(
    refPop: ReferencePopulation,
    data: Data,
    cox: CoxSurvivalAlgorithm,
):
    | IValidationError[]
    | {
          warnings: IValidationError[];
          healthAge: number;
      } {
    const oneYearFromToday = moment();
    oneYearFromToday.add(1, 'year');
    const oneYearRiskCalculation = cox.getRiskToTime(data, oneYearFromToday);

    if (oneYearRiskCalculation instanceof Array) {
        return oneYearRiskCalculation;
    }

    const healthAge = refPop.reduce((currentRefPopRow, refPopRow) => {
        if (
            Math.abs(refPopRow.outcomeRisk - oneYearRiskCalculation.risk) <
            Math.abs(currentRefPopRow.outcomeRisk - oneYearRiskCalculation.risk)
        ) {
            return refPopRow;
        }

        return currentRefPopRow;
    }, refPop[0]).age;
    return {
        warnings: oneYearRiskCalculation.warnings,
        healthAge,
    };
}
