import '../../../styles/survey.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { SurveyProgressBar } from '../SurveyProgressBar/SmartSurveyProgressBar';
import QuestionWrapper from '../Group/question-wrapper';
import classNames from 'classnames';

//models
import {
    IGroup,
    getRelevantQuestions,
} from '@big-life-lab/pbl-limesurvey-engine/lib/group';
import {
    ISurvey,
    getRelevantGroups,
} from '@big-life-lab/pbl-limesurvey-engine/lib/survey';
import { Response } from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import Group from '../Group/Group';
import { ISurveyConfig } from '../../models/survey-config';
import { ICustomizeSurveyProps } from '../../custom-components/customize-survey-props';
import { isEmbedded } from '../../utils/browser';

export interface SurveyProps extends ICustomizeSurveyProps {
    survey: ISurvey;
    responses: Response[];
    resultsComponents: Array<{
        id: string;
        component: React.ComponentType<any>;
    }>;
    surveyConfig: ISurveyConfig;
    onInputChange: () => void;
    surveyThemeClass: string;
}

export interface SurveyState {
    currentQuestionId: string;
    targetQuestionId: string;
}

@observer
export class Survey extends React.Component<SurveyProps, SurveyState> {
    static defaultProps = {
        surveyConfig: {
            questionsConfig: [],
        },
        surveyThemeClass: '',
    };

    constructor(props: SurveyProps) {
        super(props);

        this.state = {
            currentQuestionId: '',
            targetQuestionId: '',
        };
    }

    relQuestionIds = [] as string[];
    relevantGroups = [] as IGroup[];

    handlers = {
        moveUp: () => {
            this.previousQuestion();
        },
        moveDown: () => this.nextQuestion(),
    };

    onArrowPress = (up: boolean) => {
        up ? this.previousQuestion() : this.nextQuestion();
    };

    // Scroll the user to the next question
    nextQuestion = (
        clickedQuestionId: string = this.state.currentQuestionId,
    ) => {
        this.setState(
            {
                targetQuestionId: '',
            },
            () => {
                this.getRelevantQuestions();
                const currentQuestionIndex = this.relQuestionIds.indexOf(
                    clickedQuestionId,
                );
                if (currentQuestionIndex > -1) {
                    this.setState({
                        targetQuestionId: this.relQuestionIds[
                            currentQuestionIndex + 1
                        ],
                    });
                }
            },
        );
    };

    // Scroll the user to the previous question
    previousQuestion = () => {
        this.getRelevantQuestions();
        const currentQuestionIndex = this.relQuestionIds.indexOf(
            this.state.currentQuestionId,
        );
        if (currentQuestionIndex > -1) {
            this.setState({
                targetQuestionId: this.relQuestionIds[currentQuestionIndex - 1],
            });
        }
    };

    updateQuestionVisibility = (id: string, percentage: number) => {
        if (
            percentage > 0.3 &&
            percentage < 0.7 &&
            this.state.currentQuestionId != id
        ) {
            this.setState({
                currentQuestionId: id,
            });
        }
    };

    // Get all relevant question ids in an array
    getRelevantQuestions() {
        this.relevantGroups = getRelevantGroups(
            this.props.survey,
            this.props.responses,
        );

        this.relQuestionIds = this.relevantGroups.reduce((agg, group) => {
            let questionIds = getRelevantQuestions(
                group,
                this.props.responses,
            ).map((question) => {
                return question.questionId;
            });
            return agg.concat(questionIds);
        }, [] as any[]);

        // Add the ids of the results components
        this.relQuestionIds = this.relQuestionIds.concat(
            this.props.resultsComponents.map(({ id }) => {
                return id;
            }),
        );

        // Add id of the hardcoded end of survey view
        this.relQuestionIds.push('survey-end');
    }

    render() {
        this.getRelevantQuestions();

        return (
            <div
                className={classNames('survey', this.props.surveyThemeClass)}
                tabIndex={-1}
            >
                {this.relevantGroups.map((group, index) => {
                    return (
                        <Group
                            key={index}
                            firstGroup={index === 0}
                            group={group}
                            responses={this.props.responses}
                            updateQuestionVisibility={
                                this.updateQuestionVisibility
                            }
                            targetQuestionId={this.state.targetQuestionId}
                            currentQuestionId={this.state.currentQuestionId}
                            nextQuestion={this.nextQuestion}
                            surveyConfig={this.props.surveyConfig}
                            customComponents={this.props.customComponents}
                            onChange={this.props.onInputChange}
                        />
                    );
                })}

                <div className="results" tabIndex={-1}>
                    {this.props.resultsComponents.map(({ component, id }) => {
                        const Component = component;

                        var props = {
                            key: id,
                            nextQuestion: this.nextQuestion,
                            focus: this.state.currentQuestionId === id,
                        };
                        var ResultComp = (resultProps: any) => (
                            <Component {...props} {...resultProps} />
                        );

                        return (
                            <QuestionWrapper
                                key={id}
                                questionId={id}
                                updateQuestionVisibility={
                                    this.updateQuestionVisibility
                                }
                                current={this.state.currentQuestionId === id}
                                targetQuestion={
                                    this.state.targetQuestionId === id
                                }
                            >
                                {(percentage: number, inView: boolean) => {
                                    return ResultComp({ percentage, inView });
                                }}
                            </QuestionWrapper>
                        );
                    })}
                </div>

                <div className="survey-footer">
                    <div className="flex-container">
                        <div id="left-container" className="left-container">
                            <SurveyProgressBar
                                survey={this.props.survey}
                                responses={this.props.responses}
                            />
                        </div>
                        {isEmbedded() ? undefined : (
                            <div className="button-container">
                                {/* Debug info */}
                                {/* {this.state.currentQuestionId} */}

                                <StyledButton
                                    {...(this.props as SurveyProps)}
                                    up={true}
                                    onArrowPress={this.onArrowPress}
                                    isInputSelected={false}
                                >
                                    Previous
                                </StyledButton>
                                <StyledButton
                                    {...(this.props as SurveyProps)}
                                    up={false}
                                    onArrowPress={this.onArrowPress}
                                    isInputSelected={false}
                                >
                                    Next
                                </StyledButton>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

interface ButtonProps {
    isInputSelected: boolean;
    children: string;
    up: boolean;
    onArrowPress: (up: boolean) => void;
}

const Button = (props: ButtonProps) => {
    return (
        <button className="btn" onClick={() => props.onArrowPress(props.up)}>
            {props.children}
        </button>
    );
};

const StyledButton = Button;
