import './project-big-life.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { MPoRTSurvey } from '../../smart/MPoRTSurvey';
import { ShortReport } from '../ShortReport/ShortReport';

export interface IProjectBigLifeProps {
    showShortReport: boolean;
    shortReportValue: number;
}

export const ProjectBigLife: React.SFC<IProjectBigLifeProps> = observer(
    ({ shortReportValue, showShortReport }: IProjectBigLifeProps) => {
        return (
            <div className="project-big-life">
                <ShortReport
                    showShortReport={showShortReport}
                    value={shortReportValue}
                    shortReportContainerId="mport-short-report-container"
                    shortReportValueDivId="mport-short-report-value"
                />
                <MPoRTSurvey />
            </div>
        );
    },
);
