import './cvdport-calculator.scss';

import * as React from 'react';
import { observer, Provider } from 'mobx-react';
import { Survey } from '@big-life-lab/pbl-survey-components/src';
import { CVDPoRT } from '../../../store/ui/cvdport';
import { CVDPoRTIconGraph } from '../CVDPoRTIconGraph';
import { CVDPoRTHeartAge } from '../CVDPoRTHeartAge';
import { CVDPoRTRiskResult } from '../CVDPoRTRiskResult';
import { ShortReport } from '../../dumb/ShortReport/ShortReport';

@observer
export class CVDPoRTCalculator extends React.Component {
    render() {
        return CVDPoRT.getSingleton().isConstructed ? (
            <Provider cvdport={CVDPoRT.getSingleton()}>
                <>
                    <ShortReport
                        showShortReport={CVDPoRT.getSingleton().showShortReport}
                        shortReportValueDivId="cvd-short-report-value"
                        shortReportContainerId="cvd-short-report-container"
                        value={CVDPoRT.getSingleton().healthyHeartAge}
                    />
                    <Survey
                        surveyThemeClass="cvdport-survey"
                        survey={CVDPoRT.getSingleton().surveySingleton.survey}
                        responses={
                            CVDPoRT.getSingleton().surveySingleton.responses
                        }
                        resultsComponents={[
                            {
                                id: 'cvd-port-risk-result',
                                component: CVDPoRTRiskResult,
                            },
                            {
                                id: 'cvd-port-icon-graph',
                                component: CVDPoRTIconGraph,
                            },
                        ]}
                        surveyConfig={
                            CVDPoRT.getSingleton().surveySingleton.surveyConfig
                        }
                        onInputChange={() => {}}
                    />
                </>
            </Provider>
        ) : null;
    }
}
