import '../../../styles/sub-question-input.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { GaInputChange } from '../GaInputChange/GaInputChange';
import { SurveyInput } from '../Inputs/SurveyInput';
import { formatNumberValueFromEvent } from '../../utils/number-input';
import {
    IMultipleNumberQuestion,
    ISubQuestion,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    ISubQuestionResponse,
    IArrayNumberResponse,
    setResponse,
    IConnectedQuestionResponse,
    isConnectedQuestionResponse,
    findResponseWithQuestionId,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';

export interface ISubQuestionNumberInputProps {
    question: IMultipleNumberQuestion;
    subQuestion: ISubQuestion;
    subQuestionResponse: ISubQuestionResponse<number>;
    response: IConnectedQuestionResponse | IArrayNumberResponse;
    inputLabel: string;
    suffixText?: string;
    errorText?: string;
    onBecomeDirty?: Function;
    focus: boolean;
    onChange: () => void;
}

//@ts-ignore
export const SubQuestionNumberInput: React.SFC<ISubQuestionNumberInputProps> = observer(
    ({
        subQuestion,
        subQuestionResponse,
        question,
        response,
        suffixText,
        inputLabel,
        onBecomeDirty,
        errorText,
        focus,
        onChange,
    }: ISubQuestionNumberInputProps) => {
        return (
            <div className="sub-question-number-input">
                <GaInputChange question={question}>
                    <SurveyInput
                        inputType="number"
                        inputLabelText={inputLabel}
                        question={question}
                        response={subQuestionResponse.response}
                        errorText={errorText}
                        onBecomeDirty={onBecomeDirty}
                        onChange={(e) => {
                            const formattedResponseValue = formatNumberValueFromEvent(
                                e,
                            );

                            if (isConnectedQuestionResponse(response)) {
                                const arrayNumberResponse = findResponseWithQuestionId(
                                    question.questionId,
                                    response.response,
                                ) as IArrayNumberResponse;
                                setResponse(arrayNumberResponse, {
                                    subQuestionId: subQuestion.subQuestionId,
                                    response: formattedResponseValue,
                                });
                                setResponse(response, arrayNumberResponse);
                            } else {
                                setResponse(response, {
                                    subQuestionId: subQuestion.subQuestionId,
                                    response: formattedResponseValue,
                                });
                            }

                            onChange();
                        }}
                        suffixText={suffixText}
                        showErrorText={false}
                        focus={focus}
                    />
                </GaInputChange>
            </div>
        );
    },
);
