import { JsonSerializable } from '../../util/types';
import { Range } from '../../range/range';
import { JsonMargin } from './json-margin';
import { Omit } from 'utility-types';

export interface JsonRange
    extends Omit<JsonSerializable<Range>, 'lowerMargin' | 'higherMargin'> {
    lowerMargin: JsonMargin;
    higherMargin: JsonMargin;
}

export function prettyPrintJsonRange(jsonRange: JsonRange): string {
    let rangeString = '';

    if (jsonRange.lowerMargin!.isOpen) {
        rangeString += '(';
    } else {
        rangeString += '[';
    }

    rangeString += jsonRange.lowerMargin!.margin + ',';

    rangeString += jsonRange.higherMargin!.margin;

    if (jsonRange.higherMargin!.isOpen) {
        rangeString += ')';
    } else {
        rangeString += ']';
    }

    return rangeString;
}
