import './will-you-live-to-see-it.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { select, selectAll, event } from 'd3-selection';
import { drag } from 'd3-drag';
import { scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { AwsHost } from '../../../constants/constants';
import AppearingNextButton from '@big-life-lab/pbl-survey-components/src/components/AppearingNextButton/AppearingNextButton';
import { HotKeys } from 'react-hotkeys';
import { isEmbedded } from '@ottawamhealth/pbl-utils/lib/browser';
import classnames from 'classnames';

export interface IWillYouLiveToSeeItProps {
    lowerAge: number;
    higherAge: number;
    willYouLiveToSeeItAge: number;
    willYouLiveToSeeItSurvival: number;
    bigLifeText: string;
    bigLifeYear: string;
    sliderAge: number;
    sliderSurvivalPercent: number;
    updateSliderValue: (newValue: number) => void;
    nextQuestion: Function;
    focus: boolean;
}

@observer
export class WillYouLiveToSeeIt extends React.Component<
    IWillYouLiveToSeeItProps
> {
    // Set in ref function
    rootNode!: HTMLElement;

    divRef!: HTMLDivElement;

    handlers = {
        nextQuestion: () => this.props.nextQuestion(),
    };

    componentDidUpdate(prevProps: IWillYouLiveToSeeItProps, _prevState: {}) {
        select(this.rootNode)
            .datum(this.props)
            .call(this.update);

        //console.log('CDU');
        // console.log(`CDU ${prevProps.focus} - ${this.props.focus}`);
        if (!prevProps.focus && this.props.focus) {
            // Timer allows the question-wrapper's scrollIntoView call time to happen
            // before calling focus on this field intercepts the scroll
            // preventScroll option has limited browser compatibility
            setTimeout(() => {
                //@ts-ignore
                this.divRef.focus({ preventScroll: true });
            }, 100);
        }
    }

    //Set in componentDidMount
    update!: (selection: d3.Selection<any, any, any, any>) => void;
    componentDidMount() {
        this.update = willYouLiveToSeeIt();
        select(this.rootNode)
            .datum(this.props)
            .call(this.update);
    }

    render() {
        const keyMap = {
            nextQuestion: 'return',
        };

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={this.handlers}
                focused={this.props.focus}
            >
                <div
                    ref={c => (this.divRef = c as HTMLDivElement)}
                    tabIndex={5}
                    id="mport-live-to-see-it"
                    className={classnames('will-you-live-to-see-it-wrapper', {
                        'result--embedded': isEmbedded(),
                    })}
                >
                    <h3>Will you live to see it?</h3>
                    <p>
                        This report show you the likelihood of surviving to a
                        date in the future. Drag the handle to see how survival
                        changes over time.
                    </p>
                    <p>
                        The likelihood of living to see{' '}
                        <strong>{this.props.bigLifeText}</strong> in{' '}
                        <strong>{this.props.bigLifeYear}</strong> is{' '}
                        <strong>{`${this.props.willYouLiveToSeeItSurvival}%`}</strong>
                        .
                    </p>
                    <div
                        className="will-you-live-to-see-it"
                        ref={refNode => {
                            this.rootNode = refNode as HTMLElement;
                        }}
                    />
                    {isEmbedded() === false ? (
                        <AppearingNextButton
                            onClick={this.props.nextQuestion}
                        />
                    ) : (
                        <div className="buttom-prompt button-prompt--embedded">
                            Scroll for the next report
                        </div>
                    )}
                </div>
            </HotKeys>
        );
    }
}

function willYouLiveToSeeIt() {
    const ClassNames = {
        svg: 'will-you-live-to-see-it__svg',
        barContainer: 'will-you-live-to-see-it__bar-container',
        willYouLiveToSeeItMetricsContainer:
            'will-you-live-to-see-it__metrics-container',
        sliderContainer: 'will-you-live-to-see-it__slider-container',
    };

    const BarHeight = 60;

    const WillYouSeeItMetricsCircleRadius = 10;
    const renderWillYouLiveToSeeItMetrics = willYouLiveToSeeItMetrics({
        spaceBetweenTextAndCircle: BarHeight / 2,
        circleRadius: WillYouSeeItMetricsCircleRadius,
    });
    const willYouLiveToSeeItOverflow = onXOverflow(
        renderWillYouLiveToSeeItMetrics.onOverflowRight,
        renderWillYouLiveToSeeItMetrics.onOverflowLeft,
    );

    const SliderControlWidth = 75;
    const renderSlider = slider({ sliderControlWidth: SliderControlWidth });
    const sliderControlHeight =
        1 / (renderSlider.getDragMeControlAspectRatio() / SliderControlWidth);
    const sliderOverflow = onXOverflow(
        renderSlider.onOverflowRight,
        renderSlider.onOverflowLeft,
    );

    const state = {
        isDragging: false,
        hasBeenDragged: false,
    };

    function willYouLiveToSeeIt(
        selection: d3.Selection<any, IWillYouLiveToSeeItProps, any, any>,
    ) {
        const {
            width,
            height,
        } = (selection.node() as HTMLElement).getBoundingClientRect();
        const datum = selection.datum();
        const ageScale = scaleLinear()
            .domain([datum.lowerAge, datum.higherAge])
            .range([0, width]);

        const svg = selection.selectAll('svg').data([datum]);
        const svgEnter = svg
            .enter()
            .append('svg')
            .attr('height', height)
            .attr('width', width)
            .attr('class', ClassNames.svg);

        const renderBar = bar({ barWidth: width, barHeight: BarHeight });
        const barG = svg
            .selectAll<SVGGElement, IWillYouLiveToSeeItProps>(
                `.${ClassNames.barContainer}`,
            )
            .data([datum]);
        const barGEnter = svgEnter
            .append('g')
            .attr('class', ClassNames.barContainer)
            .data([datum]);
        barGEnter
            .merge(barG)
            //@ts-ignore
            .call(renderBar)
            .call(centerGVertically);

        const willYouSeeItMetricsGEnter = svgEnter
            .append('g')
            .attr('class', ClassNames.willYouLiveToSeeItMetricsContainer)
            .data([datum])
            .call(renderWillYouLiveToSeeItMetrics);
        const willYouSeeItMetricsG = svg
            .selectAll<SVGGElement, IWillYouLiveToSeeItProps>(
                `.${ClassNames.willYouLiveToSeeItMetricsContainer}`,
            )
            .data([datum])
            .call(renderWillYouLiveToSeeItMetrics);
        willYouSeeItMetricsGEnter
            .merge(willYouSeeItMetricsG)
            .attr('transform', function() {
                const node = select(this);

                const translateX =
                    ageScale(datum.willYouLiveToSeeItAge) -
                    WillYouSeeItMetricsCircleRadius;
                const translateY =
                    height / 2 -
                    (node.node() as SVGGElement).getBBox().height +
                    WillYouSeeItMetricsCircleRadius;

                return `translate(${translateX}, ${translateY})`;
            })
            .call(willYouLiveToSeeItOverflow);

        const sliderGEnter = svgEnter
            .append('g')
            .attr('class', ClassNames.sliderContainer);
        const sliderG = svg.selectAll(`.${ClassNames.sliderContainer}`).data([
            Object.assign({}, datum, {
                hasBeenDragged: state.hasBeenDragged,
            }),
        ]);
        sliderGEnter
            //@ts-ignore
            .merge(sliderG)
            // @ts-ignore
            .call(renderSlider)
            .call(
                //@ts-ignore
                drag()
                    .on('start', dragStart)
                    .on('drag', dragged)
                    .on('end', dragEnd),
            )
            .attr('transform', function() {
                const sliderTranslateX =
                    ageScale(datum.sliderAge) - SliderControlWidth / 2;
                const sliderTranslateY = height / 2 - sliderControlHeight / 2;

                return `translate(${sliderTranslateX}, ${sliderTranslateY})`;
            });

        function dragStart() {
            if (
                event.sourceEvent.target.className.baseVal ===
                'will-you-live-to-see-it__slider-control'
            ) {
                state.hasBeenDragged = true;
                state.isDragging = true;
            }
        }

        function dragEnd() {
            state.isDragging = false;
        }

        function dragged() {
            if (!state.isDragging) {
                return;
            }

            selectAll(`.${ClassNames.sliderContainer}`)
                .data([datum])
                .call(sliderOverflow);
            select('.will-you-live-to-see-it__drag-me-image').attr(
                'class',
                'will-you-live-see-it__drag-me-text--hidden',
            );

            sliderG
                .selectAll('.will-you-live-see-it__drag-me-text--hidden')
                .data([datum])
                .attr('class', 'will-you-live-see-it__drag-me-text--hidden');

            let newX = event.x;

            if (newX < 0) {
                newX = 0;
            } else if (newX > Number(width)) {
                newX = Number(width);
            }
            datum.updateSliderValue(ageScale.invert(newX));
        }
    }

    return willYouLiveToSeeIt;
}

function bar({ barWidth, barHeight }: { barWidth: number; barHeight: number }) {
    const ClassNames = {
        bar: 'will-you-live-to-see-it__bar',
    };

    function bar(
        selection: d3.Selection<
            SVGGElement,
            IWillYouLiveToSeeItProps,
            any,
            any
        >,
    ) {
        selection.each(function(datum) {
            const container = select(this);

            const containerEnter = container
                .selectAll('defs')
                .data([datum])
                .enter();

            containerEnter.append('defs').html(`
                    <linearGradient id="bar-gradient">
                        <stop stop-color="#64d0fa"/>
                        <stop offset="1" stop-color="#64d0fa" stop-opacity="0"/>
                    </linearGradient>
                `);

            const TextFontSize = 17;
            const TextSpacing = 10;

            //100% Text
            containerEnter
                .append('text')
                .attr('y', TextFontSize)
                .attr('x', 0)
                .attr('font-size', TextFontSize)
                .attr('class', 'will-you-live-to-see-it__bar-text')
                .text('100%');
            //0% Text
            containerEnter
                .append('text')
                .attr('y', TextFontSize)
                .attr('x', barWidth - 25)
                .attr('font-size', TextFontSize)
                .attr('class', 'will-you-live-to-see-it__bar-text')
                .text('0%');

            const barY = TextFontSize + TextSpacing;
            const barX = 0;

            const bar = container.selectAll(`.${ClassNames.bar}`).data([datum]);
            bar.enter()
                .append('rect')
                .attr('x', barX)
                .attr('y', barY)
                .attr('rx', 5)
                .attr('ry', 5)
                .attr('width', barWidth)
                .attr('height', barHeight)
                .attr('class', ClassNames.bar)
                .attr('fill', `url('#bar-gradient')`);

            //Lower age value text
            const lowerAgeText = container
                .selectAll<SVGTextElement, IWillYouLiveToSeeItProps>(
                    `.will-you-live-to-see-it__bar-lower-age-text`,
                )
                .data([datum]);
            const lowerAgeTextEnter = lowerAgeText
                .enter()
                .append('text')
                .attr('y', barY + barHeight + TextFontSize + TextSpacing - 5)
                .attr('x', barX)
                .attr('font-size', TextFontSize)
                .attr('class', 'will-you-live-to-see-it__bar-lower-age-text')
                .data([datum]);
            lowerAgeTextEnter.merge(lowerAgeText).text(`Age ${datum.lowerAge}`);

            //Higher age value text
            containerEnter
                .append('text')
                .attr('y', barY + barHeight + TextFontSize + TextSpacing - 5)
                .attr('x', barX + barWidth - 60)
                .attr('font-size', TextFontSize)
                .attr('class', 'will-you-live-to-see-it__bar-text')
                .text(`Age ${datum.higherAge}`);
        });
    }

    return bar;
}

function centerGVertically(selection: d3.Selection<any, any, any, any>) {
    selection.each(function() {
        const currentSelection = select(this);

        const currentNode = currentSelection.node() as SVGGElement;
        const currentNodeBBox = currentNode.getBBox();

        const parentNode = currentNode.parentElement as Element;
        const parentNodeBoundingClientRect = parentNode.getBoundingClientRect();

        currentSelection.attr(
            'transform',
            `translate(${0}, ${(parentNodeBoundingClientRect.height -
                currentNodeBBox.height) /
                2})`,
        );
    });
}

function willYouLiveToSeeItMetrics({
    spaceBetweenTextAndCircle,
    circleRadius,
}: {
    spaceBetweenTextAndCircle: number;
    circleRadius: number;
}): {
    (selection: d3.Selection<any, any, any, any>): void;
    onOverflowRight: (selection: d3.Selection<any, any, any, any>) => void;
    onOverflowLeft: (selection: d3.Selection<any, any, any, any>) => void;
} {
    const ClassNames = {
        textContainer: 'will-you-live-to-see-it__text-container',
        percentageTextContainer:
            'will-you-live-to-see-it__percentage-text-container',
        percentage: 'will-you-live-to-see-it__percentage',
        percentageText: 'will-you-live-to-see-it__percentage-text',
        text: 'will-you-live-to-see-it__text',
        age: 'will-you-live-to-see-it__age',
        line: 'will-you-live-to-see-it__line',
        circle: 'will-you-live-to-see-it__circle',
    };

    const SpaceBetweenTextAndLine = 5;
    const HorizontalLineLength = 10;
    const BarTextHeight = 30;

    function getLineDAttr(
        testContainer: d3.Selection<any, any, any, any>,
        facingRight: boolean,
    ) {
        const textContainerClientRect = (testContainer.node() as HTMLElement).getBoundingClientRect();
        const survivalTextContainer = testContainer.select(
            `.${ClassNames.percentageTextContainer}`,
        );

        // The y coordinate of the horizontal line that points to the middle of the survival percentage text
        const PercentageTextLineHeight =
            (survivalTextContainer.node() as HTMLElement).getBoundingClientRect()
                .height /
                2 -
            5;
        const LineEndY =
            textContainerClientRect.height +
            BarTextHeight +
            spaceBetweenTextAndCircle;

        const startingX = facingRight
            ? circleRadius + HorizontalLineLength
            : circleRadius - HorizontalLineLength;

        return line()([
            [startingX, PercentageTextLineHeight],
            [circleRadius, PercentageTextLineHeight],
            [circleRadius, LineEndY],
        ]) as string;
    }

    const willYouLiveToSeeItMetrics = function(
        selection: d3.Selection<any, IWillYouLiveToSeeItProps, any, any>,
    ) {
        selection.each(function(d) {
            const container = select(this);

            const foreignObjectTextContainer = container
                .selectAll('foreignObject')
                .data([d]);
            const foreignObjectTextContainerEnter = foreignObjectTextContainer
                .enter()
                .append('foreignObject')
                .attr('y', 0)
                .attr(
                    'x',
                    SpaceBetweenTextAndLine +
                        circleRadius +
                        HorizontalLineLength,
                )
                .attr('width', '100%')
                .attr('height', '100%');

            const textContainerEnter = foreignObjectTextContainerEnter
                .append('xhtml:div')
                .attr('class', ClassNames.textContainer);
            const textContainer = foreignObjectTextContainer
                .selectAll(`.${ClassNames.textContainer}`)
                .data([d]);
            textContainerEnter.merge(textContainer).html(function() {
                return `
                    <div class="${ClassNames.percentageTextContainer}">
                        <span class="${ClassNames.percentage}">
                            ${d.willYouLiveToSeeItSurvival}%    
                        </span>
                        <span class="${ClassNames.percentageText}">
                            chance of survival
                        </span>
                    </div>
                    <div class="${ClassNames.text}">
                        ${d.bigLifeText} in ${d.bigLifeYear}
                    </div>
                    <div class="${ClassNames.age}">
                        Age: ${d.willYouLiveToSeeItAge}
                    </div>
                `;
            });

            foreignObjectTextContainerEnter.call(
                setForeignObjectDimentions(
                    foreignObjectTextContainerEnter.select(
                        `.${ClassNames.percentageTextContainer}`,
                    ),
                ),
            );

            const path = container.selectAll(`.${ClassNames.line}`).data([d]);
            path.enter()
                .append('path')
                .attr('class', ClassNames.line)
                .attr('d', function() {
                    return getLineDAttr(textContainerEnter, true);
                });

            const circle = container
                .selectAll(`.${ClassNames.circle}`)
                .data([d]);
            circle
                .enter()
                .append('circle')
                .attr('cx', circleRadius)
                .attr('cy', function() {
                    const textContainerClientRect = (textContainerEnter.node() as HTMLElement).getBoundingClientRect();

                    return (
                        textContainerClientRect.height +
                        spaceBetweenTextAndCircle +
                        BarTextHeight
                    );
                })
                .attr('class', ClassNames.circle)
                .attr('r', circleRadius);
        });
    };

    return Object.assign(willYouLiveToSeeItMetrics, {
        onOverflowRight: (selection: d3.Selection<any, any, any, any>) => {
            const textContainer = selection.select(
                `.${ClassNames.textContainer}`,
            );
            const boundingClientRect = (textContainer.node() as HTMLElement).getBoundingClientRect();

            selection
                .select('foreignObject')
                .attr(
                    'transform',
                    `translate(${-boundingClientRect.width - 30}, ${0})`,
                );

            selection.select(`.${ClassNames.line}`).attr('d', function() {
                return getLineDAttr(
                    selection.select(`.${ClassNames.textContainer}`),
                    false,
                );
            });
        },
        onOverflowLeft: (selection: d3.Selection<any, any, any, any>) => {
            selection
                .select('foreignObject')
                .attr('transform', `translate(${0}, ${0})`);

            selection.select(`.${ClassNames.line}`).attr('d', function() {
                return getLineDAttr(
                    selection.select(`.${ClassNames.textContainer}`),
                    true,
                );
            });
        },
    });
}

function slider({
    sliderControlWidth,
}: {
    sliderControlWidth: number;
}): {
    (
        selection: d3.Selection<
            any,
            IWillYouLiveToSeeItProps & { hasBeenDragged: boolean },
            any,
            any
        >,
    ): void;
    getDragMeControlAspectRatio: () => number;
    onOverflowRight: (selection: d3.Selection<any, any, any, any>) => void;
    onOverflowLeft: (selection: d3.Selection<any, any, any, any>) => void;
    hideDragMeImage: (selection: d3.Selection<any, any, any, any>) => void;
} {
    const SliderControlConfig = {
        aspectRatio: 144 / 76,
        width: sliderControlWidth,
        get height(): number {
            return 1 / (this.aspectRatio / this.width);
        },
    };

    const DragMeTextImageConfig = {
        height: 40,
        aspectRatio: 162 / 87,
        get width(): number {
            return this.height * this.aspectRatio;
        },
    };

    const SpaceBetweenSliderAndText = 100;

    const ClassNames = {
        sliderControl: 'will-you-live-to-see-it__slider-control',
        textContainer: 'will-you-live-to-see-it__text-container',
        percentageTextContainer:
            'will-you-live-to-see-it__percentage-text-container',
        percentage: 'will-you-live-to-see-it__percentage',
        text: 'will-you-live-to-see-it__text',
        age: 'will-you-live-to-see-it__age',
        line: 'will-you-live-to-see-it__slider-line',
        dragMeImage: 'will-you-live-to-see-it__drag-me-image',
    };

    function renderSlider(
        selection: d3.Selection<
            any,
            IWillYouLiveToSeeItProps & { hasBeenDragged: boolean },
            any,
            any
        >,
    ) {
        selection.each(function(d) {
            const container = select(this);

            // Add Slider Control
            container
                .selectAll(`.${ClassNames.sliderControl}`)
                .data([d])
                .enter()
                .append('image')
                .attr('class', ClassNames.sliderControl)
                .attr('xlink:href', `${AwsHost}/img/drag-control.svg`)
                .attr('width', SliderControlConfig.width)
                // Add width also due to Edge bug where if both width and height is not specified it won't render the image
                .attr('height', SliderControlConfig.height);

            const foreignObjectTextContainer = container
                .selectAll('foreignObject')
                .data([d]);
            const foreignObjectTextContainerEnter = foreignObjectTextContainer
                .enter()
                .append('foreignObject')
                .attr('y', 0)
                .attr('x', 0)
                .attr('width', '100%')
                .attr('height', '100%');
            //Position the foreignObject
            foreignObjectTextContainerEnter
                .attr(
                    'y',
                    SliderControlConfig.height + SpaceBetweenSliderAndText,
                )
                .attr('x', DragMeTextImageConfig.width / 2 + 5);

            const textContainerEnter = foreignObjectTextContainerEnter
                .append('xhtml:div')
                .attr('class', ClassNames.textContainer);
            const textContainer = foreignObjectTextContainer
                .selectAll(`.${ClassNames.textContainer}`)
                .data([d]);
            textContainerEnter.merge(textContainer).html(`
                <div class=${ClassNames.percentageTextContainer}>
                    <span class=${ClassNames.percentage}>
                        ${d.sliderSurvivalPercent}%
                    </span>
                </div>
                <div class=${ClassNames.text}>
                    ${new Date().getFullYear() + (d.sliderAge - d.lowerAge)}
                </div>
                <div class=${ClassNames.age}>
                    Age: ${d.sliderAge}
                </div>
            `);

            foreignObjectTextContainerEnter.call(
                setForeignObjectDimentions(textContainerEnter),
            );

            container
                .selectAll(`.${ClassNames.line}`)
                .data([d])
                .enter()
                .append('path')
                .attr('class', ClassNames.line)
                .attr('d', function() {
                    const textContainerClientRect = (textContainerEnter.node() as HTMLElement).getBoundingClientRect();

                    //prettier-ignore
                    return line()([
                        [
                            DragMeTextImageConfig.width / 2, SliderControlConfig.height
                        ],
                        [
                            DragMeTextImageConfig.width / 2, SliderControlConfig.height + SpaceBetweenSliderAndText + textContainerClientRect.height
                        ]
                    ]) as string;
                });

            if (!d.hasBeenDragged) {
                // DragMe image. Add it at the end so that it appears below the dotted line
                container
                    .selectAll(`.${ClassNames.dragMeImage}`)
                    .data([d])
                    .enter()
                    .append('image')
                    .attr('class', ClassNames.dragMeImage)
                    .attr('xlink:href', `${AwsHost}/img/drag-me.svg`)
                    .attr('height', DragMeTextImageConfig.height)
                    // Add width also due to Edge bug where if both width and height is not specified it won't render the image
                    .attr('width', DragMeTextImageConfig.width)
                    .attr('y', DragMeTextImageConfig.height - 10);
            }
        });
    }

    return Object.assign(renderSlider, {
        getDragMeControlAspectRatio: () => {
            return SliderControlConfig.aspectRatio;
        },
        onOverflowRight: (selection: d3.Selection<any, any, any, any>) => {
            const textContainer = selection.select(
                `.${ClassNames.textContainer}`,
            );
            const boundingClientRect = (textContainer.node() as HTMLElement).getBoundingClientRect();

            selection
                .select('foreignObject')
                .attr(
                    'transform',
                    `translate(${-boundingClientRect.width - 10}, ${0})`,
                );
        },
        onOverflowLeft: (selection: d3.Selection<any, any, any, any>) => {
            selection
                .select('foreignObject')
                .attr('transform', `translate(${0}, ${0})`);
        },
        hideDragMeImage: (selection: d3.Selection<any, any, any, any>) => {
            selection
                .select(`.${ClassNames.dragMeImage}`)
                .attr('class', 'will-you-live-see-it__drag-me-text--hidden');
        },
    });
}

function setForeignObjectDimentions(
    selectionDimentionsToMatch: d3.Selection<any, any, any, any>,
) {
    return function(selection: d3.Selection<any, any, any, any>) {
        selection.each(function() {
            select(this)
                .attr('width', function() {
                    return (
                        (selectionDimentionsToMatch.node() as HTMLElement).getBoundingClientRect()
                            .width + 10
                    );
                })
                .attr('height', function() {
                    return (selectionDimentionsToMatch.node() as HTMLElement).getBoundingClientRect().height;
                });
        });
    };
}

function onXOverflow(
    onOverflowRight: (selection: d3.Selection<any, any, any, any>) => void,
    onOverflowLeft: (selection: d3.Selection<any, any, any, any>) => void,
) {
    return function(selection: d3.Selection<any, any, any, any>) {
        selection.each(function() {
            const currentSelection = select(this);

            const currentNode = currentSelection.node() as SVGElement;
            const currentNodeRect = currentNode.getBoundingClientRect();

            let parentNode = currentNode.parentNode as SVGElement;
            while (parentNode.nodeName !== 'svg') {
                //@ts-ignore
                parentNode = parentNode.parentElement;
            }
            const parentNodeRect = parentNode.getBoundingClientRect();

            if (
                currentNodeRect.left + currentNodeRect.width >=
                parentNodeRect.right
            ) {
                return onOverflowRight(currentSelection);
            } else if (currentNodeRect.left < parentNodeRect.left) {
                return onOverflowLeft(currentSelection);
            }
        });
    };
}
