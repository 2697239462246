const phpDate = require('locutus/php/datetime/date');
const phpTime = require('locutus/php/datetime/time');
const phpStrToTime = require('locutus/php/datetime/strtotime');

const date = phpDate;

const time = phpTime;

const strtotime = phpStrToTime;

function returnEvaluateOrString(code: any): any {
    if (typeof code === 'string') {
        return code;
    } else {
        return eval(code);
    }
}

function ifFunc(condition: string, resultOne: string, resultTwo: string) {
    if (eval(condition)) {
        return returnEvaluateOrString(resultOne);
    } else {
        return returnEvaluateOrString(resultTwo);
    }
}

function sum(...numbers: number[]): number {
    return numbers.reduce((currentSum, currentNumber) => {
        return currentSum + currentNumber;
    }, 0);
}

// Implemented as given here http://www.php.net/manual/en/function.empty.php
function is_empty(value: any): boolean {
    return (
        value === '' ||
        value === 0 ||
        value === '0' ||
        value === null ||
        value === undefined ||
        value === false ||
        (value instanceof Array && value.length === 0)
    );
}

function floor(value: any): number {
    return Math.floor(value);
}

function ceil(value: any): number {
    return Math.ceil(value);
}

function round(value: number, precision: any): number {
    let precisionNum = `1`;
    for (let i = 1; i <= precision; i++) {
        precisionNum = `${precisionNum}0`;
    }

    return Math.round(value * Number(precisionNum)) / Number(precisionNum);
}

export default {
    date,
    time,
    ifFunc,
    sum,
    is_empty,
    floor,
    ceil,
    round,
    strtotime,
};
