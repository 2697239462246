import { ICoxSurvivalAlgorithmJson } from '@big-life-lab/pbl-calculator-engine/src/parsers/json/json-cox-survival-algorithm';
import { CalibrationJson } from '@big-life-lab/pbl-calculator-engine/src/parsers/json/json-calibration';
import { CoxSurvivalAlgorithm } from '@big-life-lab/pbl-calculator-engine/src/engine/algorithm/regression-algorithm/cox-survival-algorithm/cox-survival-algorithm';
import { ReferencePopulation } from '@big-life-lab/pbl-calculator-engine/src/engine/ref-pop/reference-population';
import {
    Data,
    findDatumWithName,
} from '@big-life-lab/pbl-calculator-engine/src/engine/data';

export class MPoRTAlgorithmSingleton {
    models: {
        male: CoxSurvivalAlgorithm;
        female: CoxSurvivalAlgorithm;
    };
    meanRefPop: {
        male: ReferencePopulation;
        female: ReferencePopulation;
    };
    healthyRefPop: {
        male: ReferencePopulation;
        female: ReferencePopulation;
    };

    constructor(
        modelJsons: {
            male: ICoxSurvivalAlgorithmJson;
            female: ICoxSurvivalAlgorithmJson;
        },
        calibrationJson: CalibrationJson,
        meanRefPopJson: {
            male: any;
            female: any;
        },
        healthyRefPopJson: {
            male: any;
            female: any;
        },
    ) {
        this.models = {
            male: new CoxSurvivalAlgorithm(
                modelJsons.male,
            ).addCalibrationToAlgorithm(calibrationJson, [
                {
                    name: 'sex',
                    coefficent: 'male',
                },
            ]),
            female: new CoxSurvivalAlgorithm(
                modelJsons.female,
            ).addCalibrationToAlgorithm(calibrationJson, [
                {
                    name: 'sex',
                    coefficent: 'female',
                },
            ]),
        };
        this.meanRefPop = meanRefPopJson;
        this.healthyRefPop = healthyRefPopJson;
    }

    getModel(data: Data): CoxSurvivalAlgorithm {
        if (findDatumWithName('sex', data).coefficent === 'male') {
            return this.models.male;
        }

        return this.models.female;
    }

    getMeanRefPop(data: Data): ReferencePopulation {
        if (findDatumWithName('sex', data).coefficent === 'male') {
            return this.meanRefPop.male;
        }

        return this.meanRefPop.female;
    }

    getHealthyRefPop(data: Data): ReferencePopulation {
        if (findDatumWithName('sex', data).coefficent === 'male') {
            return this.healthyRefPop.male;
        }

        return this.healthyRefPop.female;
    }
}
