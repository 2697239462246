import '../../../styles/choice-question-input-container.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Icon from '@material-ui/core/Icon/Icon';

export interface IChoiceInputContainerProps {
    input: React.ReactElement<any>;
    labelString: string;
    keypressValue?: string;
    isInputSelected: boolean;
    isDisabled: boolean;
    imageUrl?: string;
    hideCheckmarks?: boolean;
}

//@ts-ignore
const ChoiceInputContainer: React.SFC<IChoiceInputContainerProps> = observer(
    ({
        input,
        labelString,
        keypressValue,
        isInputSelected,
        isDisabled,
        imageUrl,
        hideCheckmarks,
    }: IChoiceInputContainerProps) => {
        return (
            <label
                className={classnames('choice-question-input-container', {
                    'choice-question-input-container--selected': isInputSelected,
                    'choice-question-input-container--disabled': isDisabled,
                    'choice-question-input-container--images': imageUrl
                        ? true
                        : false,
                    'choice-question-input-container--nocheck': hideCheckmarks,
                })}
            >
                {/* Alphabetical Keypress Label */}
                {imageUrl || hideCheckmarks ? (
                    <></>
                ) : (
                    <div className="keypress-label">{keypressValue}</div>
                )}

                {input}
                {imageUrl ? (
                    <>
                        <img
                            src={imageUrl}
                            className="choice-question-input-container__image"
                        />
                        {getLabel(labelString)}
                    </>
                ) : (
                    getLabel(labelString)
                )}
                {imageUrl || hideCheckmarks ? (
                    <></>
                ) : (
                    <div className="choice-question-input-container__check-icon-container">
                        <Icon
                            className={classnames(
                                'fas fa-check fa-lg choice-question-input-container__check-icon',
                                {
                                    'choice-question-input-container__check-icon--selected': isInputSelected,
                                },
                            )}
                        >
                            check
                        </Icon>
                    </div>
                )}
            </label>
        );
    },
);

export default ChoiceInputContainer;

function getLabel(labelString: string) {
    return (
        <span className="choice-question-input-container__label">
            {labelString}
        </span>
    );
}
