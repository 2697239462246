import * as React from 'react';
import { Provider } from 'mobx-react';
import { ProjectBigLife as DumbProjectBigLife } from '../dumb/ProjectBigLife/ProjectBigLife';
import { MPoRT } from '../../store/ui/mport';
import { observer } from 'mobx-react';

@observer
export class ProjectBigLife extends React.Component {
    render() {
        if (MPoRT.getSingleton().isConstructed === false) {
            return null;
        }

        return (
            <Provider mport={MPoRT.getSingleton()}>
                <DumbProjectBigLife
                    showShortReport={MPoRT.getSingleton().showShortReport}
                    shortReportValue={
                        MPoRT.getSingleton().formattedLifeExpectancy
                    }
                />
            </Provider>
        );
    }
}
