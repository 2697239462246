import { IGroup } from './group';
import {
    Response,
    replaceResponsesWithResponses,
    responseFactoryFromQuestion,
} from './responses/response';
import { isRelevant } from './questions/mixins/relevance-mixin';
import { QuestionType } from './questions/question-type';
import { ITextDisplayQuestion } from './questions/text-display-question';
import { Question } from './questions/question';
import { flatten } from 'lodash';

export interface ISurvey {
    surveyId: string;
    groups: IGroup[];
}

export function getRelevantGroups(
    survey: ISurvey,
    responses: Response[],
): IGroup[] {
    /* When a question is shown and then hidden the response for the hidden
    question may not be reset. Create a copy of the responses and when we find a question that is not relevant we will reset all the responses for that group*/
    let responsesForRelevance = ([] as Response[]).concat(responses.slice());

    return survey.groups.filter(group => {
        const isGroupRelevant = isRelevant(group, responsesForRelevance);

        if (!isGroupRelevant) {
            responsesForRelevance = replaceResponsesWithResponses(
                responsesForRelevance,
                group.questions
                    .filter(currentQuestion => {
                        return (
                            currentQuestion.questionType !==
                            QuestionType.TextDisplayQuestion
                        );
                    })
                    .map(currentQuestion => {
                        return responseFactoryFromQuestion(
                            currentQuestion as Exclude<
                                Question,
                                ITextDisplayQuestion
                            >,
                        );
                    }),
            );
        }

        return isGroupRelevant;
    });
}

export function getAllQuestions(survey: ISurvey): Question[] {
    return flatten(
        survey.groups.map(group => {
            return group.questions;
        }),
    );
}
