import { Data, CoxSurvivalAlgorithm } from '../model';
import {
    IUnAbridgedLifeTableRow,
    UnAbridgedLifeTable,
} from './unabridged-life-table';
import {
    LifeExpectancy,
    ICompleteLifeTableRow,
    findLifeTableRow,
} from '../life-expectancy/life-expectancy';
import { InteractionCovariate } from '../data-field/covariate/interaction-covariate/interaction-covariate';
import { NonInteractionCovariate } from '../data-field/covariate/non-interaction-covariats/non-interaction-covariate';
import { DataField } from '../data-field/data-field';
import { flatten } from 'lodash';
import { filterDataForFields } from '../data/data';
import { findDatumWithName } from '../data';
import { autobind } from 'core-decorators';
import { IValidationError } from '../../validation/validate/validation-error';
import { IRangeValidation, RuleType } from '../../validation/validation';

@autobind
export class UnAbridgedLifeExpectancy extends LifeExpectancy<
    IUnAbridgedLifeTableRow
> {
    private unnAbridgedLifeTable: UnAbridgedLifeTable;

    constructor(
        model: CoxSurvivalAlgorithm,
        unAbridgedLifeTable: UnAbridgedLifeTable,
    ) {
        super(model);

        this.model = model;
        this.unnAbridgedLifeTable = unAbridgedLifeTable;
    }

    /**
     * Calculates the life expectancy for an individual using a un-abridged
     * life table
     *
     * @param {Data} data The variables and their values for this individual
     * @returns {number}
     * @memberof UnABridgedLifeExpectancy
     */
    calculateForIndividual(
        data: Data,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              lifeExpectancy: number;
          } {
        // For an algorithm which has binning data we don't need to use the
        // life table
        const AgeDatumName = 'age';
        const ageDatum = findDatumWithName(AgeDatumName, data);

        const validatedData = this.model.validateData(data);

        const completeLifeTableCalculation = this.getCompleteUnAbridgedLifeTable(
            validatedData,
        );
        if (completeLifeTableCalculation instanceof Array) {
            return completeLifeTableCalculation;
        }
        return {
            warnings: completeLifeTableCalculation.warnings,
            lifeExpectancy: this.getLifeExpectancyForAge(
                completeLifeTableCalculation.completeLifeTable,
                ageDatum.coefficent as number,
            ),
        };
    }

    getSurvivalToAge(
        data: Data,
        toAge: number,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              survivalProbability: number;
          } {
        const individualsAge = this.getIndividualsAge(data);

        const completeLifeTableCalculation = this.getCompleteUnAbridgedLifeTable(
            data,
        );
        if (completeLifeTableCalculation instanceof Array) {
            return completeLifeTableCalculation;
        }

        const lifeTableRowForCurrentAge = findLifeTableRow(
            completeLifeTableCalculation.completeLifeTable,
            individualsAge,
        );

        const lifeTableRowForToAge = findLifeTableRow(
            completeLifeTableCalculation.completeLifeTable,
            toAge,
        );

        return {
            warnings: completeLifeTableCalculation.warnings,
            survivalProbability: this.calculateSurvivalToAge(
                lifeTableRowForCurrentAge.lx,
                lifeTableRowForToAge.lx,
            ),
        };
    }

    getSurvivalForAllAges(
        data: Data,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              survivalProbabilities: Array<{
                  age: number;
                  survivalProbability: number;
              }>;
          } {
        const individualsAge = this.getIndividualsAge(data);

        const completeLifeTableCalculation = this.getCompleteUnAbridgedLifeTable(
            data,
        );
        if (completeLifeTableCalculation instanceof Array) {
            return completeLifeTableCalculation;
        }
        const lifeTableRowForIndividualsAge = findLifeTableRow(
            completeLifeTableCalculation.completeLifeTable,
            individualsAge,
        );

        const survivalProbabilities = completeLifeTableCalculation.completeLifeTable
            .slice(
                completeLifeTableCalculation.completeLifeTable.indexOf(
                    lifeTableRowForIndividualsAge,
                ) + 1,
            )
            .map(lifeTableRow => {
                return {
                    age: lifeTableRow.age,
                    survivalProbability: this.calculateSurvivalToAge(
                        lifeTableRowForIndividualsAge.lx,
                        lifeTableRow.lx,
                    ),
                };
            });
        return {
            warnings: completeLifeTableCalculation.warnings,
            survivalProbabilities,
        };
    }

    protected getLifeTableRowForAge(
        completeLifeTable: Array<
            IUnAbridgedLifeTableRow & ICompleteLifeTableRow
        >,
        age: number,
    ) {
        return completeLifeTable.find(lifeTableRow => {
            return lifeTableRow.age === age;
        });
    }

    protected getFirstTxValue(
        lifeTable: Array<
            IUnAbridgedLifeTableRow & {
                Lx: number;
            }
        >,
    ): number {
        return lifeTable[lifeTable.length - 1].Lx;
    }

    private getCompleteUnAbridgedLifeTable(
        data: Data,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              completeLifeTable: Array<
                  ICompleteLifeTableRow & IUnAbridgedLifeTableRow
              >;
          } {
        const algorithm = this.model;

        const AgeDatumName = 'age';
        const ageDatum = findDatumWithName(AgeDatumName, data);

        const ageInteractionCovariates = algorithm.covariates.filter(
            covariate => {
                return (
                    covariate instanceof InteractionCovariate &&
                    covariate.isPartOfGroup('AGE')
                );
            },
        );
        const ageNonInteractionCovariates = algorithm.covariates.filter(
            covariate => {
                return (
                    covariate instanceof NonInteractionCovariate &&
                    covariate.isPartOfGroup('AGE')
                );
            },
        );
        const allAgeFields = DataField.getUniqueDataFields(
            flatten(
                ageNonInteractionCovariates
                    .map(covariate => {
                        return covariate.getDescendantFields();
                    })
                    .concat(ageInteractionCovariates)
                    .concat(ageNonInteractionCovariates),
            ),
        );
        const dataWithoutAgeFields = filterDataForFields(data, allAgeFields);
        /* When we go through each row of the life table and calculate ex, the only
        coefficient that changes going from one covariate to the next are the ones
        belonging to the age covariate since we increment the age value from one
        row of the life table to the next. As an optimization we precalculate the
        coefficients for all covariates that are not part of the age group and use it as the base data for
        calculating qx for each life table row*/
        const lifeTableDataWithAgeCalculation = algorithm
            .getCovariatesWithoutGroup('AGE')
            /* Goes through all non-age covariates and calculates the data
        required to calculate the coefficient for each one. Then uses the
        data to calculate the actual coefficient and finally adds it all
        to the currentData argument to be used by the next covariate */
            .reduce(
                (calculation, covariate) => {
                    const currentCoefficientDataCalculation = covariate.calculateDataToCalculateCoefficent(
                        calculation.data,
                        algorithm.userFunctions,
                        algorithm.tables,
                    );
                    if (currentCoefficientDataCalculation instanceof Array) {
                        calculation.errors.push(
                            ...currentCoefficientDataCalculation,
                        );
                    } else {
                        const covariateCoefficientCalculation = covariate.calculateCoefficient(
                            currentCoefficientDataCalculation.data,
                            algorithm.userFunctions,
                            algorithm.tables,
                        );
                        if (covariateCoefficientCalculation instanceof Array) {
                            calculation.errors.push(
                                ...covariateCoefficientCalculation,
                            );
                        } else {
                            calculation.warnings.push(
                                ...currentCoefficientDataCalculation.warnings,
                                ...covariateCoefficientCalculation.warnings,
                            );

                            const covariateDatum = {
                                name: covariate.name,
                                coefficent:
                                    covariateCoefficientCalculation.coefficient,
                            };
                            calculation.data.push(
                                ...currentCoefficientDataCalculation.data,
                                covariateDatum,
                            );
                        }
                    }

                    return calculation;
                },
                {
                    errors: [],
                    warnings: [],
                    data: dataWithoutAgeFields.concat(ageDatum),
                } as {
                    errors: IValidationError[];
                    warnings: IValidationError[];
                    data: Data;
                },
            );
        if (lifeTableDataWithAgeCalculation.errors.length > 0) {
            return lifeTableDataWithAgeCalculation.errors;
        }
        const lifeTableDataWithoutAge = filterDataForFields(
            lifeTableDataWithAgeCalculation.data,
            allAgeFields,
        );

        const unAbridgedLifeTable = this.unnAbridgedLifeTable;
        const ageMaxAllowableValue = (algorithm
            .findDataField(AgeDatumName)
            .validations.find(validation => {
                return validation.ruleType === RuleType.Range;
            }) as IRangeValidation).range.higherMargin.margin;
        // The partial life table we will
        const qxAndNx = unAbridgedLifeTable.reduce(
            (currentQxAndNx, lifeTableRow) => {
                const qxCalculation =
                    lifeTableRow.age > ageMaxAllowableValue
                        ? {
                              risk: lifeTableRow.qx,
                              warnings: [],
                          }
                        : this.getQx(
                              lifeTableDataWithoutAge.concat({
                                  name: AgeDatumName,
                                  coefficent: lifeTableRow.age,
                              }),
                          );
                if (qxCalculation instanceof Array) {
                    currentQxAndNx.errors.push(...qxCalculation);
                } else {
                    currentQxAndNx.qx.push(qxCalculation.risk);
                    currentQxAndNx.nx.push(1);
                    currentQxAndNx.warnings.push(...qxCalculation.warnings);
                }
                return currentQxAndNx;
            },
            {
                qx: [],
                nx: [],
                errors: [],
                warnings: [],
            } as {
                qx: number[];
                nx: number[];
                errors: IValidationError[];
                warnings: IValidationError[];
            },
        );
        if (qxAndNx.errors.length > 0) {
            return qxAndNx.errors;
        }
        const refLifeTableWithQxAndNx = unAbridgedLifeTable.map(
            (lifeTableRow, index) => {
                return Object.assign({}, lifeTableRow, {
                    qx: qxAndNx.qx[index],
                    nx: qxAndNx.nx[index],
                });
            },
        );
        // Get the index of the life table row after which we need to
        // stop calculating values
        const lastValidLifeTableRowIndex = unAbridgedLifeTable.findIndex(
            lifeTableRow => {
                return lifeTableRow.age > ageMaxAllowableValue;
            },
        );

        return {
            warnings: lifeTableDataWithAgeCalculation.warnings.concat(
                qxAndNx.warnings,
            ),
            completeLifeTable: super.getCompleteLifeTable(
                refLifeTableWithQxAndNx,
                unAbridgedLifeTable[lastValidLifeTableRowIndex].age,
            ),
        };
    }

    private calculateSurvivalToAge(
        lxForCurrentAge: number,
        lxForSurvivalAge: number,
    ): number {
        return lxForSurvivalAge / lxForCurrentAge;
    }

    private getIndividualsAge(individual: Data): number {
        const AgeDatumName = 'age';

        return findDatumWithName(AgeDatumName, individual).coefficent as number;
    }
}
