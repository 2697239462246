import * as React from 'react';
import { observer } from 'mobx-react';
import { SurveyInput } from './SurveyInput';
import { formatNumberValueFromEvent } from '../../utils/number-input';
import { INumberQuestion } from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    INumberResponse,
    setResponse,
    IConnectedQuestionResponse,
    isConnectedQuestionResponse,
    findResponseWithQuestionId,
    ResponseType,
    Response,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import {
    ValidationCode,
    validateResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/validation';

export interface INumberQuestionInputProps {
    question: INumberQuestion;
    response: INumberResponse | IConnectedQuestionResponse;
    responses: Response[];
    onBecomeDirty?: Function;
    focus: boolean;
    onChange: () => void;
}

// @ts-ignore
export const NumberQuestionInput: React.SFC<INumberQuestionInputProps> = observer(
    (props: INumberQuestionInputProps) => {
        const inputValue = isConnectedQuestionResponse(props.response)
            ? (findResponseWithQuestionId(
                  props.question.questionId,
                  props.response.response,
              ) as INumberResponse).response
            : props.response.response;

        return (
            <SurveyInput
                inputType="number"
                question={props.question}
                response={inputValue}
                onBecomeDirty={props.onBecomeDirty}
                focus={props.focus}
                errorText={getErrorText(props)}
                onChange={(e) => {
                    const formattedResponseValue = formatNumberValueFromEvent(
                        e,
                    );

                    if (isConnectedQuestionResponse(props.response)) {
                        setResponse(props.response, {
                            responseType: ResponseType.NumberResponse,
                            questionId: props.question.questionId,
                            response: formattedResponseValue,
                        });
                    } else {
                        setResponse(props.response, formattedResponseValue);
                    }

                    props.onChange();
                }}
            />
        );
    },
);

function getErrorText(props: INumberQuestionInputProps): string | undefined {
    const validationInfos = validateResponse(
        props.question,
        props.response,
        props.responses,
    );

    if (validationInfos === true) {
        return undefined;
    }

    const validationInfo = validationInfos[0];
    switch (validationInfo.validationType) {
        case ValidationCode.RequiredQuestionNotAnswered: {
            return `This question is mandatory`;
        }
        case ValidationCode.NumberAnswerLessThanMin: {
            return `Answer cannot be less than ${validationInfo.min}`;
        }
        case ValidationCode.NumberAnswerGreaterThanMax: {
            return `Answer cannot be more than ${validationInfo.max}`;
        }
        case ValidationCode.QuestionValidationEquation: {
            return validationInfo.validationMessage;
        }
        case ValidationCode.DateGreaterThanMax: {
            return undefined;
        }
    }
}
