import './cvd-port-risk-result.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import AppearingNextButton from '@big-life-lab/pbl-survey-components/src/components/AppearingNextButton/AppearingNextButton';
import { HotKeys } from 'react-hotkeys';

export interface ICVDPoRTRiskResultProps {
    risk: number;
    nextQuestion: Function;
    focus: boolean;
}

@observer
export class CVDPoRTRiskResult extends React.Component<
    ICVDPoRTRiskResultProps
> {
    divRef!: HTMLDivElement;

    handlers = {
        nextQuestion: () => {
            this.props.nextQuestion();
        },
    };

    componentDidUpdate(prevProps: ICVDPoRTRiskResultProps, _prevState: {}) {
        //console.log('CDU');
        // console.log(`CDU ${prevProps.focus} - ${this.props.focus}`);
        if (!prevProps.focus && this.props.focus) {
            // Timer allows the question-wrapper's scrollIntoView call time to happen
            // before calling focus on this field intercepts the scroll
            // preventScroll option has limited browser compatibility
            setTimeout(() => {
                // @ts-ignore
                this.divRef.focus({ preventScroll: true });
            }, 100);
        }
    }

    render() {
        const keyMap = {
            nextQuestion: 'return',
        };

        const { risk } = this.props;

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={this.handlers}
                focused={this.props.focus}
            >
                <div
                    ref={c => (this.divRef = c as HTMLDivElement)}
                    tabIndex={5}
                    id="cvd-port-risk-result"
                    className="cvd-port-risk-result"
                >
                    <h3>
                        Your risk of having a Heart Attack or Stroke within the
                        next 5 years is
                    </h3>
                    <div className="life-expectancy-value">
                        {risk} <span className="years-label">%</span>
                    </div>

                    <AppearingNextButton onClick={this.props.nextQuestion} />
                </div>
            </HotKeys>
        );
    }
}
