import { DataField } from '../data-field';
import { flatten } from 'lodash';
import { Data, Coefficent } from '../../data';
import { autobind } from 'core-decorators';
import { Covariate } from '../covariate/covariate';
import { throwErrorIfUndefined } from '../../../util/undefined';
import { NoTableRowFoundError } from '../../errors';
import PmmlFunctions from './pmml-functions';
import { IDerivedFieldJson } from '../../../parsers/json/json-derived-field';
import { IUserFunctions } from '../../algorithm/user-functions/user-functions';
import { ITables } from '../../algorithm/tables/tables';
import { datumFactoryFromDataField, IDatum } from '../../data/datum';
import { debugRisk } from '../../../debug/debug-risk';
import { IValidationError } from '../../../validation/validate/validation-error';

// tslint:disable-next-line:only-arrow-functions
const getValueFromTable = function(
    table: Array<{ [index: string]: string }>,
    outputColumn: string,
    conditions: { [index: string]: string },
): string {
    const conditionTableColumns = Object.keys(conditions);

    return throwErrorIfUndefined(
        table.find(row => {
            const unMatchedColumn = conditionTableColumns.find(
                conditionColumn => {
                    // tslint:disable-next-line
                    return row[conditionColumn] != conditions[conditionColumn];
                },
            );

            return unMatchedColumn === undefined ? true : false;
        }),
        new NoTableRowFoundError(conditions),
    )[outputColumn];
};

export function getLeafFieldsForDerivedField(
    derivedField: DerivedField,
): DataField[] {
    if (derivedField.derivedFrom.length === 0) {
        return [derivedField];
    } else {
        return flatten(
            derivedField.derivedFrom.map(derivedFromItem => {
                if (derivedFromItem instanceof DerivedField) {
                    return getLeafFieldsForDerivedField(derivedFromItem);
                } else if (derivedFromItem instanceof Covariate) {
                    if (derivedFromItem.derivedField) {
                        return getLeafFieldsForDerivedField(
                            derivedFromItem.derivedField,
                        );
                    } else {
                        return derivedFromItem;
                    }
                } else {
                    return derivedFromItem;
                }
            }),
        );
    }
}

@autobind
export class DerivedField extends DataField {
    equation: string;
    derivedFrom: DataField[];

    constructor(derivedFieldJson: IDerivedFieldJson, derivedFrom: DataField[]) {
        super(derivedFieldJson);

        this.name = derivedFieldJson.name;
        this.equation = derivedFieldJson.equation;
        this.derivedFrom = derivedFrom;
    }

    evaluateEquation(
        obj: {
            [index: string]: any;
        },
        userFunctions: IUserFunctions,
        tables: ITables,
    ): any {
        // tslint:disable-next-line
        obj;
        // tslint:disable-next-line
        userFunctions;
        // tslint:disable-next-line
        tables;

        // tslint:disable-next-line
        let derived: any = undefined;
        // tslint:disable-next-line
        let func: { [index: string]: Function } = PmmlFunctions;
        // tslint:disable-next-line
        func;
        func['getValueFromTable'] = getValueFromTable;

        eval(this.equation);

        return derived;
    }

    calculateCoefficient(
        data: Data,
        userDefinedFunctions: IUserFunctions,
        tables: ITables,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              coefficient: Coefficent;
          } {
        let coefficient: Coefficent;
        let warnings: IValidationError[] = [];

        /*Check if there is a datum for this intermediate predictor. If there is then we don't need to go further*/
        const datumForCurrentDerivedField = this.getDatumForField(data);
        if (datumForCurrentDerivedField) {
            coefficient = datumForCurrentDerivedField.coefficent;
        } else {
            const dataForEvaluationCalculation = this.calculateDataToCalculateCoefficent(
                data,
                userDefinedFunctions,
                tables,
            );
            if (dataForEvaluationCalculation instanceof Array) {
                return dataForEvaluationCalculation;
            }

            warnings = dataForEvaluationCalculation.warnings;

            /*make the object with the all the data needed for the equation evaluation*/
            const obj: {
                [index: string]: any;
            } = {};
            dataForEvaluationCalculation.data.forEach(datum => {
                obj[datum.name] = datum.coefficent;
            });

            let evaluatedValue = this.evaluateEquation(
                obj,
                userDefinedFunctions,
                tables,
            );

            const evaluatedValueValidation = this.validate(evaluatedValue);
            if (evaluatedValueValidation.errors.length > 0) {
                return evaluatedValueValidation.errors;
            }
            warnings.push(...evaluatedValueValidation.warnings);
            evaluatedValue = this.formatCoefficient(
                evaluatedValue,
                evaluatedValueValidation.warnings,
            );

            // null or an empty string are coerced to 0 when passed through the Number function in the next statement. This statement will reset them to undefined so that any further manipulations in upstream fields will always return undefined.
            // To handle cases where the value is '1', '2' etc.
            if (
                typeof evaluatedValue === 'string' &&
                isNaN(Number(evaluatedValue)) === false &&
                evaluatedValue !== null &&
                evaluatedValue !== undefined
            ) {
                coefficient = Number(evaluatedValue);
            } else {
                coefficient = evaluatedValue;
            }
        }

        debugRisk.addFieldDebugInfo(this.name, coefficient);

        return {
            warnings,
            coefficient,
        };
    }

    calculateDataToCalculateCoefficent(
        data: Data,
        userDefinedFunctions: IUserFunctions,
        tables: ITables,
    ):
        | IValidationError[]
        | {
              warnings: IValidationError[];
              data: Data;
          } {
        const dataCalculation = this.derivedFrom.reduce(
            (currentDataCalculation, derivedFromItem) => {
                const datumFound = derivedFromItem.getDatumForField(data);
                if (datumFound) {
                    const validation = derivedFromItem.validate(
                        datumFound.coefficent,
                    );
                    if (validation.errors.length > 0) {
                        return Object.assign(currentDataCalculation, {
                            errors: currentDataCalculation.errors.concat(
                                validation.errors,
                            ),
                        });
                    }
                    return Object.assign(currentDataCalculation, {
                        data: currentDataCalculation.data.concat([datumFound]),
                        warnings: currentDataCalculation.warnings.concat(
                            validation.warnings,
                        ),
                    });
                } else if (derivedFromItem instanceof Covariate) {
                    const derivedFromItemCalculation = derivedFromItem.calculateCoefficient(
                        data,
                        userDefinedFunctions,
                        tables,
                    );
                    if (derivedFromItemCalculation instanceof Array) {
                        return Object.assign(currentDataCalculation, {
                            errors: currentDataCalculation.errors.concat(
                                derivedFromItemCalculation,
                            ),
                        });
                    }
                    return Object.assign(currentDataCalculation, {
                        warnings: currentDataCalculation.warnings.concat(
                            derivedFromItemCalculation.warnings,
                        ),
                        data: currentDataCalculation.data.concat(
                            datumFactoryFromDataField(
                                derivedFromItem,
                                derivedFromItemCalculation.coefficient,
                            ),
                        ),
                    });
                } else if (derivedFromItem instanceof DerivedField) {
                    const derivedFromItemCalculation = derivedFromItem.calculateCoefficient(
                        data,
                        userDefinedFunctions,
                        tables,
                    );
                    if (derivedFromItemCalculation instanceof Array) {
                        return Object.assign(currentDataCalculation, {
                            errors: currentDataCalculation.errors.concat(
                                derivedFromItemCalculation,
                            ),
                        });
                    }
                    return Object.assign(currentDataCalculation, {
                        warnings: currentDataCalculation.warnings.concat(
                            derivedFromItemCalculation.warnings,
                        ),
                        data: currentDataCalculation.data.concat(
                            datumFactoryFromDataField(
                                derivedFromItem,
                                derivedFromItemCalculation.coefficient,
                            ),
                        ),
                    });
                } else {
                    return Object.assign(currentDataCalculation, {
                        data: currentDataCalculation.data.concat({
                            name: derivedFromItem.name,
                            coefficent: undefined,
                        }),
                    });
                }
            },
            {
                errors: [],
                warnings: [],
                data: [],
            } as {
                errors: IValidationError[];
                warnings: IValidationError[];
                data: IDatum[];
            },
        );
        if (dataCalculation.errors.length > 0) {
            return dataCalculation.errors;
        }
        return {
            warnings: dataCalculation.warnings,
            data: dataCalculation.data,
        };
    }

    getDescendantFields(): DataField[] {
        return DataField.getUniqueDataFields(
            flatten(
                this.derivedFrom.map(derivedFromItem => {
                    if (derivedFromItem instanceof Covariate) {
                        if (derivedFromItem.derivedField) {
                            return derivedFromItem.derivedField
                                .getDescendantFields()
                                .concat(derivedFromItem);
                        } else {
                            return derivedFromItem;
                        }
                    } else if (derivedFromItem instanceof DerivedField) {
                        return derivedFromItem
                            .getDescendantFields()
                            .concat(derivedFromItem);
                    } else {
                        return derivedFromItem;
                    }
                }),
            ),
        );
    }
}
