import '../../../styles/connected-question.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { SubQuestionNumberInput } from '../SubQuestionNumberInput/SubQuestionNumberInput';
import { NumberQuestionInput } from '../Inputs/NumberQuestionInput';
import { Question, QuestionProps } from '../Question/Question';
import { Omit } from 'utility-types';
import {
    IConnectedQuestions,
    IMultipleNumberQuestion,
    INumberQuestion,
    QuestionType,
    getFilteredSubQuestions,
    getText,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    Response,
    findResponseWithQuestionId,
    ArrayResponse,
    IArrayNumberResponse,
    findSubQuestionResponse,
    IConnectedQuestionResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';

export interface IConnectedQuestionProps
    extends Omit<QuestionProps<true>, 'rootClassName' | 'showContinueButton'> {
    question: IConnectedQuestions;
    responses: Response[];
}

@observer
export class ConnectedQuestions extends React.Component<
    IConnectedQuestionProps
> {
    render() {
        let { question, responses } = this.props;
        const questionResponse = findResponseWithQuestionId(
            question.questionId,
            responses,
        ) as IConnectedQuestionResponse;

        return (
            <Question
                {...this.props}
                rootClassName="connected-question"
                showContinueButton={true}
            >
                <div className="connected-questions__questions-and-or-container">
                    {renderConnectedQuestion(
                        this.props,
                        question.questions[0],
                        questionResponse,
                        this.props.focus === true,
                    )}
                    <div className="connected-question__or-text">or</div>
                    {renderConnectedQuestion(
                        this.props,
                        question.questions[1],
                        questionResponse,
                        false,
                    )}
                </div>
            </Question>
        );
    }
}

function renderConnectedQuestion(
    props: IConnectedQuestionProps,
    connectedQuestion: INumberQuestion | IMultipleNumberQuestion,
    connectedQuestionResponse: IConnectedQuestionResponse,
    focus: boolean,
) {
    const responseForCurrentQuestion = findResponseWithQuestionId(
        connectedQuestion.questionId,
        connectedQuestionResponse.response,
    );

    return connectedQuestion.questionType === QuestionType.NumberQuestion ? (
        <NumberQuestionInput
            question={connectedQuestion}
            response={connectedQuestionResponse}
            responses={props.responses}
            focus={focus}
            onChange={props.onChange}
        />
    ) : (
        <div>
            {getFilteredSubQuestions(
                connectedQuestion,
                props.responses as ArrayResponse[],
            ).map((subQuestion, i) => {
                return (
                    <SubQuestionNumberInput
                        key={i}
                        question={connectedQuestion}
                        subQuestion={subQuestion}
                        inputLabel={getText(subQuestion, props.responses)}
                        subQuestionResponse={findSubQuestionResponse(
                            responseForCurrentQuestion as IArrayNumberResponse,
                            subQuestion.subQuestionId,
                        )}
                        onChange={props.onChange}
                        response={connectedQuestionResponse}
                        suffixText={getText(subQuestion, props.responses)}
                        focus={focus === true && i === 0}
                    />
                );
            })}
        </div>
    );
}
