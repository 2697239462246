import './life-expectancy-result.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import AppearingNextButton from '@big-life-lab/pbl-survey-components/src/components/AppearingNextButton/AppearingNextButton';
import { HotKeys } from 'react-hotkeys';
import classnames from 'classnames';
import { isEmbedded } from '@ottawamhealth/pbl-utils/lib/browser';

// TODO: Wire this up
export interface ILifeExpectancyResultProps {
    nextQuestion: Function;
    focus: boolean;
    currentAge: number;
    lifeExpectancy: number;
    meanHealthAge: number;
}

@observer
export class LifeExpectancyResult extends React.Component<
    ILifeExpectancyResultProps
> {
    divRef!: HTMLDivElement;

    handlers = {
        nextQuestion: () => this.props.nextQuestion(),
    };

    componentDidUpdate(prevProps: ILifeExpectancyResultProps, _prevState: {}) {
        //console.log('CDU');
        // console.log(`CDU ${prevProps.focus} - ${this.props.focus}`);
        if (!prevProps.focus && this.props.focus) {
            // Timer allows the question-wrapper's scrollIntoView call time to happen
            // before calling focus on this field intercepts the scroll
            // preventScroll option has limited browser compatibility
            setTimeout(() => {
                //@ts-ignore
                this.divRef.focus({ preventScroll: true });
            }, 100);
        }
    }

    render() {
        const { lifeExpectancy } = this.props;

        const keyMap = {
            nextQuestion: 'return',
        };

        return (
            <HotKeys
                keyMap={keyMap}
                handlers={this.handlers}
                focused={this.props.focus}
            >
                <div
                    ref={c => (this.divRef = c as HTMLDivElement)}
                    tabIndex={5}
                    id="mport-life-expectancy"
                    className={classnames('life-expectancy-result', {
                        'result--embedded': isEmbedded(),
                    })}
                >
                    <h3>
                        Life expectancy for people who answered the same
                        questions is
                    </h3>
                    <div className="life-expectancy-value">
                        {lifeExpectancy}{' '}
                        <span className="years-label">YEARS</span>
                    </div>

                    {isEmbedded() === false ? (
                        <AppearingNextButton
                            onClick={this.props.nextQuestion}
                        />
                    ) : (
                        <div className="buttom-prompt button-prompt--embedded">
                            Scroll for the next report
                        </div>
                    )}
                </div>
            </HotKeys>
        );
    }
}
