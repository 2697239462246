import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Survey } from '@big-life-lab/pbl-survey-components/src';
import { MPoRT } from '../../store/ui/mport';
import { LifeExpectancyResult } from './LifeExpectancyResult';
import { WillYouLiveToSeeIt } from './WillYouLiveToSeeIt';
import { HealthAge } from './HealthAge';
import { LifeYearsLostResult } from './LifeYearsLostResult';

export interface IMPoRTSurveyProps {
    mport?: MPoRT;
}

@inject('mport')
@observer
export class MPoRTSurvey extends React.Component<IMPoRTSurveyProps> {
    render() {
        const { survey, responses, surveyConfig } = this.props.mport!.survey;

        return (
            <Survey
                survey={survey}
                responses={responses}
                resultsComponents={[
                    {
                        id: 'mport-life-expectancy',
                        component: LifeExpectancyResult,
                    },
                    {
                        id: 'mport-live-to-see-it',
                        component: WillYouLiveToSeeIt,
                    },
                    {
                        id: 'mport-life-years-lost',
                        component: LifeYearsLostResult,
                    },
                    {
                        id: 'mport-health-age',
                        component: HealthAge,
                    },
                ]}
                surveyConfig={surveyConfig}
                onInputChange={() => {}}
            />
        );
    }
}
