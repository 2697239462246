import {
    ArrayResponse,
    filterArrayResponses,
    findSubQuestionResponse,
    setResponse as setArrayResponse,
    isArrayResponse,
    hasResponse as hasArrayResponse,
} from './array-responses/array-response';
import {
    SingleResponse,
    setResponse as setSingleResponse,
    isSingleResponse,
    hasResponse as hasSingleResponse,
} from './single-responses/single-response';
import { ISurvey, getRelevantGroups, getAllQuestions } from '../survey';
import { flatten } from 'lodash';
import {
    isQuestionRelevant,
    ResponseQuestion,
    Question,
    isResponseQuestion,
} from '../questions/question';
import { QuestionType } from '../questions/question-type';
import { IFlexibleRowArrayQuestion } from '../questions/flexible-row-array-question';
import { IArrayAnswerOptionResponse } from './array-responses/array-answer-option-response';
import { getFilteredSubQuestions } from '../questions/mixins/sub-question-mixin';
import {
    IConnectedQuestionResponse,
    setResponse as setConnectedQuestionResponse,
    isConnectedQuestionResponse,
    hasResponse as hasConnectedQuestionResponse,
} from './connected-question-response';
import { ITextDisplayQuestion } from '../questions/text-display-question';
import { ResponseType } from './response-type';

export type Response =
    | ArrayResponse
    | SingleResponse
    | IConnectedQuestionResponse;

export function findResponseWithQuestionId(
    questionId: string,
    responses: Response[],
) {
    let foundResponse: Response | undefined;
    for (const currentResponse of responses) {
        if (currentResponse.questionId === questionId) {
            foundResponse = currentResponse;
            break;
        }

        if (isConnectedQuestionResponse(currentResponse)) {
            try {
                foundResponse = findResponseWithQuestionId(
                    questionId,
                    currentResponse.response,
                );
                break;
                // tslint:disable-next-line: no-empty
            } catch (err) {}
        }
    }

    if (foundResponse === undefined) {
        throw new Error(`No response found for question id ${questionId}`);
    }

    return foundResponse;
}

export function hasUserGivenResponse<T extends Response>(
    response: T,
): response is T & {
    response: NonNullable<T['response']>;
} {
    if (isSingleResponse(response)) {
        return response.response !== undefined;
    } else if (
        isArrayResponse(response) ||
        isConnectedQuestionResponse(response)
    ) {
        return response.response.length !== 0;
    } else {
        return false;
    }
}

export function replaceResponsesWithResponses(
    responsesOne: Response[],
    responsesTwo: Response[],
): Response[] {
    return responsesOne
        .filter(currentResponseOne => {
            try {
                findResponseWithQuestionId(
                    currentResponseOne.questionId,
                    responsesTwo,
                );
                return false;
            } catch (err) {
                return true;
            }
        })
        .concat(responsesTwo);
}

/* Returns a new array of responses where the ones tied to not relevant questions are reset as if the question is not answered */
export function resetNotRelevantResponses(
    responses: Response[],
    survey: ISurvey,
): Response[] {
    const relevantQuestions = flatten(
        getRelevantGroups(survey, responses).map(currentGroup => {
            return currentGroup.questions.filter(question => {
                return isQuestionRelevant(question, responses);
            });
        }),
    );
    const allQuestions = flatten(
        survey.groups.map(currentGroup => {
            return currentGroup.questions;
        }),
    );

    const responsesTwoOne = allQuestions
        .filter(currentQuestion => {
            return relevantQuestions.find(currentRelevantQuestion => {
                return (
                    currentQuestion.questionId ===
                    currentRelevantQuestion.questionId
                );
            })
                ? false
                : true;
        })
        .filter(currentQuestion => {
            return (
                currentQuestion.questionType !==
                QuestionType.TextDisplayQuestion
            );
        })
        .map(currentNotRelevantQuestion => {
            return responseFactoryFromQuestion(
                currentNotRelevantQuestion as Exclude<
                    Question,
                    ITextDisplayQuestion
                >,
            );
        });
    // To the above array of responses add the replacement responses for flexible row array questions. This is to ensure that we reset the sub questions responses which are not relevant
    const responsesTwoTwo = (relevantQuestions.filter(relevantQuestion => {
        return (
            relevantQuestion.questionType ===
            QuestionType.FlexibleRowArrayQuestion
        );
    }) as IFlexibleRowArrayQuestion[]).map(
        (relevantFlexibleRowArrayQuestion: IFlexibleRowArrayQuestion) => {
            // Get the original response for this question
            const responseOne = findResponseWithQuestionId(
                relevantFlexibleRowArrayQuestion.questionId,
                responses,
            ) as IArrayAnswerOptionResponse;
            // Make the response object which we will be replacing in the original
            const responseTwo = responseFactoryFromQuestion(
                relevantFlexibleRowArrayQuestion,
            ) as IArrayAnswerOptionResponse;
            // Get the all the sub question ids which are relevant
            const relevantSubQuestions = getFilteredSubQuestions(
                relevantFlexibleRowArrayQuestion,
                filterArrayResponses(responses),
            ).map(relevantSubQuestion => {
                return relevantSubQuestion.subQuestionId;
            });

            if (hasUserGivenResponse(responseTwo)) {
                // For each sub questions response in the replacement response
                responseTwo.response.forEach(subQuestionResponse => {
                    // If it's not relevant then set it to null
                    if (
                        relevantSubQuestions.indexOf(
                            subQuestionResponse.subQuestionId,
                        ) === -1
                    ) {
                        subQuestionResponse.response = undefined;
                    } else {
                        // Otherwise set it to the original response
                        subQuestionResponse.response = findSubQuestionResponse(
                            responseOne,
                            subQuestionResponse.subQuestionId,
                        ).response;
                    }
                });
            }

            return responseTwo;
        },
    );

    return replaceResponsesWithResponses(
        responses,
        responsesTwoOne.concat(responsesTwoTwo),
    );
}

export function responseFactoryFromQuestion(
    question: ResponseQuestion,
): Response {
    switch (question.questionType) {
        case QuestionType.TextQuestion:
        case QuestionType.ShortTextQuestion: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.TextResponse,
                response: undefined,
            };
        }
        case QuestionType.ConnectedQuestions: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.ConnectedQuestionResponse,
                responseTransforms: question.equationTransforms,
                response: question.questions.map(connectedQuestion => {
                    return responseFactoryFromQuestion(connectedQuestion);
                }) as IConnectedQuestionResponse['response'],
            };
        }
        case QuestionType.DateQuestion: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.DateResponse,
                response: undefined,
            };
        }
        case QuestionType.FlexibleRowArrayQuestion: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.ArrayAnswerOptionResponse,
                response: question.subQuestions.map(subQuestion => {
                    return {
                        subQuestionId: subQuestion.subQuestionId,
                        response: undefined,
                    };
                }),
            };
        }
        case QuestionType.MultipleChoiceQuestion: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.ArrayBooleanResponse,
                response: question.subQuestions.map(subQuestion => {
                    return {
                        subQuestionId: subQuestion.subQuestionId,
                        response: undefined,
                    };
                }),
            };
        }
        case QuestionType.MultipleNumberQuestion: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.ArrayNumberResponse,
                response: question.subQuestions.map(subQuestion => {
                    return {
                        subQuestionId: subQuestion.subQuestionId,
                        response: undefined,
                    };
                }),
            };
        }
        case QuestionType.NumberQuestion: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.NumberResponse,
                response: undefined,
            };
        }
        case QuestionType.RadioQuestion: {
            return {
                questionId: question.questionId,
                responseType: ResponseType.AnswerOptionResponse,
                response: undefined,
            };
        }
    }
}

export function responseFactoryFromSurvey(survey: ISurvey): Response[] {
    return getAllQuestions(survey)
        .filter((question): question is ResponseQuestion => {
            return isResponseQuestion(question);
        })
        .map(responseQuestion => {
            return responseFactoryFromQuestion(responseQuestion);
        });
}

type SetResponse = typeof setSingleResponse &
    typeof setArrayResponse &
    typeof setConnectedQuestionResponse;
export const setResponse: SetResponse = function(
    response: Response,
    responseVal: any,
): void {
    if (isSingleResponse(response)) {
        setSingleResponse(response as any, responseVal);
    } else if (isArrayResponse(response)) {
        setArrayResponse(response as any, responseVal);
    } else {
        setConnectedQuestionResponse(response, responseVal);
    }
};

type HasResponse = typeof hasSingleResponse &
    typeof hasArrayResponse &
    typeof hasConnectedQuestionResponse;
export const hasResponse: HasResponse = function(
    response: Response,
    responseVal: any,
): boolean {
    if (isSingleResponse(response)) {
        return hasSingleResponse(response, responseVal);
    } else if (isArrayResponse(response)) {
        return hasArrayResponse(response, responseVal);
    } else {
        return hasConnectedQuestionResponse(response, responseVal);
    }
};
