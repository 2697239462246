import '../../../styles/question-helper.scss';

import React from 'react';
import { computed } from 'mobx';
import { Tooltip, ClickAwayListener } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { observer } from 'mobx-react';
import { autobind } from 'core-decorators';
import { c } from '../../constants';

/**
 *
 *
 * @export
 * @interface IQuestionHelperProps
 */
export interface IQuestionHelperProps {
    questionText: string;
}

interface IQuestionHelperState {
    isTouchHelperOpen: boolean;
}

@observer
@autobind
export class QuestionHelper extends React.Component<
    IQuestionHelperProps,
    IQuestionHelperState
> {
    constructor(props: IQuestionHelperProps) {
        super(props);

        this.state = {
            isTouchHelperOpen: false,
        };
    }

    render() {
        const { questionHelperText } = this;
        if (!questionHelperText) return null;

        return (
            <ClickAwayListener onClickAway={this.closeHelperMessage}>
                <div className="question-helper-container">
                    <Tooltip
                        onClick={this.openHelperMessage}
                        onMouseEnter={this.openHelperMessage}
                        onMouseLeave={this.closeHelperMessage}
                        open={this.state.isTouchHelperOpen}
                        disableHoverListener
                        title={
                            <div
                                className="question-help"
                                dangerouslySetInnerHTML={{
                                    __html: questionHelperText,
                                }}
                            ></div>
                        }
                    >
                        <HelpIcon />
                    </Tooltip>
                </div>
            </ClickAwayListener>
        );
    }

    private openHelperMessage() {
        this.setState({
            isTouchHelperOpen: true,
        });
    }

    private closeHelperMessage() {
        this.setState({
            isTouchHelperOpen: false,
        });
    }

    @computed
    private get questionHelperText() {
        const questionTextElement = document.createElement('div');
        questionTextElement.innerHTML = this.props.questionText;

        const titleElement = questionTextElement.querySelector(
            c.questionPopoverTextSelector,
        );
        if (titleElement) return titleElement.outerHTML;
        return '';
    }
}
