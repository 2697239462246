import { Data, IDatum } from '../data';
import { autobind } from 'core-decorators';
import { uniqWith } from 'lodash';
import { Interval } from './interval/interval';
import { IDataFieldJson } from '../../parsers/json/json-data-field';
import { ICategory } from './category';
import { IMetadata } from './metadata';
import { Coefficent } from '../data/coefficent';
import {
    ErrorHandle,
    IRangeValidation,
    ValidationTypes,
} from '../../validation/validation';
import { IValidationError } from '../../validation/validate/validation-error';
import { validate } from '../../validation/validate/validate';

@autobind
export class DataField {
    static getUniqueDataFields(dataFields: DataField[]): DataField[] {
        return uniqWith(dataFields, DataField.isSameDataField);
    }

    static isSameDataField(dataFieldOne: DataField, dataFieldTwo: DataField) {
        return dataFieldOne.name === dataFieldTwo.name;
    }

    static findDataFieldWithName(
        dataFields: DataField[],
        name: string,
    ): DataField | undefined {
        return dataFields.find(dataField => {
            return dataField.name === name;
        });
    }

    name: string;
    intervals?: Interval[];
    /**
     * If the DataField is a categorical field, then this field will be set.
     * Otherwise it will be undefined.
     *
     * @type {ICategory[]}
     * @memberof DataField
     */
    categories?: ICategory[];
    isRequired: boolean;
    isRecommended: boolean;
    metadata: IMetadata;
    validations: ValidationTypes[];

    constructor(fieldJson: IDataFieldJson) {
        this.name = fieldJson.name;
        this.intervals = fieldJson.intervals
            ? fieldJson.intervals.map(interval => {
                  return new Interval(interval);
              })
            : undefined;
        this.categories = fieldJson.categories;
        this.isRequired = fieldJson.isRequired;
        this.metadata = fieldJson.metadata;
        this.isRecommended = fieldJson.isRecommended;
        this.validations = fieldJson.validations;
    }

    getDatumForField(data: Data): IDatum | undefined {
        return data.find(datum => datum.name === this.name);
    }

    isFieldWithName(name: string): boolean {
        return this.name === name;
    }

    /**
     * Validates the Datum identical to this DataField in the data arg using
     * the interval and categories fields if present
     *
     * @param {Data[]} data Data to validate in the context of this DataField
     * @returns {(ErrorCode[] | true)} If validation failed, then error codes
     * representing all the validation errors is returned
     * @memberof DataField
     */
    validate(
        value: any,
    ): {
        errors: IValidationError[];
        warnings: IValidationError[];
    } {
        return this.validations.reduce(
            (errorsAndWarnings, currentValidation) => {
                const validation = validate(currentValidation, value);

                if (validation !== true) {
                    switch (currentValidation.errorHandle) {
                        case ErrorHandle.Error: {
                            errorsAndWarnings.errors.push(validation);
                            break;
                        }
                        case ErrorHandle.Warning: {
                            errorsAndWarnings.warnings.push(validation);
                            break;
                        }
                    }
                }

                return errorsAndWarnings;
            },
            {
                errors: [],
                warnings: [],
            } as {
                errors: IValidationError[];
                warnings: IValidationError[];
            },
        );
    }

    formatCoefficient(
        coefficient: Coefficent,
        validationWarnings: IValidationError[],
    ): Coefficent {
        const foundWarning = validationWarnings.find(warning => {
            if (warning.validationType.errorHandle === ErrorHandle.Truncate) {
                return true;
            }

            if (
                warning.validationType.errorReplace !== null ||
                warning.validationType.errorReplace !== undefined
            ) {
                return true;
            }

            return false;
        });

        if (!foundWarning) {
            return coefficient;
        }

        if (foundWarning.validationType.errorHandle === ErrorHandle.Truncate) {
            return new Interval(
                (foundWarning.validationType as IRangeValidation).range as any,
            ).limitNumber(Number(coefficient));
        }

        return foundWarning.validationType.errorReplace;
    }
}
