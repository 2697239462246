import { ITextResponse } from './text-response';
import { INumberResponse } from './number-response';
import { IAnswerOptionResponse } from './answer-option-response';
import { IDateResponse } from './date-response';
import { Response } from '../response';
import { ResponseType } from '../response-type';
import moment from 'moment';

export type SingleResponse =
    | ITextResponse
    | INumberResponse
    | IAnswerOptionResponse
    | IDateResponse;

type GetSingleResponseType<T extends SingleResponse> = T extends
    | ITextResponse
    | IAnswerOptionResponse
    ? string | undefined
    : T extends INumberResponse
    ? number | undefined
    : T extends IDateResponse
    ? moment.Moment | undefined
    : never;

export function setResponse<T extends SingleResponse>(
    response: T,
    responseVal: GetSingleResponseType<T>,
) {
    response.response = responseVal;
}

export function hasResponse<T extends SingleResponse>(
    response: T,
    responseVal: GetSingleResponseType<T>,
) {
    return response.response === responseVal;
}

export function isSingleResponse(
    response: Response,
): response is SingleResponse {
    switch (response.responseType) {
        case ResponseType.TextResponse:
        case ResponseType.NumberResponse:
        case ResponseType.DateResponse:
        case ResponseType.AnswerOptionResponse: {
            return true;
        }
        default: {
            return false;
        }
    }
}
