import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { HealthAge as DumbHealthAge } from '../dumb/HealthAge/HealthAge';
import { MPoRT } from '../../store/ui/mport';
import { ReferenceSelected } from '../../store/ui/health-age';

export interface IHealthAgeProps {
    nextQuestion: Function;
    focus: boolean;
    mport?: MPoRT;
}

@inject('mport')
@observer
export class HealthAge extends React.Component<IHealthAgeProps> {
    render() {
        const {
            meanHealthAge,
            healthyHealthAge,
            currentAge,
            healthAge,
            currentSex,
        } = this.props.mport!;

        return (
            <DumbHealthAge
                lowerAge={0}
                upperAge={110}
                healthAge={
                    healthAge.referenceSelected === ReferenceSelected.Healthy
                        ? healthyHealthAge
                        : meanHealthAge
                }
                currentAge={currentAge}
                nextQuestion={this.props.nextQuestion}
                focus={this.props.focus}
                referenceSelected={
                    healthAge.referenceSelected === ReferenceSelected.Healthy
                        ? 'healthy'
                        : 'mean'
                }
                toggleReference={healthAge.toggleReference}
                sex={currentSex}
            />
        );
    }
}
