import { IRadioQuestion } from './radio-question';
import { INumberQuestion } from './number-question';
import { ITextQuestion } from './text-question';
import { IConnectedQuestions } from './connected-questions';
import { IDateQuestion } from './date-question';
import { IFlexibleRowArrayQuestion } from './flexible-row-array-question';
import { IMultipleChoiceQuestion } from './multiple-choice-question';
import { IMultipleNumberQuestion } from './multiple-number-question';
import { ITextDisplayQuestion } from './text-display-question';
import { QuestionType } from './question-type';
import { isRelevant } from './mixins/relevance-mixin';
import { Response } from '../responses/response';
import { getText } from './mixins/formatted-text-mixin';
import { IShortTextQuestion } from './short-text-question';

export type ResponseQuestion =
    | IConnectedQuestions
    | IDateQuestion
    | IFlexibleRowArrayQuestion
    | IMultipleChoiceQuestion
    | IMultipleNumberQuestion
    | INumberQuestion
    | IRadioQuestion
    | ITextQuestion
    | IShortTextQuestion;

export type Question = ResponseQuestion | ITextDisplayQuestion;

export function isResponseQuestion(
    question: Question,
): question is ResponseQuestion {
    return question.questionType !== QuestionType.TextDisplayQuestion;
}

export function isQuestionRelevant(
    question: Question,
    responses: Response[],
): boolean {
    switch (question.questionType) {
        case QuestionType.ConnectedQuestions: {
            return isRelevant(question.questions[0], responses);
        }
        default: {
            return isRelevant(question, responses);
        }
    }
}

export function getQuestionText(
    question: Question,
    responses: Response[],
): string {
    switch (question.questionType) {
        case QuestionType.ConnectedQuestions: {
            return getText(question.questions[0], responses);
        }
        default: {
            return getText(question, responses);
        }
    }
}

export function isConnectedQuestion(
    question: Question,
): question is IConnectedQuestions {
    return question.questionType === QuestionType.ConnectedQuestions;
}
