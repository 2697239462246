import { gaTagWebflow } from '@big-life-lab/webflow-ga/lib/ga-tag-webflow';

//@ts-ignore
if (!window.gtag) {
    //@ts-ignore
    window.gtag = function() {};
}
const GaJson = require('../../../assets/ga/ga.json');
fetch(GaJson.default)
    .then(response => {
        return response.json();
    })
    .then(GaJson => {
        gaTagWebflow(GaJson);
    });

const wyltsiContinueButton = document.getElementById('le-wyltsi-continue');
if (wyltsiContinueButton) {
    wyltsiContinueButton.addEventListener('click', function() {
        const wyltsiTextInput = document.getElementById(
            'name',
        ) as HTMLInputElement;
        const wyltsiYearButton = document.getElementById(
            'name-2',
        ) as HTMLInputElement;

        // @ts-ignore
        gtag('event', 'input', {
            event_category: 'WYLTSI text',
            event_label: wyltsiTextInput.value,
        });
        // @ts-ignore
        gtag('event', 'input', {
            event_category: 'WYLTSI year',
            event_label: wyltsiYearButton.value,
        });
    });
}
