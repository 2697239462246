import '../../../styles/multiple_number_question.scss';

//react
import * as React from 'react';

//mobx
import { observer } from 'mobx-react';
import {
    SubQuestionNumberInput,
    ISubQuestionNumberInputProps,
} from '../SubQuestionNumberInput/SubQuestionNumberInput';
import { ImageSubNumberQuestionInput } from '../ImageSubNumberQuestionInput/ImageSubNumberQuestionInput';
import classNames from 'classnames';
import { Question, QuestionProps } from '../Question/Question';
import { Omit } from 'utility-types';
import { IMultipleNumberQuestionConfig } from '../../models/survey-config';
import {
    IMultipleNumberQuestion,
    getFilteredSubQuestions,
    getText,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    Response,
    findResponseWithQuestionId,
    IArrayNumberResponse,
    filterArrayResponses,
    findSubQuestionResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import {
    ValidationCode,
    validateResponse,
} from '@big-life-lab/pbl-limesurvey-engine/lib/validation';
import { computed } from 'mobx';

export interface MultipleNumberQuestionProps
    extends Omit<QuestionProps<true>, 'rootClassName' | 'showContinueButton'> {
    question: IMultipleNumberQuestion;
    responses: Response[];
    focus: boolean;
    questionConfig?: IMultipleNumberQuestionConfig;
}

export interface MultipleNumberQuestionState {
    isDirty: boolean;
}

@observer
export class MultipleNumberQuestion extends React.Component<
    MultipleNumberQuestionProps,
    MultipleNumberQuestionState
> {
    constructor(props: MultipleNumberQuestionProps) {
        super(props);

        this.state = {
            isDirty: false,
        };
    }

    onBecomeDirty = () => {
        this.setState({ isDirty: true });
    };

    renderSubQuestions() {
        const { question, responses, questionConfig } = this.props;
        const currentResponse = findResponseWithQuestionId(
            question.questionId,
            responses,
        ) as IArrayNumberResponse;
        const hasImageUrl = questionConfig !== undefined;

        return (
            <div
                className={classNames(
                    'multiple-number-question__sub-questions-container',
                    {
                        'multiple-number-question__sub-questions-container--image-sub-questions': hasImageUrl,
                    },
                )}
            >
                {getFilteredSubQuestions(
                    question,
                    filterArrayResponses(responses),
                ).map((subQuestion, index) => {
                    const subQuestionConfig = questionConfig
                        ? questionConfig.subQuestions.find(
                              ({ subQuestionId }) => {
                                  return (
                                      subQuestionId ===
                                      subQuestion.subQuestionId
                                  );
                              },
                          )
                        : undefined;

                    const currentSubQuestionResponse = findSubQuestionResponse(
                        currentResponse,
                        subQuestion.subQuestionId,
                    );
                    const subQuestionText = getText(subQuestion, responses);

                    const subQuestionNumberInputProps: ISubQuestionNumberInputProps = {
                        question: question,
                        subQuestion: subQuestion,
                        subQuestionResponse: currentSubQuestionResponse,
                        response: currentResponse,
                        inputLabel: subQuestionText,
                        onBecomeDirty: this.onBecomeDirty,
                        errorText: this.errorText,
                        focus: this.props.focus && index === 0,
                        onChange: this.props.onChange,
                    };

                    return subQuestionConfig === undefined ? (
                        <SubQuestionNumberInput
                            key={index}
                            {...subQuestionNumberInputProps}
                        />
                    ) : (
                        <ImageSubNumberQuestionInput
                            key={index}
                            subQuestionText={subQuestionText}
                            imageUrl={subQuestionConfig.imageUrl}
                            subNumberQuestionInputProps={
                                subQuestionNumberInputProps
                            }
                            onBecomeDirty={() => {}}
                        />
                    );
                })}
            </div>
        );
    }

    render() {
        //const validationErrorMessage = question.validateNumberQuestionResponse(response);

        // Disable the button if there is a validation error or the
        // response is null or the response is an empty string
        // /const disabled = !(validationErrorMessage === undefined || validationErrorMessage === null)
        //   || response.response === null;

        //const disabled = false;

        // Show the button if the field is dirty or is not an empty string
        // const visible = this.state.isDirty
        //   || (response.response !== null && response.response > 0);
        //const visible = true;

        return (
            <Question
                {...this.props}
                rootClassName="multiple-number-question"
                showContinueButton={true}
            >
                {this.renderSubQuestions()}
                {this.errorText && this.state.isDirty ? (
                    <div className="multiple-number-question__error-text">
                        {this.errorText}
                    </div>
                ) : null}
            </Question>
        );
    }

    @computed
    private get errorText(): string | undefined {
        const validationInfos = validateResponse(
            this.props.question,
            findResponseWithQuestionId(
                this.props.question.questionId,
                this.props.responses,
            ) as IArrayNumberResponse,
            this.props.responses,
        );

        if (validationInfos === true) {
            return undefined;
        }

        const validationInfo = validationInfos[0];
        switch (validationInfo.validationType) {
            case ValidationCode.RequiredQuestionNotAnswered: {
                return `This question is mandatory`;
            }
            case ValidationCode.NumberAnswerLessThanMin: {
                return `Answer cannot be less than ${validationInfo.min}`;
            }
            case ValidationCode.NumberAnswerGreaterThanMax: {
                return `Answer cannot be more than ${validationInfo.max}`;
            }
            default: {
                return undefined;
            }
        }
    }
}
