import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { LifeYearsLostResult as DumbLifeYearsLostResult } from '../dumb/LifeYearsLostResult/LifeYearsLostResult';
import { MPoRT } from '../../store/ui/mport';
import { round } from 'lodash';

export interface ILifeYearsLostResultProps {
    nextQuestion: Function;
    focus: boolean;
    mport?: MPoRT;
}

@inject('mport')
@observer
export class LifeYearsLostResult extends React.Component<
    ILifeYearsLostResultProps
> {
    formatValue(value: number): number {
        return value < 0 ? 0 : round(value, 1);
    }
    render() {
        const { lifeYearsLost, calculateLifeYearsLost } = this.props.mport!;

        return (
            <DumbLifeYearsLostResult
                nextQuestion={this.props.nextQuestion}
                focus={this.props.focus}
                smokingLyl={
                    lifeYearsLost.smoking
                        ? this.formatValue(lifeYearsLost.smoking)
                        : 0
                }
                alcoholLyl={
                    lifeYearsLost.alcohol
                        ? this.formatValue(lifeYearsLost.alcohol)
                        : 0
                }
                dietLyl={
                    lifeYearsLost.diet
                        ? this.formatValue(lifeYearsLost.diet)
                        : 0
                }
                activityLyl={
                    lifeYearsLost.physicalActivity
                        ? this.formatValue(lifeYearsLost.physicalActivity)
                        : 0
                }
                showLifeYearsLost={lifeYearsLost.smoking !== undefined}
                calculateLifeYearsLost={calculateLifeYearsLost}
            />
        );
    }
}
