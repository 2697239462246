import { Omit } from 'utility-types';
import { JsonSerializable } from '../../util/types';
import { DerivedField } from '../../engine/data-field/derived-field/derived-field';
import { IDataFieldJson } from './json-data-field';
import {
    ICovariateJson,
    parseCovariateJsonToCovariate,
} from './json-covariate';
import { DataField } from '../../engine/data-field/data-field';
import { findCovariateJsonWithName } from './json-covariate';
import { JsonInterval } from './json-interval';
import { Validations } from '../../validation/validation';
import { DataFieldType } from './data-field-type';

export interface IDerivedFieldJson
    extends Omit<
        JsonSerializable<DerivedField>,
        'derivedFrom' | 'intervals' | 'validations'
    > {
    fieldType: DataFieldType.DerivedField;
    derivedFrom: string[];
    intervals?: JsonInterval[];
    validations: Validations;
}

export function findDerivedFieldJsonWithName(
    derivedFieldJsons: IDerivedFieldJson[],
    name: string,
): IDerivedFieldJson | undefined {
    return derivedFieldJsons.find(
        derivedFieldJson => derivedFieldJson.name === name,
    );
}

export function parseDerivedFromJsonToDerivedFrom(
    derivedFromJson: Array<string | IDataFieldJson>,
    derivedFieldJsons: IDerivedFieldJson[],
    covariatesJson: ICovariateJson[],
    dataFieldsJson: IDataFieldJson[],
): DataField[] {
    return derivedFromJson.map(derivedFromJsonItem => {
        if (typeof derivedFromJsonItem === 'string') {
            const covariateJsonForCurrentDerivedFromItem = findCovariateJsonWithName(
                covariatesJson,
                derivedFromJsonItem,
            );
            const derivedFieldJsonForCurrentDerivedFromItem = findDerivedFieldJsonWithName(
                derivedFieldJsons,
                derivedFromJsonItem,
            );
            const dataFieldJsonForCurrentItem = dataFieldsJson.find(
                dataFieldJson => {
                    return dataFieldJson.name === derivedFromJsonItem;
                },
            );

            if (covariateJsonForCurrentDerivedFromItem) {
                return parseCovariateJsonToCovariate(
                    covariateJsonForCurrentDerivedFromItem,
                    covariatesJson,
                    derivedFieldJsons,
                    dataFieldsJson,
                );
            } else if (derivedFieldJsonForCurrentDerivedFromItem) {
                return parseDerivedFieldJsonToDerivedField(
                    derivedFieldJsonForCurrentDerivedFromItem,
                    derivedFieldJsons,
                    covariatesJson,
                    dataFieldsJson,
                );
            } else if (dataFieldJsonForCurrentItem) {
                return new DataField(dataFieldJsonForCurrentItem);
            } else {
                throw new Error(
                    `No entry found in covariates, derived fields or data dictionary for field ${derivedFromJsonItem}`,
                );
            }
        } else {
            return new DataField(derivedFromJsonItem);
        }
    });
}

export function parseDerivedFieldJsonToDerivedField(
    derivedFieldJson: IDerivedFieldJson,
    derivedFieldJsons: IDerivedFieldJson[],
    covariateJsons: ICovariateJson[],
    dataFieldJsons: IDataFieldJson[],
): DerivedField {
    return new DerivedField(
        derivedFieldJson,
        parseDerivedFromJsonToDerivedFrom(
            derivedFieldJson.derivedFrom,
            derivedFieldJsons,
            covariateJsons,
            dataFieldJsons,
        ),
    );
}
