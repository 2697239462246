import { Margin } from './margin';
import { JsonRange } from '../parsers/json/json-range';

export class Range {
    lowerMargin: Margin;
    higherMargin: Margin;

    constructor(rangeJson: JsonRange) {
        this.lowerMargin = new Margin(rangeJson.lowerMargin);
        if (this.lowerMargin.margin === Infinity) {
            this.lowerMargin.margin = -Infinity;
        }

        this.higherMargin = new Margin(rangeJson.higherMargin);
    }

    isWithinLowerMargin(num: number): boolean {
        if (this.lowerMargin) {
            const margin = this.lowerMargin.margin;

            if (this.lowerMargin.isOpen && num <= margin) {
                return false;
            } else if (num < margin) {
                return false;
            }
        }

        return true;
    }

    isWithinHigherMargin(num: number): boolean {
        if (this.higherMargin) {
            const margin = this.higherMargin.margin;

            if (this.higherMargin.isOpen && num >= margin) {
                return false;
            } else if (num > margin) {
                return false;
            }
        }

        return true;
    }

    isWithinRange(num: number): boolean {
        return (
            this.isWithinHigherMargin(num) === true &&
            this.isWithinLowerMargin(num) === true
        );
    }
}
