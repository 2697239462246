import * as React from 'react';
import { observer } from 'mobx-react';
import { SurveyProgressBar as DumbSurveyProgressBar } from './DumbSurveyProgressBar';
import { ISurvey } from '@big-life-lab/pbl-limesurvey-engine/lib/survey';
import { Response } from '@big-life-lab/pbl-limesurvey-engine/lib/response';
import {
    getPercentComplete,
    getRelevantQuestions,
} from '@big-life-lab/pbl-limesurvey-engine/lib/group';

export interface ISurveyProgressBarProps {
    survey: ISurvey;
    responses: Response[];
}

@observer
export class SurveyProgressBar extends React.Component<
    ISurveyProgressBarProps
> {
    render() {
        const { survey, responses } = this.props;
        const groups = survey.groups.map((group) => {
            return {
                name: group.text,
                percentComplete: getPercentComplete(group, responses),
            };
        });

        // Get the total number of questions and the total number of completed questions
        let totals = survey.groups.reduce(
            (counter, group) => {
                let groupQuestionCount = getRelevantQuestions(group, responses)
                    .length;
                let groupPercentComplete = getPercentComplete(group, responses);
                let groupCompletedQuestionCount =
                    groupQuestionCount * groupPercentComplete;

                return {
                    totalQuestionCount:
                        counter.totalQuestionCount + groupQuestionCount,
                    totalComplete:
                        counter.totalComplete + groupCompletedQuestionCount,
                };
            },
            {
                totalQuestionCount: 0,
                totalComplete: 0,
            },
        );

        let totalProgressBarComponent = {
            name: 'Progress',
            percentComplete: totals.totalComplete / totals.totalQuestionCount,
        };

        return (
            <DumbSurveyProgressBar
                groups={groups}
                total={[totalProgressBarComponent]}
            />
        );
    }
}
