export enum QuestionType {
    NumberQuestion,
    TextQuestion,
    RadioQuestion,
    MultipleChoiceQuestion,
    MultipleNumberQuestion,
    FlexibleRowArrayQuestion,
    DateQuestion,
    ConnectedQuestions,
    TextDisplayQuestion,
    ShortTextQuestion,
}

export type ResponseQuestionType =
    | QuestionType.NumberQuestion
    | QuestionType.TextQuestion
    | QuestionType.RadioQuestion
    | QuestionType.MultipleChoiceQuestion
    | QuestionType.MultipleNumberQuestion
    | QuestionType.FlexibleRowArrayQuestion
    | QuestionType.DateQuestion
    | QuestionType.ConnectedQuestions
    | QuestionType.ShortTextQuestion;
