import '../../../styles/survey-input.scss';

import * as React from 'react';
import { observer } from 'mobx-react';
import { GaInputChange } from '../GaInputChange/GaInputChange';
import { autobind } from 'core-decorators';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import {
    ITextQuestion,
    INumberQuestion,
    IMultipleNumberQuestion,
    IShortTextQuestion,
} from '@big-life-lab/pbl-limesurvey-engine/lib/question';

export type InputTypePropGeneric = 'text' | 'number';

export interface ISurveyInputProps<T extends InputTypePropGeneric> {
    inputType: T;
    question: T extends 'text'
        ? ITextQuestion | IShortTextQuestion
        : INumberQuestion | IMultipleNumberQuestion;
    response: T extends 'text' ? string | undefined : number | undefined;
    errorText?: string;
    showErrorText?: boolean;
    inputLabelText?: string;
    suffixText?: string;
    onBecomeDirty?: Function;
    onChange: (e: any) => void;
    focus: boolean;
}

export interface ISurveyInputState {
    isDirty: boolean;
}

@observer
@autobind
export class SurveyInput<
    T extends InputTypePropGeneric,
> extends React.Component<ISurveyInputProps<T>, ISurveyInputState> {
    inputFieldRef!: React.RefObject<HTMLInputElement>;
    constructor(props: ISurveyInputProps<T>) {
        super(props);

        this.state = {
            isDirty: false,
        };
    }

    get showValidationMessage(): boolean {
        return this.state.isDirty
            ? this.props.errorText !== undefined
                ? true
                : false
            : false;
    }

    componentDidUpdate(prevProps: ISurveyInputProps<any>) {
        if (!prevProps.focus && this.props.focus) {
            // Timer allows the question-wrapper's scrollIntoView call time to happen
            // before calling focus on this field intercepts the scroll
            // preventScroll option has limited browser compatibility
            setTimeout(() => {
                //@ts-ignore
                this.inputFieldRef.focus({ preventScroll: true });
            }, 100);
        }
    }

    render() {
        const { response, question, suffixText } = this.props;

        return (
            <FormControl
                className="survey-input"
                error={this.showValidationMessage}
            >
                {this.props.inputLabelText ? (
                    <InputLabel classes={{ root: 'survey-input__label' }}>
                        {this.props.inputLabelText}
                    </InputLabel>
                ) : undefined}
                <GaInputChange question={question}>
                    <Input
                        classes={{
                            underline: 'survey-input__input-underline',
                            input: 'survey-input__input',
                            disabled: 'survey-input__input--disabled',
                            focused: 'survey-input__input--focused',
                            error: 'survey-input__input--error',
                        }}
                        type={this.props.inputType}
                        fullWidth={true}
                        inputRef={(c) => (this.inputFieldRef = c)}
                        value={
                            response !== undefined && response !== null
                                ? (response as string | number)
                                : ''
                        }
                        onChange={(e: React.SyntheticEvent<EventTarget>) => {
                            if (this.props.onBecomeDirty) {
                                this.props.onBecomeDirty();
                            }

                            this.setState({
                                isDirty: true,
                            });

                            return this.props.onChange(e);
                        }}
                        onWheel={() =>
                            // Prevent scrolling from modifying number values
                            (document.activeElement as HTMLInputElement).blur()
                        }
                    />
                </GaInputChange>
                <div className="survey-input__suffix-text">
                    {suffixText || question.suffix}
                </div>
                <FormHelperText
                    classes={{
                        error: 'survey-input__error-text',
                    }}
                >
                    {this.showValidationMessage && this.props.showErrorText
                        ? this.props.errorText
                        : undefined}
                </FormHelperText>
            </FormControl>
        );
    }
}

//@ts-ignore
SurveyInput.defaultProps = {
    showErrorText: true,
};
