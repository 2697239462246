import {
    IAllowedValidation,
    INullableValidation,
    IRangeValidation,
    TypeValidationTypes,
    ValidationTypes,
} from '../validation';
import moment from 'moment';
import { prettyPrintJsonRange } from '../../parsers/json/json-range';

export interface IValidationError {
    validationType: ValidationTypes;
    invalidValue: any;
    message: string;
}

export function constructNullableValidationError(
    nullableValidation: INullableValidation,
    invalidValue: null | undefined,
): IValidationError {
    return {
        validationType: nullableValidation,
        invalidValue,
        message: `Variable ${nullableValidation.variable} has value ${invalidValue} but is not nullable`,
    };
}

interface ITypeValidationError extends IValidationError {
    invalidType: any;
}

export function constructTypeValidationError(
    typeValidation: TypeValidationTypes,
    invalidValue: any,
): ITypeValidationError {
    const invalidType =
        invalidValue === null
            ? 'null'
            : invalidValue === undefined
            ? 'undefined'
            : typeof invalidValue === 'string'
            ? 'string'
            : invalidValue instanceof Date || moment.isMoment(invalidValue)
            ? 'date'
            : typeof invalidValue === 'number'
            ? 'number'
            : 'unknown';

    return {
        validationType: typeValidation,
        invalidValue,
        invalidType,
        message: `Variable ${typeValidation.variable} has type ${invalidType} but should be of type ${typeValidation.type}`,
    };
}

export function constructAllowedValidationError(
    allowedValidation: IAllowedValidation,
    invalidValue: any,
): IValidationError {
    return {
        validationType: allowedValidation,
        invalidValue,
        message: `Variable ${
            allowedValidation.variable
        } has value ${invalidValue} but can only one of ${allowedValidation.allowed.join(
            ', ',
        )}`,
    };
}

export function constructRangeValidationError(
    rangeValidation: IRangeValidation,
    invalidValue: any,
): IValidationError {
    return {
        validationType: rangeValidation,
        invalidValue,
        message: `Variable ${rangeValidation} has value ${invalidValue} which is outside the range ${prettyPrintJsonRange(
            rangeValidation.range,
        )}`,
    };
}
