import * as React from 'react';
import classnames from 'classnames';

interface AppearingNextButtonProps {
    title?: string;
    visible?: boolean;
    disabled?: boolean;
    //idk ts
    onClick: any; //((event: MouseEvent<HTMLButtonElement>) => void) | undefined;
}

const AppearingNextButton = (props: AppearingNextButtonProps) => {
    return (
        <div
            className={classnames('appearing-button-wrapper', {
                visible: props.visible === undefined ? true : props.visible,
            })}
        >
            <button
                className={classnames('appearing-button')}
                onClick={props.onClick}
            >
                Calculate
            </button>
        </div>
    );
};

export default AppearingNextButton;
