import { RuleType, TypeValidationType, ValidationTypes } from '../validation';
import {
    constructAllowedValidationError,
    constructNullableValidationError,
    constructRangeValidationError,
    constructTypeValidationError,
    IValidationError,
} from './validation-error';
import moment from 'moment';
import { Range } from '../../range/range';
import { convertRDateFormatToMoment } from '../../util/r-interop';

export function validate(
    validation: ValidationTypes,
    value: any,
): true | IValidationError {
    switch (validation.ruleType) {
        case RuleType.Nullable: {
            if (!validation.isNullable) {
                const OtherMissingValues = ['NA::a', 'NA::b'];
                if (
                    value === null ||
                    value === undefined ||
                    OtherMissingValues.indexOf(value) !== -1
                ) {
                    return constructNullableValidationError(validation, value);
                }
            }
            break;
        }
        case RuleType.Type: {
            switch (validation.type) {
                case TypeValidationType.Number: {
                    if (
                        value === null ||
                        value === undefined ||
                        (typeof value === 'number' && isNaN(value)) ||
                        (typeof value === 'string' &&
                            isNaN(Number(value)) &&
                            typeof value !== 'number')
                    ) {
                        return constructTypeValidationError(validation, value);
                    }
                    break;
                }
                case TypeValidationType.String: {
                    if (
                        value === null ||
                        value === undefined ||
                        typeof value !== 'string'
                    ) {
                        return constructTypeValidationError(validation, value);
                    }
                    break;
                }
                case TypeValidationType.Date: {
                    if (!(value instanceof Date || value instanceof moment)) {
                        if (
                            !moment(
                                value,
                                convertRDateFormatToMoment(
                                    validation.dateFormat,
                                ),
                            ).isValid()
                        ) {
                            return constructTypeValidationError(
                                validation,
                                value,
                            );
                        }
                    }
                    break;
                }
            }
            break;
        }
        case RuleType.Allowed: {
            const foundMatchingValue = validation.allowed.find(allowedValue => {
                if (isNaN(Number(allowedValue))) {
                    return allowedValue === value;
                } else {
                    const numberAllowedValue = Number(allowedValue);
                    const allowedValuePrecision = getPrecision(
                        numberAllowedValue,
                    );

                    let valueWithSamePrecision: number;
                    const valueAsNumber = Number(value);
                    if (allowedValuePrecision > 0) {
                        valueWithSamePrecision = Number(
                            valueAsNumber.toPrecision(allowedValuePrecision),
                        );
                    } else {
                        valueWithSamePrecision = Number(
                            valueAsNumber.toFixed(),
                        );
                    }
                    return numberAllowedValue === valueWithSamePrecision;
                }
            });

            if (foundMatchingValue === undefined) {
                return constructAllowedValidationError(validation, value);
            }
            break;
        }
        case RuleType.Range: {
            if (!new Range(validation.range).isWithinRange(value)) {
                return constructRangeValidationError(validation, value);
            }
            break;
        }
    }

    return true;
}

function getPrecision(num: number): number {
    const absoluteNum = Math.abs(num);

    if (Math.floor(absoluteNum) === absoluteNum) return 0;

    const str = absoluteNum.toString();
    if (str.indexOf('.') !== -1 && str.indexOf('-') !== -1) {
        return Number(str.split('-')[1]) || 0;
    } else if (str.indexOf('.') !== -1) {
        return str.split('.')[1].length || 0;
    }
    return Number(str.split('-')[1]) || 0;
}
