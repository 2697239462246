import '../../../styles/number_question.scss';

import * as React from 'react';

import { NumberQuestionInput } from '../Inputs/NumberQuestionInput';
import { Question, QuestionProps } from '../Question/Question';
import { Omit } from 'utility-types';
import { INumberQuestionConfig } from '../../models/survey-config';
import { INumberQuestion } from '@big-life-lab/pbl-limesurvey-engine/lib/question';
import {
    INumberResponse,
    findResponseWithQuestionId,
} from '@big-life-lab/pbl-limesurvey-engine/lib/response';

export interface NumberQuestionProps
    extends Omit<QuestionProps<true>, 'rootClassName' | 'showContinueButton'> {
    question: INumberQuestion;
    questionConfig?: INumberQuestionConfig;
}

export function NumberQuestion(props: NumberQuestionProps) {
    const { question, responses, questionConfig } = props;
    const response = findResponseWithQuestionId(
        question.questionId,
        responses,
    ) as INumberResponse;

    return (
        <Question {...props} rootClassName="number-question" showContinueButton>
            <div>
                <NumberQuestionInput
                    question={question}
                    response={response}
                    responses={responses}
                    focus={props.focus}
                    onChange={props.onChange}
                />
                {questionConfig && (
                    <img
                        className="number-question__image"
                        src={questionConfig.imageUrl}
                    />
                )}
            </div>
        </Question>
    );
}
